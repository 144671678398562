import { apiStore } from '../api';

class CupomDescontoService {
  getCuponsDesconto(filter, data) {
    return apiStore.post(`/api/backoffice/cupom-desconto/${filter}`, data);
  }

  getCupomDesconto(id) {
    return apiStore.get(`/api/backoffice/cupom-desconto/${id}`);
  }

  updateCupomDesconto(id, data) {
    return apiStore.put(`/api/backoffice/cupom-desconto/${id}`, data);
  }

  insertCupomDesconto(data) {
    return apiStore.post(`/api/backoffice/cupom-desconto/save`, data);
  }

  updateItem(data) {
    return apiStore.put(`/api/backoffice/cupom-desconto-item`, data);
  }

  deleteItem(itemId) {
    return apiStore.delete(`/api/backoffice/cupom-desconto-item/${itemId}`);
  }

  saveItem(data) {
    return apiStore.post(`/api/backoffice/cupom-desconto-item`, data);
  }
}

export default new CupomDescontoService();
