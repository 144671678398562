import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
export const useStyles = makeStyles((theme) => ({
  root: {
    margin: 10,
  },
}));

export const useStyles2 = makeStyles({
  root: {
    height: 300,
  }
});

export const Container1 = styled.div`
.MuiCardHeader-root {
  padding: 10px 16px;
  .MuiCardHeader-title {
    font-size: 0.9em;
    color: #666;
  }
  .MuiCardHeader-action {
    padding-top: 5px;
  }
}

.MuiCardContent-root {
  padding: 0 16px 20px;
  text-align: center;
  h1 {
    font-size: 4.5em;
  }
}
`;