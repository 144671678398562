import { apiStore } from '../api';

class EmpresaService {
  getEmpresa(empresaId) {
    return apiStore.post(`/api/backoffice/empresa/${empresaId}`);
  }

  getAllCategorias() {
    return apiStore.get(`/api/backoffice/categoria`);
  }

  getAll(filters, data) {
      return apiStore.post(`/api/backoffice/empresa/get/all${filters ? filters : ''}`, data);
  }

  saveEmpresa(data) {
    return apiStore.post('/api/backoffice/empresa', data);
  }

  updateEmpresa(data) {
    return apiStore.put('/api/backoffice/empresa', data);
  }

  addLogo(data) {
    return apiStore.post('/api/backoffice/empresa/upload/imagem', data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
  }

  deleteLogo(fileName) {
    return apiStore.delete(`/api/backoffice/empresa/logo/${fileName}`);
  }
}
export default new EmpresaService();
