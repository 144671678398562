export const BEFORE_LODING_DATA = '@product/BEFORE_LODING_DATA';
export const LOADING_DATA = '@product/LOADING_DATA';
export const AFTER_LOADING_DATA = '@product/AFTER_LOADING_DATA';

export const SET_PAGE_COUNT = '@product/SET_PAGE_COUNT';

export const CHANGE_SELECTION_ALL = '@product/CHANGE_SELECTION_ALL';
export const CHANGE_SELECTION_ONE = '@product/CHANGE_SELECTION_ONE';

export const ADD_PRODUCTS = '@product/ADD_PRODUCTS';
export const CLEAR_PRODUCTS = '@produtct/CLEAR_PRODUCTS';
export const REMOVE_PRODUCT = '@product/REMOVE_PRODUCT';
export const CHANGE_GROUP_PRODUCT = '@product/CHANGE_GROUP_PRODUCT';
export const CHANGE_FRACIONAMENTO = '@product/CHANGE_FRACIONAMENTO';
export const CHANGE_ALL_GROUP_PRODUCT = '@product/CHANGE_ALL_GROUP_PRODUCT';
export const CHANGE_ALL_FRACIONAMENTO = '@product/CHANGE_ALL_FRACIONAMENTO';
export const CHANGE_PERMITE_FRACIONAMENTO = '@product/CHANGE_PERMITE_FRACIONAMENTO';

export function beforeLoadingData({ filters, page, rowsPerPage }) {
  return {
    type: BEFORE_LODING_DATA,
    filters,
    rowsPerPage,
    page
  };
}

export function loadingData({ filters = {}, page = 0, rowsPerPage = 10 }) {
  return {
    type: LOADING_DATA,
    filters,
    page,
    rowsPerPage
  };
}

export function afterLoadingData({ rows, rowsCount }) {
  return {
    type: AFTER_LOADING_DATA,
    rows,
    rowsCount
  };
}

export function setPageCount(page) {
  return {
    type: SET_PAGE_COUNT,
    page
  }
}

export function changeSelectionAll() {
  return {
    type: CHANGE_SELECTION_ALL
  };
}

export function changeSelectionOne(product) {
  return {
    type: CHANGE_SELECTION_ONE,
    product
  };
}

export function changeFracionamento(product, fracionamento) {
  return {
    type: CHANGE_FRACIONAMENTO,
    product, fracionamento
  };
}

export function changePermiteFracionamento(product, permiteFracionamento) {
  return {
    type: CHANGE_PERMITE_FRACIONAMENTO,
    product, permiteFracionamento
  };
}

export function changeGroupProduct(product, grupoProdutoGenericoId) {
  return {
    type: CHANGE_GROUP_PRODUCT,
    product, grupoProdutoGenericoId
  };
}

export function changeAllGroupProduct(grupoProdutoGenericoId) {
  return {
    type: CHANGE_ALL_GROUP_PRODUCT,
    grupoProdutoGenericoId
  };
}

export function changeAllFracionamento(fracionamento) {
  return {
    type: CHANGE_ALL_FRACIONAMENTO,
    fracionamento
  };
}

export function addProducts() {
  return {
    type: ADD_PRODUCTS
  };
}

export function removerProduct(id) {
  return {
    type: REMOVE_PRODUCT,
    id
  };
}

export function clearProducts() {
  return {
    type: CLEAR_PRODUCTS
  };
}
