import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardActions } from '@material-ui/core';
import {
  Cancel as IconCancel
} from '@material-ui/icons';
import { Plugins } from '@capacitor/core';

import {
  Grid,
  Switch,
  Checkbox,
  Box,
  Typography,
  AppBar,
  Tabs,
  Tab,
  Container,
  Button,
  InputLabel,
  IconButton,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Select,
  MenuItem,
  Divider,
  ListItemText,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Tooltip,
  InputAdornment
} from '@material-ui/core';

import axios from 'axios';
import { apiSiaf, apiStore } from '../../../services/api';
import meioPagamentoService from '../../../services/modules/meio-pagamento.service';
import notification from '../../../services/notification';
import { CustomSelect, Loading } from '../../../components';
import { CurrencyFormatCustom, PercentageDecimalThreePrecision } from '../../../components/NumberFormat';

const { Storage } = Plugins;
const MeioPagamentoNew = ({ history }) => {
  let { id } = useParams();
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const [bandeiras, setBandeiras] = useState([]);
  const [tipoPagamentos, setTipoPagamentos] = useState([]);
  const [tipoVendas, setTipoVendas] = useState([]);
  const [formasPagamentos, setFormasPagamentos] = useState([]);
  const [model, setModel] = useState();
  const [openParcela, setOpenParcela] = React.useState(false);
  const [empresaLicenciada, setEmpresaLicenciada] = useState(null);

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const handleInputChange = event => {
    event.persist();
    setModel(data => ({ ...data, [event.target.name]: event.target.value }));
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleAddParcela = (event, bandeira) => {
    event.persist();
    setModel(data => {
      if (!data.meioPagamentoParcela) data.meioPagamentoParcela = [];
      const listInputs = data.meioPagamentoParcela.map(input => {
        return { ...input };
      });

      listInputs.push({
        meioPagamentoId: model?.id,
        parcela: bandeira ? listInputs.filter(x => x.cartaoBandeiraId == bandeira).length + 1 : listInputs.length + 1,
        percentualTaxa: 0,
        cobrarTaxa: false,
        cartaoBandeiraId: bandeira
      });

      return { ...data, quantidadeParcela: listInputs.length, meioPagamentoParcela: [...listInputs] };
    });
  };

  const handleRadioChange = event => {
    event.persist();
    setModel(data => ({ ...data, [event.target.name]: parseInt(event.target.value) }));
  };

  const handleTipoPagamentoChange = event => {
    event.persist();
    setModel(data => ({ ...data, [event.target.name]: parseInt(event.target.value), meioPagamentoParcela: [] }));
  };

  const handleOpenParcela = () => {
    setOpenParcela(true);
  };

  const handleCloseParcela = () => {
    setOpenParcela(false);
  };

  const handleMeioPagamentoParcelaChange = (event, meioPagamentoParcela) => {
    event.persist();
    setModel(data => {
      const listInputs = data.meioPagamentoParcela.map(input => {
        if (input === meioPagamentoParcela) {
          if (event.target.value && event.target.value.length > 0)
            return { ...input, [event.target.name]: event.target.value };
          else
            return {
              ...input,
              [event.target.name]: event.target.name === 'desativado' ? !event.target.checked : event.target.checked
            };
        } else {
          return { ...input };
        }
      });

      return { ...data, meioPagamentoParcela: listInputs };
    });
  };

  const handleRemoveMeioPagamentoParcela = meioPagamentoParcela => {
    setModel(data => {
      const listInputs = [];

      data.meioPagamentoParcela.forEach(input => {
        if (input !== meioPagamentoParcela) listInputs.push({ ...input });
      });

      return { ...data, quantidadeParcela: listInputs.length, meioPagamentoParcela: [...listInputs] };
    });
  };

  const removerParcelasParaQuantidade = quantidadeLimite => {
    setModel(data => {
      const listInputs = [];
      let controle = 0

      data.meioPagamentoParcela.forEach(input => {
        if (controle < quantidadeLimite) {
          listInputs.push({ ...input });
          controle = controle + 1;
        }
      });

      return { ...data, meioPagamentoParcela: [...listInputs] };
    });
  };

  const handleBooleanChange = event => {
    event.persist();
    setModel(data => ({ ...data, [event.target.name]: !data[event.target.name] }));
  };

  const handleChangeBandeiras = event => {
    event.persist();

    const selected = event.target.value || [];
    setModel(data => ({ ...data, bandeirasPermitidas: [...selected] }));
  };

  const changeCheckBandeira = (event, data) => {
    event.persist();

    console.log(event, data);
  }

  const handleQuantidadeParcelaChangeBlur = event => {
    event.persist();
    const parcelas = +event.target.value;
    setModel(data => ({ ...data, [event.target.name]: event.target.value }));

    let parcelasCount = +model.meioPagamentoParcela.length;

    if (parcelasCount !== parcelas) {
      notification.confirm('Alteração nas parcelas', 'Gostaria de alterar automaticamente a quantidade na configuração de parcelas?')
        .then(result => {
          if (result.value === true) {

            if (parcelasCount < parcelas)
              while (parcelasCount < parcelas) {
                handleAddParcela(event);
                parcelasCount = parcelasCount + 1;
              }
            else if (parcelasCount > parcelas)
              removerParcelasParaQuantidade(parcelas)

            setOpenParcela(true);
          }
        })
    }

    while (parcelasCount < parcelas) {
      handleAddParcela(event);
      parcelasCount = parcelasCount + 1;
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();

    try {
      setLoading(true);

      const payload = {
        ...model,
        bandeirasPermitidas: model.bandeirasPermitidas.join(','),
        taxaFixa:
          typeof model.taxaFixa === 'string' ? Number(model.taxaFixa.replace(/[^0-9.-]+/g, '') / 100) : model.taxaFixa,
        valorMinimoParcela:
          typeof model.valorMinimoParcela === 'string'
            ? Number(model.valorMinimoParcela.replace(/[^0-9.-]+/g, '') / 100)
            : model.valorMinimoParcela,
        valorMinimoCompraParcelada:
          typeof model.valorMinimoCompraParcelada === 'string'
            ? Number(model.valorMinimoCompraParcelada.replace(/[^0-9.-]+/g, '') / 100)
            : model.valorMinimoCompraParcelada
      };

      +id === 0 || id === undefined || id === null
        ? await meioPagamentoService.saveMeioPagamento(payload)
        : await meioPagamentoService.updateMeioPagamento(id, payload);

      notification.success('Meio de pagamento salvo com sucesso!');
      history.push('/meio-pagamento');
      setLoading(false);
    } catch (error) {
      setLoading(false);
      notification.error(error);
    }
  };

  useEffect(() => {
    let source = axios.CancelToken.source();

    const loadData = async source => {
      const options = {
        cancelToken: source.token
      };

      try {
        setLoading(true);

        const { value: empresaLicenciadaStorage } = await Storage.get({
          key: 'empresaLicenciada'
        });

        const objEmpresaLicenciada = JSON.parse(empresaLicenciadaStorage)

        setEmpresaLicenciada(objEmpresaLicenciada)

        if (objEmpresaLicenciada?.possuiIntegracaoSIAF) {
          const [{ data: _tipopagamento }, { data: _tipovenda }] = await Promise.all([
            apiSiaf.get('/api/faturamento/tipo-pagamento', options),
            apiSiaf.get('/api/faturamento/tipo-venda', options)
          ]);

          setTipoPagamentos([..._tipopagamento]);
          setTipoVendas([..._tipovenda]);

        }
        
        const { data: cartoesBandeira } = await meioPagamentoService.getCartoesBandeira(id, options);
        setBandeiras(cartoesBandeira);

        if (id > 0) {
          const { data: _model } = await meioPagamentoService.getMeioPagamento(id, options);

          setModel({
            ..._model,
            bandeirasPermitidas: _model.bandeirasPermitidas ? _model.bandeirasPermitidas.split(',').map(x => +x) : []
          });
        } else {
          const { data: _formasPagamentos } = await apiStore.get('/api/backoffice/forma-pagamento', options);
          setFormasPagamentos([..._formasPagamentos]);

          setModel({
            tipoPagamento: 1,
            siafPagamentoId: 0,
            siafCondicaoId: 0,
            descricao: formasPagamentos.length > 0 ? formasPagamentos[0] : 'Dinheiro',
            formaPagamento: 1,
            bandeirasPermitidas: [],
            desativado: false,
            meioPagamentoParcela: [
              {
                parcela: 1,
                percentualTaxa: 0.000,
                cobrarTaxa: false
              }
            ]
          });
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
        notification.error(error);
      }
    };

    loadData(source);
    return () => {
      source.cancel();
    };
  }, []);

  return (
    <Container fixed>
      <Card>
        {loading ? (
          <>
            <Loading />
          </>
        ) : (
            <>
              <CardContent>
                <Grid item orientation="column">
                  <Grid container orientation="row" spacing={2}>
                    <Grid item md={9} xs={12}>
                      <FormControl component="fieldset">
                        <FormLabel component="legend">Tipo de pagamento</FormLabel>
                        <RadioGroup
                          row
                          aria-label="Tipo de pagamento"
                          name="tipoPagamento"
                          value={model?.tipoPagamento}
                          onChange={handleTipoPagamentoChange}
                        >
                          <FormControlLabel
                            value={1}
                            control={<Radio color="primary" />}
                            label="Não integrado (máquina/dinheiro)"
                          />
                          <FormControlLabel
                            value={2}
                            control={<Radio color="primary" />}
                            label="Pagamento integrado (app/web)"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container orientation="row" spacing={2}>
                    <Grid item md={6} xs={12}>
                      {id > 0 ? (
                        <TextField
                          name="descricao"
                          label="Forma de pagamento (Descrição)"
                          value={model?.descricao ?? ''}
                          variant="outlined"
                          fullWidth
                          disabled={true}
                        />
                      ) : (
                          <FormControl variant="outlined" fullWidth>
                            <InputLabel>Forma de pagamento (Descrição)</InputLabel>
                            <Select
                              name="descricao"
                              label="Forma de pagamento (Descrição)"
                              value={formasPagamentos.find(meio => meio.formaPagamento === model?.formaPagamento) ?? ''}
                              onChange={event => {
                                event.persist();

                                const item = event.target.value;
                                if (item) {
                                  setModel(data => ({
                                    ...data,
                                    descricao: item?.descricao,
                                    formaPagamento: item?.formaPagamento,
                                    cartao: item?.formaPagamento === 2 || item?.formaPagamento === 3
                                  }));
                                }
                              }}
                            >
                              {formasPagamentos.map(item => (
                                <MenuItem key={item.id} value={item}>
                                  {item.descricao}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                    </Grid>
                  </Grid>
                  {empresaLicenciada?.possuiIntegracaoSIAF === true && (
                    <Card style={{ marginTop: 16 }}>
                      <CardHeader title="Vincular pagamento" />
                      <CardContent>
                        <Grid container orientation="row" spacing={2}>
                          <Grid item md={3} xs={12}>
                            <CustomSelect
                              name="siafPagamentoId"
                              label="Tipo de pagamento"
                              value={model?.siafPagamentoId ?? 0}
                              handleChange={e => handleInputChange(e)}
                              options={tipoPagamentos}
                            />
                          </Grid>
                          <Grid item md={3} xs={12}>
                            <CustomSelect
                              name="siafCondicaoId"
                              label="Tipo de venda"
                              value={model?.siafCondicaoId ?? 0}
                              handleChange={e => handleInputChange(e)}
                              options={tipoVendas}
                            />
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <div
                              style={{
                                color: 'rgb(105, 165, 211)',
                                backgroundColor: 'rgb(232, 244, 253)',
                                padding: '8px 16px',
                                borderRadius: 4
                              }}
                            >
                              <p>
                                Informar o tipo de pagamento e venda. Necessário para geração dos documentos para o SIAF.
                                            </p>
                            </div>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  )}

                  {model?.cartao && (
                    <Card style={{ marginTop: 16 }}>
                      <CardHeader title="Cartão" />
                      <CardContent>
                        <Grid container orientation="row" spacing={2}>
                          <Grid item md={2} xs={12}>
                            <FormControl component="fieldset">
                              <FormLabel component="legend">Operação Cartão</FormLabel>
                              <RadioGroup
                                row
                                aria-label="Operação Cartão"
                                name="operacaoCartao"
                                value={model?.operacaoCartao || 1}
                                onChange={e => handleRadioChange(e)}
                              >
                                <FormControlLabel value={1} control={<Radio color="primary" />} label="Crédito" />
                                <FormControlLabel value={2} control={<Radio color="primary" />} label="Débito" />
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                          <Grid item md={2} xs={12}>
                            <TextField
                              name="taxaFixa"
                              label="Taxa por transação"
                              value={model?.taxaFixa ?? 0}
                              onChange={e => handleInputChange(e)}
                              InputProps={{
                                inputComponent: CurrencyFormatCustom
                              }}
                              variant="outlined"
                              fullWidth
                            />
                          </Grid>
                          <Grid item md={2} xs={12}>
                            <TextField
                              name="diasRecebimento"
                              label="Dias recebimento"
                              value={model?.diasRecebimento ?? 0}
                              onChange={e => handleInputChange(e)}
                              type="number"
                              variant="outlined"
                              fullWidth
                            />
                          </Grid>
                          {(model?.tipoPagamento === 1) && (
                            <Grid item md={2} xs={12}>
                              <TextField
                                name="quantidadeParcela"
                                label="Quantidade parcela"
                                value={model?.quantidadeParcela}
                                type="number"
                                variant="outlined"
                                fullWidth
                                onChange={e => handleInputChange(e)}
                                onBlur={e => handleQuantidadeParcelaChangeBlur(e)}
                              />
                            </Grid>
                          )}
                          <Grid item md={2} xs={12}>
                            <Tooltip title="Valor mínimo para efetuar uma compra parcelada">
                              <TextField
                                name="valorMinimoCompraParcelada"
                                label="Valor mín. compra parcelada"
                                value={model?.valorMinimoCompraParcelada}
                                variant="outlined"
                                InputProps={{
                                  inputComponent: CurrencyFormatCustom
                                }}
                                fullWidth
                                onChange={e => handleInputChange(e)}
                              />
                            </Tooltip>
                          </Grid>
                          <Grid item md={2} xs={12}>
                            <Tooltip title="Valor mínimo da parcela para efetuar pagamento">
                              <TextField
                                name="valorMinimoParcela"
                                label="Valor mín. da parcela"
                                value={model?.valorMinimoParcela}
                                variant="outlined"
                                fullWidth
                                onChange={e => handleInputChange(e)}
                                InputProps={{
                                  inputComponent: CurrencyFormatCustom
                                }}
                              />
                            </Tooltip>
                          </Grid>
                        </Grid>
                        <Grid container orientation="row" spacing={2}>
                          <Grid item md={12}>
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel id="grupo">Bandeiras</InputLabel>
                              <Select
                                labelId="grupo"
                                id="select-grupo"
                                label="Grupo"
                                multiple
                                value={model?.bandeirasPermitidas ?? []}
                                onChange={e => handleChangeBandeiras(e)}
                                renderValue={selected =>
                                  selected.map(s => bandeiras.find(bandeira => bandeira.id === s)?.descricao)?.join(', ')
                                }
                                fullWidth
                              >
                                {bandeiras.filter(itemBandeira => (model?.tipoPagamento === 2 ? empresaLicenciada?.esitefCartoesBandeiraPermitidos?.split(',')
                                  .filter(itemCartoesEmpresa => itemBandeira.id === +itemCartoesEmpresa)?.length > 0 : itemBandeira === itemBandeira))
                                  .map(bandeira => (
                                    <MenuItem key={bandeira.id} value={bandeira.id}>
                                      <Checkbox
                                        color="primary"
                                        checked={
                                          model?.bandeirasPermitidas.indexOf(
                                            model?.bandeirasPermitidas.find(b => b === bandeira.id)
                                          ) > -1
                                        }
                                      />
                                      <img src={bandeira.imagemUrl} alt="Bandeira" style={{ width: 40 }} /> &nbsp;
                                      <ListItemText>{bandeira.descricao}</ListItemText>
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item md={12}>
                            <Button
                              style={{ marginLeft: 'auto' }}
                              variant="contained"
                              color="primary"
                              onClick={handleOpenParcela}
                            >
                              Configurar parcelas...
                          </Button>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  )}
                </Grid>
              </CardContent>
              <Divider />
              <CardActions>
                <FormControlLabel
                  control={
                    <Switch
                      color="secondary"
                      name="desativado"
                      checked={model?.desativado ?? false}
                      onChange={e => handleBooleanChange(e)}
                    />
                  }
                  label="Desativado"
                />

                <Button style={{ marginLeft: 'auto' }} variant="contained" color="primary" onClick={handleSubmit}>
                  Salvar
              </Button>
              </CardActions>
            </>
          )}
      </Card>

      <Dialog
        open={openParcela}
        onClose={handleCloseParcela}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth={true}
        maxWidth={'md'}
      >
        <DialogTitle id="scroll-dialog-title">Configurar parcelas</DialogTitle>
        <DialogContent dividers={true}>
          {model?.tipoPagamento === 2 && (
            <>
              <AppBar position="static" color="default">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  aria-label="full width tabs example"
                >

                  {model?.bandeirasPermitidas.map(bandeira => (
                    <Tab value={bandeira} label={bandeiras.find(x => x.id === bandeira).descricao} {...a11yProps(bandeira)} />
                  ))}
                </Tabs>
              </AppBar>
              {model?.bandeirasPermitidas.map(bandeira => (
                <TabPanel value={value} index={bandeira}>
                  <TableContainer style={{ marginTop: '15px' }}>
                    <Table stickyHeader size="small" aria-label="">
                      <TableHead>
                        <TableRow>
                          <TableCell>Parcelas</TableCell>
                          <TableCell align="center">Taxa</TableCell>
                          <TableCell align="center"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {loading ? (
                          <TableRow>
                            <TableCell colSpan={5} rowSpan="all" align="center">
                              <CircularProgress />
                              <p>Carregando...</p>
                            </TableCell>
                          </TableRow>
                        ) : (
                            <>
                              {model?.meioPagamentoParcela?.filter(x => x.cartaoBandeiraId === bandeira).map(row => (
                                <TableRow key={row.id}>
                                  <TableCell>
                                    <h4>{row?.parcela}</h4>
                                    <Tooltip title="Cobrar taxa">
                                      <Switch
                                        checked={row.cobrarTaxa}
                                        onChange={event => {
                                          handleMeioPagamentoParcelaChange(event, row);
                                        }}
                                        name="cobrarTaxa"
                                        id="cobrarTaxa"
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                      />
                                    </Tooltip>
                                  </TableCell>
                                  <TableCell style={{ width: '60%' }}>
                                    <Grid container>
                                      <Grid item xs={12}>
                                        <TextField
                                          id="percentualTaxa"
                                          name="percentualTaxa"
                                          label="Taxa (%)"
                                          value={row?.percentualTaxa ? row?.percentualTaxa : ''}
                                          onChange={event => {
                                            handleMeioPagamentoParcelaChange(event, row);
                                          }}
                                          autoFocus
                                          variant="outlined"
                                          fullWidth
                                          InputProps={{
                                            // inputComponent: PercentageDecimalThreePrecision,
                                            endAdornment: <InputAdornment position="end">%</InputAdornment>
                                          }}
                                        />
                                      </Grid>
                                    </Grid>
                                  </TableCell>
                                  {!model?.meioPagamentoParcela?.find(x => x.parcela > row.parcela) ? (
                                    <TableCell>
                                      <IconButton
                                        aria-label="delete"
                                        onClick={event => {
                                          handleRemoveMeioPagamentoParcela(row);
                                        }}
                                      >
                                        <IconCancel />
                                      </IconButton>
                                    </TableCell>
                                  ) : <TableCell style={{ width: '10%' }}>&nbsp;</TableCell>}
                                </TableRow>
                              ))}
                            </>
                          )}

                        {(model?.meioPagamentoParcela?.length === 0 || model?.meioPagamentoParcela?.length === undefined) &&
                          !loading && (
                            <TableRow style={{ height: 53 }}>
                              <TableCell colSpan={5}>Nenhuma informação encontrada</TableCell>
                            </TableRow>
                          )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Button
                    style={{ marginLeft: 'auto', marginTop: 10 }}
                    variant="contained"
                    color="primary"
                    onClick={event => { handleAddParcela(event, bandeira) }}
                  >
                    Adicionar parcela
              </Button>
                </TabPanel>
              ))}
            </>
          )}
          {model?.tipoPagamento === 1 && (
            <>
              <TableContainer style={{ marginTop: '15px' }}>
                <Table stickyHeader size="small" aria-label="">
                  <TableHead>
                    <TableRow>
                      <TableCell>Parcelas</TableCell>
                      <TableCell align="center">Taxa</TableCell>
                      <TableCell align="center"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <TableRow>
                        <TableCell colSpan={5} rowSpan="all" align="center">
                          <CircularProgress />
                          <p>Carregando...</p>
                        </TableCell>
                      </TableRow>
                    ) : (
                        <>
                          {model?.meioPagamentoParcela?.map(row => (
                            <TableRow key={row.id}>
                              <TableCell>
                                <h4>{row?.parcela}</h4>
                                <Tooltip title="Cobrar taxa">
                                  <Switch
                                    checked={row.cobrarTaxa}
                                    onChange={event => {
                                      handleMeioPagamentoParcelaChange(event, row);
                                    }}
                                    name="cobrarTaxa"
                                    id="cobrarTaxa"
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                  />
                                </Tooltip>
                              </TableCell>
                              <TableCell style={{ width: '60%' }}>
                                <Grid container>
                                  <Grid item xs={12}>
                                    <TextField
                                      id="percentualTaxa"
                                      name="percentualTaxa"
                                      label="Taxa (%)"
                                      value={row?.percentualTaxa ? row?.percentualTaxa : '' }
                                      onChange={event => {
                                        handleMeioPagamentoParcelaChange(event, row);
                                      }}
                                      variant="outlined"
                                      fullWidth
                                      InputProps={{
                                        inputComponent: PercentageDecimalThreePrecision,
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </TableCell>
                              {!model?.meioPagamentoParcela?.find(x => x.parcela > row.parcela) ? (
                                <TableCell>
                                  <IconButton
                                    aria-label="delete"
                                    onClick={event => {
                                      handleRemoveMeioPagamentoParcela(row);
                                    }}
                                  >
                                    <IconCancel />
                                  </IconButton>
                                </TableCell>
                              ) : <TableCell style={{ width: '10%' }}>&nbsp;</TableCell>}
                            </TableRow>
                          ))}
                        </>
                      )}

                    {(model?.meioPagamentoParcela?.length === 0 || model?.meioPagamentoParcela?.length === undefined) &&
                      !loading && (
                        <TableRow style={{ height: 53 }}>
                          <TableCell colSpan={5}>Nenhuma informação encontrada</TableCell>
                        </TableRow>
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Button
                style={{ marginLeft: 'auto', marginTop: 10 }}
                variant="contained"
                color="primary"
                onClick={event => { handleAddParcela(event) }}
              >
                Adicionar parcela
              </Button>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseParcela} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default MeioPagamentoNew;
