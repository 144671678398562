import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Plugins } from '@capacitor/core';
import {
  Container,
  Typography,
  Grid,
  TextField,
  Button,
  Fade,
  Snackbar,
  CircularProgress
} from '@material-ui/core';
import { Check, ArrowBack } from '@material-ui/icons';

import * as AuthActions from '../../store/modules/auth/actions';
import { Header, HeaderLogo, useStyles } from './styles';
import { apiSiaf, generateToken } from '../../services/api';
import EmpresaService from '../../services/modules/empresa.service';

const imagesStep = {
  ativacao: require('../../assets/images/ativacao.png'),
  api: require('../../assets/images/configuar-api.png'),
  loja: require('../../assets/images/loja.png')
};

const { Storage } = Plugins;

const Setup = ({ activation, history }) => {
  const classes = useStyles();

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    Transition: Fade
  });
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [baseApiUrl] = useState(false);
  const [servidor, setServidor] = useState('');
  const [cpfCnpj, setCpfCnpj] = useState('');
  const [numeroSerie, setNumeroSerie] = useState('');

  const handleConfiguarServidor = async e => {
    e.preventDefault();

    setLoading(true);

    if (!servidor) {
      setSnackbar({
        open: true,
        message: 'Informar o caminho e a porta do servidor'
      });
      setLoading(false);
      return;
    }

    await Storage.set({
      key: 'servidorSiaf',
      value: servidor
    });

    setLoading(false);
    setStep(1);
  };

  const handleConsultarLicenca = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      const token = generateToken({ cpfCnpj, numeroSerie });
      const headers = {
        Authorization: `Bearer ${token}`
      };
      const { value: apiSiafStore } = await Storage.get({
        key: 'usuarioToken'
      });

      //Valida liberacao e licenca retornando a empresa e chaveAtivacao
      const { data: dataSIAF } = await apiSiaf.post(
        '/api/licenca',
        {
          cpfCnpj,
          numeroSerie,
          chaveDispositivo: 'mock-dispositivo',
          modulo: 'store',
          tokenApiSiafStore: apiSiafStore
        },
        { headers }
      ).catch(err => { console.log(JSON.stringify(err, Object.getOwnPropertyNames(err))) });
      const { chavePrivada, token: siafToken } = dataSIAF;

      if (baseApiUrl === '') {
        await Storage.set({
          key: 'baseApiUrl',
          value: null
        })
      } else
        await Storage.set({
          key: 'baseApiUrl',
          value: baseApiUrl
        })

      await Storage.set({
        key: 'chavePrivada',
        value: chavePrivada
      });
      await Storage.set({
        key: 'storeToken',
        value: token
      });

      await Storage.set({
        key: 'siafToken',
        value: siafToken
      });

      const { data: empresaLicenciadaAtualizada } = await EmpresaService.getEmpresa(0);

      empresaLicenciadaAtualizada.dadosIntegracaoSIAF = JSON.stringify({
        baseApiUrl: baseApiUrl === '' ? null : baseApiUrl,
        chavePrivada: chavePrivada,
        storeToken: token,
        siafToken: siafToken
      });

      empresaLicenciadaAtualizada.possuiIntegracaoSIAF = true;

      await EmpresaService.updateEmpresa(empresaLicenciadaAtualizada);

      await Storage.set({
        key: 'empresaLicenciada',
        value: JSON.stringify(empresaLicenciadaAtualizada)
      });

      activation();
      history.push('/');
    } catch (err) {
      // const { message } = response.data;

      setLoading(false);
      const errorString = JSON.stringify(err, Object.getOwnPropertyNames(err))
      setSnackbar({ open: true, errorString });
    }
  };

  return (
    <>
      <Container className={classes.root}>
        <Grid className={classes.container} container spacing={2}>
          {step === 0 && (
            <>
              <Grid item xs={12}>
                <Header>
                  <HeaderLogo alt="api" src={imagesStep['api']} />
                  <Typography variant="h2">Servidor</Typography>
                  <Typography>
                    Informar os dados de acesso ao servidor da aplicação
                  </Typography>
                </Header>
              </Grid>
              <Grid item xs={12}>
                <form noValidate onSubmit={handleConfiguarServidor}>
                  <TextField
                    id="servidor"
                    label="Servidor e porta"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={servidor}
                    onChange={e => setServidor(e.target.value)}
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    margin="normal"
                    startIcon={<Check />}
                    onClick={handleConfiguarServidor}
                  >
                    Configurar
                  </Button>
                </form>
              </Grid>
            </>
          )}

          {step === 1 && (
            <>
              <Grid item xs={12}>
                <Header>
                  <HeaderLogo alt="Ativação" src={imagesStep['ativacao']} />
                  <Typography variant="h2">Ativação</Typography>
                  <Typography>
                    Informar os dados para a ativação e liberação das licenças
                  </Typography>
                </Header>
              </Grid>
              <Grid item xs={12}>
                <form noValidate onSubmit={handleConsultarLicenca}>
                  {loading ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignContent: 'center',
                        minHeight: 120
                      }}
                    >
                      <CircularProgress />
                    </div>
                  ) : (
                      <>
                        <TextField
                          id="cpfCnpj"
                          label="CPF/CNPJ"
                          fullWidth
                          margin="normal"
                          variant="outlined"
                          value={cpfCnpj}
                          onChange={e => setCpfCnpj(e.target.value)}
                        />

                        <TextField
                          id="numeroSerie"
                          label="Número de serie"
                          size="medium"
                          fullWidth
                          margin="normal"
                          variant="outlined"
                          value={numeroSerie}
                          onChange={e => setNumeroSerie(e.target.value)}
                        />
                        {/* <Tooltip title="Deixe em branco para usar o servidor pré-configurado">
                          <TextField
                            className={classes.textField}
                            variant="outlined"
                            label="Url da API"
                            name="baseApiUrl"
                            fullWidth
                            type="text"
                            onChange={e => setBaseApiUrl(e.target.value)}
                            value={baseApiUrl || ''}
                          />
                        </Tooltip> */}
                      </>
                    )}

                  <Grid container>
                    <Grid item xs>
                      <Button
                        variant="contained"
                        margin="normal"
                        startIcon={<ArrowBack />}
                        disabled={loading}
                        onClick={() => setStep(0)}
                      >
                        Voltar
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        margin="normal"
                        startIcon={<Check />}
                      >
                        Consultar licença
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </>
          )}
        </Grid>
      </Container>

      <Snackbar
        open={snackbar.open}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        TransitionComponent={snackbar.Transition}
        message={snackbar.message}
      />
    </>
  );
};

const mapStateToProps = state => ({
  auth: state.auth
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(AuthActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Setup));
