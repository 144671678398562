import React from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { AppBar, Toolbar, Badge, Hidden, IconButton, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {
  NotificationsOutlined as IconNotificationsOutlined,
  Input as IconInput,
  Menu as IconMenu
} from '@material-ui/icons/';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Plugins } from '@capacitor/core';
import { Title } from './../../../../styles/global';
import * as AuthActions from '../../../../store/modules/auth/actions';
const { Storage } = Plugins;
const drawerWidth = 280;
const useStyles = makeStyles(theme => ({
  appBar: {
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    // backgroundColor: theme.palette.topbar,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    backgroundColor: theme.palette.topbar,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  container: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center'
  }
}));
const Topbar = ({ className, history, handleDrawerOpen, open }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const notifications = [];

  const handleLogout = () => {
    dispatch(AuthActions.logout())

    Storage.remove({ key: 'usuarioToken' })
    Storage.remove({ key: 'empresaLicenciada' })
    Storage.remove({ key: 'baseApiUrl' })
  }

  return (
    <AppBar position="absolute" className={clsx(classes.appBar)}>
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
        >
          <IconMenu />
        </IconButton>
        <Container className={classes.container} fixed>
          <Title>Backoffice</Title>
        </Container>

        {/* <IconButton color="inherit">
          <Badge badgeContent={notifications.length} color="primary" variant="dot">
            <IconNotificationsOutlined />
          </Badge>
        </IconButton> */}
        <IconButton onClick={() => handleLogout()} className={classes.signOutButton} color="inherit">
          <IconInput />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string
};

export default withRouter(Topbar);
