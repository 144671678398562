import { apiStore } from '../api';

class PedidoService {
  getPorData(data, filters) {
    return apiStore.post(`/api/backoffice/pedido/${filters}`, {dataBusca: data});
  }

  getPedido(pedidoId) {
    return apiStore.get(`/api/backoffice/pedido/${pedidoId}`);
  }

  getAll(statusPedido, filters, apenasRetirada, searchQuery) {
    return apiStore.post(`/api/backoffice/pedido/${filters}`, {statusPedido: statusPedido, apenasRetirada: apenasRetirada, termoParaBusca: searchQuery});
  }

  nextSituacao(pedidoId) { 
    return apiStore.get(`/api/backoffice/pedido/avancar-situacao/${pedidoId}`);
  }

  nextSituacaoLote(idsPedidos) { 
    return apiStore.post(`/api/backoffice/pedido/0/avancar-situacao-lote`, idsPedidos);
  }

  cancelarPedido(pedidoId, justificativa) { 
    return apiStore.post(`/api/backoffice/pedido/${pedidoId}/cancelar`, justificativa);
  }

  getMotivoCancelamento(pedidoId) {
    return apiStore.get(`/api/backoffice/pedido/${pedidoId}/motivocancelamento`);
  }

  faturarPedido(pedidoId, data){
    return apiStore.post(`/api/backoffice/pedido/${pedidoId}/faturar`, data);
  }

  aprovarPedido(pedidoId, data){
    return apiStore.post(`/api/backoffice/pedido/${pedidoId}/aprovar`);
  }

  reprovarPedido(pedidoId, data){
    return apiStore.post(`/api/backoffice/pedido/${pedidoId}/reprovar`, data);
  }
}

export default new PedidoService();
