import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: 15
  },
  detailLabel:{
    marginBottom: 15
  },
  alert: {
    marginBottom: theme.spacing(3)
  },
  media: {
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  formGroup: {
    marginBottom: theme.spacing(3)
  },
  fieldGroup: {
    display: 'flex',
    alignItems: 'center'
  },
  fieldHint: {
    margin: theme.spacing(1, 0)
  },
  tags: {
    marginTop: theme.spacing(1),
    '& > * + *': {
      marginLeft: theme.spacing(1)
    }
  },
  flexGrow: {
    flexGrow: 1
  },
  dateField: {
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  },
  modalActions: {
    borderTop: '1px solid #E4E7EB',
    padding: '15px',
    display: 'flex',
    buttonRight: {
      marginLeft: 'auto'
    },
    buttonLeft: {
      marginRight: 'auto'
    }
  },
  colorRed: {
    backgroundColor: 'red !important',
    color: 'white',
    marginTop: 15,
  },
  colorBlack:{
    backgroundColor: 'black !important',
    color: 'white',
    marginTop: 15,
  },
  colorGreen:{
    backgroundColor: 'green !important',
    color: 'white',
    marginTop: 15,
  },
  colorBlue:{
    backgroundColor: 'blue !important',
    color: 'white',
    marginTop: 15,
  },
  colorGray:{
    backgroundColor: 'gray !important',
    color: 'white',
    marginTop: 15,
  },
  divider: {
    marginTop: 15,
    marginBottom: 15
  },
  buttonAction:{
    width: '100%',
    marginTop: 5,
    marginBottom: 5
  },
  buttonPrint:{
    width: '100%',
    marginTop: 5,
    marginBottom: 5,
    backgroundColor: 'gray',
    color: 'white'
  }
}));
