import React, { useEffect, useState } from 'react';
import { format } from 'date-fns'
import { withRouter, useParams } from 'react-router-dom';
import { Loading } from '../../../components';
import { Container, CardContent, Card, CardActions } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  FormControlLabel,
  Switch,
  Button,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';

import notification from '../../../services/notification';
import usuarioService from '../../../services/modules/usuario.service';
import empresaService from '../../../services/modules/empresa.service';
import { useStyles } from './styles';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const UsuarioEdit = ({ history }) => {
  const classes = useStyles();
  let { id } = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const [showRecoveryPassword, setShowRecoveryPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [empresas, setEmpresas] = useState([]);
  const [empresaSelecionada, setEmpresaSelecionada] = useState(null);
  const [model, setModel] = useState({
    razaoSocial: '',
    nomeFantasia: '',
    enderecoLogradouro: '',
    enderecoLogradouroNumero: '',
    enderecoBairro: '',
    enderecoCep: '',
    enderecoCidade: '',
    enderecoUf: '',
    telefone: '',
    email: '',
    numeroSerie: '',
    percentualComissao: 0,
    atividadeSuspensa: true
  });

  useEffect(() => {
    const bootstrapAsync = async () => {
      setLoading(true);
      try {
        let dataModel = null

        if (id === 0) {
          dataModel = {

          }
        } else {
          const { data } = await usuarioService.getUsuario(id);
          dataModel = data;
        }

        const { data: empresasData } = await empresaService.getAll();
        setEmpresas(empresasData);

        setEmpresaSelecionada(empresasData.find(item => item.id === dataModel?.empresaId))

        setModel(dataModel);

        setLoading(false);
      } catch (error) {
        setLoading(false);
        notification.error(error);
      }
    };
    bootstrapAsync();
  }, [id]);

  const handleChange = e => {
    e.persist();

    setModel(data => ({ ...data, [e.target.id]: e.target.value }));
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);

    if (!model.senha && model.id === undefined) {
      notification.erroValidacao("Deve preencher a senha");
      setLoading(false);
      return;
    }

    if (model.senha && model.senha !== model.repetirSenha) {
      notification.erroValidacao("As senhas devem coincidir");
      setLoading(false);
      return;
    }
    if (model.empresaId == null) {
      notification.erroValidacao("Selecione uma empresa");
      setLoading(false);
      return;
    }

    try {
      if(model.id > 0){
        await usuarioService.updateUsuario(model);
      }
        else{

            let errorSaveUsuario = JSON.parse(await (await usuarioService.saveUsuario(model)).request.response);
          
            if(errorSaveUsuario.message!=undefined){
              setLoading(false);
              notification.error(errorSaveUsuario);
            }else{
              setLoading(false);
              history.push('/usuario/list');
              notification.success('Usuário cadastrado com sucesso!');
            }
          }
    } catch (error) {
      setLoading(false);
      notification.error(error);
    }
  };

  return (
    <>

      {loading ? (
        <Loading />
      ) : (
        <>
          <Container className={classes.root} fixed>
            <Card>
              <CardContent>
                <Grid container orientation="row" spacing={2}>
                  <Grid item md={9} lg={10} orientation="column">
                    <Grid container orientation="row" spacing={2}>
                      <Grid item md={12}>
                        <Typography variant="h5" component="h5">
                          {model?.email}
                        </Typography>
                        <Typography variant="body2">Empresa: {empresaSelecionada?.razaoSocial}</Typography>
                      </Grid>
                      <Grid item md={4}>
                        <TextField
                          id="email"
                          label="E-mail"
                          value={model?.email}
                          onChange={handleChange}
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={4}>
                        <Autocomplete
                          id="empresas"
                          value={empresaSelecionada}
                          onChange={(event, newValue) => {
                            setEmpresaSelecionada(newValue);
                            setModel(item => { return { ...item, empresaId: newValue?.id } })
                          }}
                          options={empresas}
                          getOptionLabel={(option) => option.razaoSocial}
                          style={{ width: 300 }}
                          renderInput={(params) => <TextField {...params} label="Empresa" variant="outlined" />}
                        />
                      </Grid>
                      <Grid item md={12}>
                        <h4>Senha</h4>
                      </Grid>
                      <Grid item md={6}>
                        <OutlinedInput
                          id="senha"
                          type={showPassword ? 'text' : 'password'}
                          value={model?.senha}
                          onChange={handleChange}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="Alternar a visibilidade da senha"
                                onClick={e => setShowPassword(!showPassword)}
                                edge="end"
                              >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={6}>
                        <OutlinedInput
                          id="repetirSenha"
                          type={showRecoveryPassword ? 'text' : 'password'}
                          value={model?.repetirSenha}
                          onChange={handleChange}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="Alternar a visibilidade da senha"
                                onClick={e => setShowRecoveryPassword(!showRecoveryPassword)}
                                edge="end"
                              >
                                {showRecoveryPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={12}>
                        <h4>Acesso</h4>
                      </Grid>
                      <Grid item md={6}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={model?.desativado ?? false}
                              onChange={() => {
                                setModel(data => ({ ...data, desativado: !data.desativado }));
                              }}
                            />
                          }
                          label="Desativado"
                        />
                        <br />
                        <FormControlLabel
                          control={
                            <Switch
                              checked={model?.administrador ?? false}
                              onChange={() => {
                                setModel(data => ({ ...data, administrador: !data.administrador }));
                              }}
                            />
                          }
                          label="Administrador"
                        />
                      </Grid>
                      <Grid item md={12}>
                        <h4>Detalhes</h4>
                      </Grid>
                      <Grid item md={3}>
                        <Typography variant="h6" gutterBottom>
                          <b>Situação do cadastro</b>
                        </Typography>
                      </Grid>
                      <Grid item md={3}>
                        <Typography variant="subtitle2" gutterBottom>
                          {model?.situacaoUsuarioToDescricao}
                        </Typography>
                      </Grid>
                      <Grid item md={6}>&nbsp;</Grid>
                      <Grid item md={3}>
                        <Typography variant="h6" gutterBottom>
                          <b>Data de Cadastro</b>
                        </Typography>
                      </Grid>
                      <Grid item md={3}>
                        <Typography variant="subtitle2" gutterBottom>
                          {model?.dataCadastro !== undefined && model?.dataCadastro !== null && (format(new Date(model?.dataCadastro), 'dd/MM/yyyy HH:mm'))}
                        </Typography>
                      </Grid>
                      <Grid item md={3}>
                        <Typography variant="h6" gutterBottom>
                          <b>Data de Desativação</b>
                        </Typography>
                      </Grid>
                      <Grid item md={3}>
                        <Typography variant="subtitle2" gutterBottom>
                          {model?.dataDesativacao !== undefined && model?.dataDesativacao !== null && (format(new Date(model?.dataDesativacao), 'dd/MM/yyyy HH:mm'))}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions className={classes.modalActions}>
                <Button style={{ marginLeft: 'auto' }} variant="contained" color="primary" onClick={handleSubmit}>
                  Salvar
                </Button>
              </CardActions>
            </Card>
          </Container>
        </>
      )}
    </>
  );
};

export default withRouter(UsuarioEdit);
