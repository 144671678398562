export const PROPS = {
    USUARIO: {
      SITUACAO: {
        CONTA_CRIADA: 1, // Conta criada e aguardando ativação no email
        CONTA_ATIVA: 2, // Conta ativa pelo email
      },
    },
    MEIO_PAGAMENTO: {
      TIPO: {
        NAO_INTEGRADO: 1,
        INTEGRADO: 2,
      },
    },
    PEDIDO: {
      SITUACAO: {
        AGUARDANDO_APROVACAO: 1,
        APROVADO: 2,
        REPROVADO: 3,
        FATURADO: 4,
        PREPARACAO: 5,
        AGUARDANDO_ENVIO: 6,
        TRANSITO: 7,
        CONCLUIDO: 8,
        CANCELADO: 9
      },
    },
    ENTREGA: {
      NORMAL: 1,
      AGENDADA: 2,
      RETIRADA: 3,
    },
  };
  
  const PARAMETROS = {
    P001: {
      nome: 'P001',
      descricao: 'Aceitar pedido somente durante o horário do expediente',
      valorPadrao: 'N'
    },
    P002: {
      nome: 'P002',
      descricao: 'Cobrar taxa de entrega',
      valorPadrao: 'N'
    },
    P003: {
      nome: 'P003',
      descricao: 'Permite agendar data e hora de entrega dos produtos pelo cliente',
      valorPadrao: 'N'
    },
  };
  
  const situacaoToDescricao = situacao => {
    let str = '';
    switch (situacao) {
      case PROPS.PEDIDO.SITUACAO.AGUARDANDO_APROVACAO:
        str = 'Aguardando aprovação';
        break;
  
      case PROPS.PEDIDO.SITUACAO.APROVADO:
        str = 'Aprovado';
        break;
  
      case PROPS.PEDIDO.SITUACAO.REPROVADO:
        str = 'Reprovado';
        break;
  
      case PROPS.PEDIDO.SITUACAO.FATURADO:
        str = 'Faturado';
        break;
  
      default:
        break;
    }
  
    return str;
  };
  export { situacaoToDescricao, PARAMETROS };
  
  