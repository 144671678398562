import React, { useEffect, useState, useRef } from 'react';
import { format } from 'date-fns'
import clsx from 'clsx';
import { withRouter, useParams } from 'react-router-dom';
import Geocode from "react-geocode";
import { compose, withProps } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import { useReactToPrint } from 'react-to-print';
import { Storage } from '@capacitor/core';
import PedidoReport from "../Reports/pedido-report"
import { apiSiaf } from '../../../services/api';
import SubjectIcon from '@material-ui/icons/Subject';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import { CheckCircle, MonetizationOn, LocalShipping, ShoppingBasket, Store, LocationOn, Home } from '@material-ui/icons';
import {
  Card,
  CardHeader,
  CardContent,
  Container,
  Grid,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Chip,
  AppBar,
  Tabs,
  Tab,
  Box,
  Divider,
  Stepper,
  Step,
  StepLabel,
  Button,
  Tooltip,
  StepConnector
} from '@material-ui/core';

import notification from '../../../services/notification';
import pedidoService from '../../../services/modules/pedido.service';
import { useStyles } from './styles';
import { PROPS } from "../../../utils/constantes";
import Loading from '../../../components/Loading';
import { makeStyles, withStyles } from '@material-ui/styles';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

function getSteps(model) {
  if (model?.tipoEntrega === PROPS.ENTREGA.RETIRADA) {
    return ['Em aprovação', 'Faturando', 'Preparação para retirada', 'Aguardando retirada', 'Entregue'];
  } else {
    return ['Em aprovação', 'Faturando', 'Preparação para envio', 'Em trânsito', 'Entregue'];
  }
}

const PedidoEdit = ({ history }) => {
  const classes = useStyles();
  let { id } = useParams();
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [activeStep, setActiveStep] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const [model, setModel] = useState(null);
  const [changeSituacaoButtonStatus, setChangeSituacaoButtonStatus] = useState(false);
  const [value, setValue] = React.useState(0);
  const [actionButtonText, setActionButtonText] = useState('Próximo passo');
  const [deliveryLocation, setDeliveryLocation] = useState([-17.5355166, -39.7486474]);
  const [empresa, setEmpresa] = useState(null);

  const [skipped] = React.useState(new Set());

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const MyMapComponent = compose(
    withProps({
      googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyB0YTya7BKu1g7xOnEqO5g_Ym9cT0eyG8c&v=3.exp&libraries=geometry,drawing,places",
      loadingElement: <div style={{ height: `100%` }} />,
      containerElement: <div style={{ height: `250px` }} />,
      mapElement: <div style={{ height: `100%` }} />,
    }),
    withScriptjs,
    withGoogleMap
  )((props) =>
    <GoogleMap
      defaultZoom={16}
      defaultCenter={{ lat: deliveryLocation[0], lng: deliveryLocation[1] }}
    >
      {props.isMarkerShown && <Marker position={{ lat: deliveryLocation[0], lng: deliveryLocation[1] }} />}
    </GoogleMap>
  )

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeSituacao = async () => {
    try {
      let confirAlterarPedido = await notification.confirm(`Alterar situação do pedido?`, `Tem certeza que deseja ${actionButtonText.toLowerCase()}?`)
      if (!confirAlterarPedido?.value) {
        return;
      }
      setLoading(true);

      if (model.situacao === 2) {
        await faturarPedido();
      } else if (model.situacao === 1) {
        await aprovarPedido();
      } else
        await pedidoService.nextSituacao(model.id);
      setLoading(false);
      bootstrapAsync();
    } catch (e) {
      setLoading(false);
    }
  };

  const faturarPedido = async () => {
    try {
      let pedidoExportado;
      if (empresa?.possuiIntegracaoSIAF === true) {
        pedidoExportado = (await apiSiaf.post("/api/faturamento/pedido", { ...model, vendedorSiafId: empresa.vendedorSiafId })).data;
        setModel(item => ({ ...item, siafId: pedidoExportado.id }));
      }

      await pedidoService.faturarPedido(model.id, { siafPedidoId: pedidoExportado?.id })
    } catch (err) {
      throw err;
    }
  }

  const aprovarPedido = async () => {
    try {
      await pedidoService.aprovarPedido(model.id)
    } catch (err) {
      throw err;
    }
  }

  const handleCancelarPedido = async () => {
    await notification.showInputAlert('Tem certeza? Esta ação é irreversível. É obrigatório o registro de uma justificativa.', 'Confirmar').then(async result => {
      if (result.value && result.value.length > 0) {
        const justificativa = { justificativa: result?.value }
        await pedidoService.cancelarPedido(model?.id, justificativa);

        bootstrapAsync();
      } else if (result?.value !== undefined) {
        notification.erroValidacao("Você deve preencher uma justificativa para cancelar um pedido.")
      }
    })
  }

  const bootstrapAsync = async () => {
    setLoading(true);
    try {
      const { data } = await pedidoService.getPedido(id);
      setModel(data);

      let activeStepToSet = 0;
      if (data.situacao === 1) {
        activeStepToSet = 0;
        setActionButtonText('Aprovar pedido')
        setChangeSituacaoButtonStatus(false);
      } else if (data.situacao === 2) {
        activeStepToSet = 1;
        setActionButtonText('Faturar pedido')
        setChangeSituacaoButtonStatus(false);
      } else if (data.situacao === 3) {
        data.situacao = -1
        setActionButtonText('Pedido Reprovado')
        setChangeSituacaoButtonStatus(true);
      } else if (data.situacao === 4) {
        activeStepToSet = 1;
        setActionButtonText('Enviar para preparação')
        setChangeSituacaoButtonStatus(false);
      } else if (data.situacao === 5) {
        activeStepToSet = 2
        if (+data?.tipoEntrega === PROPS.ENTREGA.RETIRADA)
          setActionButtonText('Marcar como pronto para retirada')
        else
          setActionButtonText('Marcar como pronto para envio')
        setChangeSituacaoButtonStatus(false);
      } else if (data.situacao === 6) {
        activeStepToSet = 2
        if (+data?.tipoEntrega === PROPS.ENTREGA.RETIRADA)
          setActionButtonText('Entregar para o cliente')
        else
          setActionButtonText('Entregar para a transportadora')
        setChangeSituacaoButtonStatus(false);
      } else if (data.situacao === 7) {
        activeStepToSet = 3
        setActionButtonText('Marcar pedido como concluído')
        setChangeSituacaoButtonStatus(false);
      } else if (data.situacao === 8) {
        activeStepToSet = 4
        setActionButtonText('Pedido Concluído')
        setChangeSituacaoButtonStatus(true);
      }
      else if (data.situacao === 9) {
        activeStepToSet = -1
        setActionButtonText('Pedido Cancelado')
        setChangeSituacaoButtonStatus(true);
      }

      setActiveStep(activeStepToSet);

      Geocode.setApiKey("AIzaSyB0YTya7BKu1g7xOnEqO5g_Ym9cT0eyG8c");
      Geocode.setLanguage("ptbr");
      Geocode.setRegion("br");

      await Geocode.fromAddress(`${data?.enderecoLogradouro},${data?.enderecoLogradouroNumero},${data?.enderecoCep},${data?.enderecoBairro},${data?.enderecoCidade},${data?.enderecoUf}`).then(
        response => {
          const { lat, lng } = response.results[0].geometry.location;
          setDeliveryLocation([lat, lng]);
        },
        error => {
          console.error(error);
        }
      )

      Storage.get({
        key: 'empresaLicenciada'
      }).then(({ value: empresaLicenciada }) => {
        setEmpresa(JSON.parse(empresaLicenciada));
      });

      setLoading(false);
    } catch (error) {
      setLoading(false);
      notification.error(error);
    }
  };

  const getClassColor = (situacaoId) => {
    let styleClass = classes.colorBlack;
    switch (situacaoId) {
      case PROPS.PEDIDO.SITUACAO.AGUARDANDO_APROVACAO:
        styleClass = classes.colorRed
        break;

      case PROPS.PEDIDO.SITUACAO.AGUARDANDO_ENVIO:
        styleClass = classes.colorRed
        break;

      case PROPS.PEDIDO.SITUACAO.APROVADO:
        styleClass = classes.colorGreen
        break;

      case PROPS.PEDIDO.SITUACAO.CONCLUIDO:
        styleClass = classes.colorGreen
        break;

      case PROPS.PEDIDO.SITUACAO.FATURADO:
        styleClass = classes.colorGray
        break;

      case PROPS.PEDIDO.SITUACAO.PREPARACAO:
        styleClass = classes.colorBlack
        break;

      case PROPS.PEDIDO.SITUACAO.REPROVADO:
        styleClass = classes.colorRed
        break;

      case PROPS.PEDIDO.SITUACAO.TRANSITO:
        styleClass = classes.colorGray
        break;

      case PROPS.PEDIDO.SITUACAO.CANCELADO:
        styleClass = classes.colorGray
        break;

      default:
        break;
    }

    return styleClass;
  }

  const stepIconsStyles = makeStyles({
    root: {
      backgroundColor: '#ccc',
      zIndex: 1,
      color: '#fff',
      width: 50,
      height: 50,
      display: 'flex',
      borderRadius: '50%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    active: {
      backgroundColor: '#2196f3',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.1)',
    },
  });

  function handleStepIcons(props) {  
    const classes = stepIconsStyles();
    const { active } = props;

    const icons = {
      1: <CheckCircle />,
      2: <MonetizationOn />,
      3: <Store />,
      4: <LocalShipping />,
      5: <Home />,
    };
  
    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
        })}
      >
        {icons[String(props.icon)]}
      </div>
    );  
  }

  useEffect(() => {
    const bootstrapIntAsync = async () => {
      setLoading(true);
      try {
        const { data } = await pedidoService.getPedido(id);
        setModel(data);

        let activeStepToSet = 0;
        if (data.situacao === 1) {
          activeStepToSet = 0;
          setActionButtonText('Aprovar pedido')
          setChangeSituacaoButtonStatus(false);
        } else if (data.situacao === 2) {
          activeStepToSet = 1;
          setActionButtonText('Faturar pedido')
          setChangeSituacaoButtonStatus(false);
        } else if (data.situacao === 3) {
          data.situacao = -1
          setActionButtonText('Pedido Reprovado')
          setChangeSituacaoButtonStatus(true);
        } else if (data.situacao === 4) {
          activeStepToSet = 1;
          setActionButtonText('Enviar para preparação')
          setChangeSituacaoButtonStatus(false);
        } else if (data.situacao === 5) {
          activeStepToSet = 2
          if (+data?.tipoEntrega === PROPS.ENTREGA.RETIRADA)
            setActionButtonText('Marcar como pronto para retirada')
          else
            setActionButtonText('Marcar como pronto para envio')
          setChangeSituacaoButtonStatus(false);
        } else if (data.situacao === 6) {
          activeStepToSet = 2
          if (+data?.tipoEntrega === PROPS.ENTREGA.RETIRADA)
            setActionButtonText('Entregar para o cliente')
          else
            setActionButtonText('Entregar para a transportadora')
          setChangeSituacaoButtonStatus(false);
        } else if (data.situacao === 7) {
          activeStepToSet = 3
          setActionButtonText('Marcar pedido como concluído')
          setChangeSituacaoButtonStatus(false);
        } else if (data.situacao === 8) {
          activeStepToSet = 4
          setActionButtonText('Pedido Concluído')
          setChangeSituacaoButtonStatus(true);
        }
        else if (data.situacao === 9) {
          activeStepToSet = -1
          setActionButtonText('Pedido Cancelado')
          setChangeSituacaoButtonStatus(true);
        }

        setActiveStep(activeStepToSet);

        Geocode.setApiKey("AIzaSyB0YTya7BKu1g7xOnEqO5g_Ym9cT0eyG8c");
        Geocode.setLanguage("ptbr");
        Geocode.setRegion("br");

        await Geocode.fromAddress(`${data?.enderecoLogradouro},${data?.enderecoLogradouroNumero},${data?.enderecoCep},${data?.enderecoBairro},${data?.enderecoCidade},${data?.enderecoUf}`).then(
          response => {
            const { lat, lng } = response.results[0].geometry.location;
            setDeliveryLocation([lat, lng]);
          },
          error => {
            console.error(error);
          }
        )

        Storage.get({
          key: 'empresaLicenciada'
        }).then(({ value: empresaLicenciada }) => {
          setEmpresa(JSON.parse(empresaLicenciada));
        });

        setLoading(false);
      } catch (error) {
        setLoading(false);
        notification.error(error);
      }
    };
    bootstrapIntAsync();
  }, [id]);
  return (
    <Container className={classes.root} fixed>
      {loading ?
        (
          <Loading></Loading>
        )
        : (<Grid container orientation="row" spacing={2}>
          <Grid item md={12} orientation="column">
            <Grid container orientation="row" spacing={2}>
              <Grid item md={12}>
                <Card>
                  <CardHeader title={"Detalhamento do pedido " + (model?.numero ?? 'S/N')} />
                  <CardContent>
                    <Grid container md={12}>
                      <Grid item md={12}>
                        <Stepper activeStep={activeStep} alternativeLabel nonLinear>
                          {getSteps(model).map((label, index) => {
                            const stepProps = {};
                            const labelProps = {};
                            if (isStepSkipped(index)) {
                              stepProps.completed = false;
                            }
                            return (
                              <Step key={label} {...stepProps}>
                                <StepLabel StepIconComponent={handleStepIcons} {...labelProps}>{label}</StepLabel>
                              </Step>
                            );
                          })}
                        </Stepper>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item md={9}>
                        <Grid container>
                          <Grid item md={12}>
                            <Typography variant="h4" gutterBottom>
                              Dados do pedido
                          </Typography>
                          </Grid>
                          <Grid item md={3}>
                            <Typography variant="h6" gutterBottom>
                              <b>Valor final</b>
                            </Typography>
                          </Grid>
                          <Grid item md={3}>
                            <Typography variant="subtitle2" gutterBottom>
                              R$&nbsp;{(model?.valorTotal ? model.valorTotal.toFixed(2) : "0,00") ?? '0,00'}
                            </Typography>
                          </Grid>
                          <Grid item md={3}>
                            <Typography variant="h6" gutterBottom>
                              <b>Forma de pagamento</b>
                            </Typography>
                          </Grid>
                          <Grid item md={3}>
                            <Typography variant="subtitle2" gutterBottom>
                              {(model?.pagamentos ? model?.pagamentos.map(item => (item.quantidadeParcelas > 0 && (<span>{item.meioPagamentoDescricao} ({item.quantidadeParcelas}x)</span>))) : "N/D") ?? "N/D"}
                            </Typography>
                          </Grid>
                          <Grid item md={3}>
                            <Typography variant="h6" gutterBottom>
                              <b>Cliente</b>
                            </Typography>
                          </Grid>
                          <Grid item md={3}>
                            <Typography variant="subtitle2" gutterBottom>
                              {(model?.clienteNome ? model.clienteNome : "N/D") ?? 'N/D'}
                            </Typography>
                          </Grid>
                          <Grid item md={3}>
                            <Typography variant="h6" gutterBottom>
                              <b>Data do pedido</b>
                            </Typography>
                          </Grid>
                          <Grid item md={3}>
                            <Typography variant="subtitle2" gutterBottom>
                              {(model?.dataEmissao ? format(new Date(model.dataEmissao), 'dd/MM/yyyy HH:mm') : "N/D") ?? 'N/D'}
                            </Typography>
                          </Grid>
                          <Grid item md={3}>
                            <Typography variant="h6" gutterBottom>
                              <b>Código transação</b>
                            </Typography>
                          </Grid>
                          <Grid item md={3}>
                            <Typography variant="subtitle2" gutterBottom>
                              {(model?.pagamentos ? model?.pagamentos.map(item => (item.hostUsnPagamento && (<span>{item.hostUsnPagamento}</span>))) : "N/D") ?? "N/D"}
                            </Typography>
                          </Grid>
                          <Grid item md={12}>
                            <Chip className={getClassColor(model?.situacao)} label={model?.situacaoDescricao} />
                            {empresa?.administrador === true && (
                              <>
                                <span>&nbsp;</span>
                                <Chip className={classes.colorGray} label={model?.empresa?.nomeFantasia} />
                              </>
                            )}
                            {model?.tipoEntrega === 3 && (
                              <>
                                <span>&nbsp;</span>
                                <Chip className={classes.colorRed} label="RETIRAR NA LOJA" />
                              </>
                            )}
                            {model?.tipoPagamento === 2 && (
                              <>
                                <span>&nbsp;</span>
                                <Chip className={classes.colorGreen} label={(<><CreditCardIcon style={{ marginBottom: '-7px', color: 'white' }} /><span>Pagamento on-line</span></>)} />
                              </>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item md={3}>
                        <Grid container>
                          <Grid item md={12}>
                            <Button variant="contained" className={classes.buttonAction} disabled={changeSituacaoButtonStatus} color="primary" onClick={(event) => { handleChangeSituacao() }}>
                              {actionButtonText}
                            </Button>
                          </Grid>
                          <Grid item md={12}>
                            <Button className={classes.buttonAction} color="secondary" disabled={changeSituacaoButtonStatus} onClick={(event) => { handleCancelarPedido() }}>
                              <span>Cancelar Pedido</span>
                            </Button>
                          </Grid>
                          <Grid item md={12}>
                            <Button variant="contained" className={classes.buttonPrint} onClick={(event) => { handlePrint() }}>
                              Imprimir
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Divider className={classes.divider} />
                    <Grid container md={12}>
                      <Grid item md={12}>
                        <div className={classes.root}>
                          <AppBar position="static" color="default">
                            <Tabs
                              value={value}
                              onChange={handleChange}
                              indicatorColor="primary"
                              textColor="primary"
                              variant="fullWidth"
                              aria-label="full width tabs example"
                            >
                              <Tab label="Pedido" {...a11yProps(0)} />
                              <Tab label="Informações de entrega" disabled={model?.tipoEntrega === 3} {...a11yProps(1)} />
                              <Tab label="Itens do pedido" {...a11yProps(2)} />
                              <Tab label="Histórico dos Status" {...a11yProps(3)} />
                            </Tabs>
                          </AppBar>
                          <TabPanel value={value} index={0}>
                            <Grid container>
                              <Grid item md={6}>
                                <Grid container>
                                  <Grid item md={12}>
                                    <Typography variant="h4" gutterBottom>
                                      Dados do cliente
                                </Typography>
                                  </Grid>
                                  <Grid item md={6} >
                                    <Typography variant="h6" gutterBottom>
                                      <b>Nome completo</b>
                                    </Typography>
                                  </Grid>
                                  <Grid item md={6}>
                                    <Typography variant="subtitle2" gutterBottom>
                                      {(model?.clienteNome ? model.clienteNome : "N/D") ?? 'N/D'}
                                    </Typography>
                                  </Grid>
                                  <Grid item md={6} >
                                    <Typography variant="h6" gutterBottom>
                                      <b>Telefone</b>
                                    </Typography>
                                  </Grid>
                                  <Grid item md={6}>
                                    <Typography variant="subtitle2" gutterBottom>
                                      {(model?.cliente?.telefone ? model?.cliente?.telefone : "N/D") ?? 'N/D'}
                                      &nbsp;<WhatsAppIcon style={{ cursor: 'pointer' }} onClick={() => {
                                        window.open(`https://api.whatsapp.com/send?phone=55${model?.cliente?.telefone ? model?.cliente?.telefone.replace(/\D/g, "") : ''}&text=Olá! Tudo bem?`, "_blank")
                                      }} />
                                    </Typography>
                                  </Grid>
                                  <Grid item md={6}>
                                    <Typography variant="h6" gutterBottom>
                                      <b>CPF</b>
                                    </Typography>
                                  </Grid>
                                  <Grid item md={6}>
                                    <Typography variant="subtitle2" gutterBottom>
                                      {(model?.clienteCpfCnpj ? model.clienteCpfCnpj : "N/D") ?? 'N/D'}
                                    </Typography>
                                  </Grid>
                                  <Grid item md={12}>
                                    <Typography variant="h4" gutterBottom>
                                      Pedido
                                </Typography>
                                  </Grid>
                                  {empresa?.possuiIntegracaoSIAF === true && (
                                    <>
                                      <Grid item md={6}>
                                        <Typography variant="h6" gutterBottom>
                                          <b>Id do pedido no SIAF</b>
                                        </Typography>
                                      </Grid>
                                      <Grid item md={6}>
                                        <Typography variant="subtitle2" gutterBottom>
                                          {(model?.siafPedidoId ? model.siafPedidoId : "N/D") ?? 'N/D'}
                                        </Typography>
                                      </Grid>
                                    </>
                                  )}
                                </Grid>
                              </Grid>
                              <Grid item md={6}>
                                <Grid container>
                                  <Grid item md={12}>
                                    <Typography variant="h4" gutterBottom>
                                      Preço
                                    </Typography>
                                  </Grid>
                                  <Grid item md={6}>
                                    <Typography variant="h6" gutterBottom>
                                      <b>Valor da compra</b>
                                    </Typography>
                                  </Grid>
                                  <Grid item md={6}>
                                    <Typography variant="subtitle2" gutterBottom>
                                      R$&nbsp;{(model?.valorProduto ? model.valorProduto.toFixed(2) : "0,00") ?? '0,00'}
                                    </Typography>
                                  </Grid>
                                  <Grid item md={6}>
                                    <Typography variant="h6" gutterBottom>
                                      <b>Desconto</b>
                                    </Typography>
                                  </Grid>
                                  <Grid item md={6}>
                                    <Typography variant="subtitle2" gutterBottom>
                                      - R$&nbsp;{(model?.valorDesconto ? model.valorDesconto.toFixed(2) : "0,00") ?? '0,00'}
                                    </Typography>
                                  </Grid>
                                  <Grid item md={6}>
                                    <Typography variant="h6" gutterBottom>
                                      <b>Acréscimo</b>
                                    </Typography>
                                  </Grid>
                                  <Grid item md={6}>
                                    <Typography variant="subtitle2" gutterBottom>
                                      R$&nbsp;{(model?.valorAcrescimo ? model.valorAcrescimo.toFixed(2) : "0,00") ?? '0,00'}
                                    </Typography>
                                  </Grid>
                                  <Grid item md={6}>
                                    <Typography variant="h6" gutterBottom>
                                      <b>Frete</b>
                                    </Typography>
                                  </Grid>
                                  <Grid item md={6}>
                                    <Typography variant="subtitle2" gutterBottom>
                                      R$&nbsp;{(model?.valorFrete ? model.valorFrete.toFixed(2) : "0,00") ?? '0,00'}
                                    </Typography>
                                  </Grid>
                                  <Grid item md={6}>
                                    <Typography variant="h6" gutterBottom>
                                      <b>Total</b>
                                    </Typography>
                                  </Grid>
                                  <Grid item md={6}>
                                    <Typography variant="subtitle2" gutterBottom>
                                      <b>R$&nbsp;{(model?.valorTotal ? model.valorTotal.toFixed(2) : "0,00") ?? '0,00'}</b>
                                    </Typography>
                                  </Grid>
                                  {model?.pagamentos.find(x => x.formaPagamento === 1) && (
                                    <>
                                      <Grid item md={12}>
                                        <Typography variant="h4" gutterBottom>
                                          Troco
                                    </Typography>
                                      </Grid>
                                      <Grid item md={6}>
                                        <Typography variant="h6" gutterBottom>
                                          <b>Valor a receber em dinheiro</b>
                                        </Typography>
                                      </Grid>
                                      <Grid item md={6}>
                                        <Typography variant="subtitle2" gutterBottom>
                                          <b>R$&nbsp;{(model?.pagamentos.find(x => x.formaPagamento === 1) ? (model.valorTroco + model?.pagamentos.find(x => x.formaPagamento === 1).valorTotal).toFixed(2) : "0,00") ?? '0,00'}</b>
                                        </Typography>
                                      </Grid>
                                      <Grid item md={6}>
                                        <Typography variant="h6" gutterBottom>
                                          <b>Troco</b>
                                        </Typography>
                                      </Grid>
                                      <Grid item md={6}>
                                        <Typography variant="subtitle2" gutterBottom>
                                          <b>R$&nbsp;{(model?.valorTroco ? (model.valorTroco).toFixed(2) : "0,00") ?? '0,00'}</b>
                                        </Typography>
                                      </Grid>
                                    </>
                                  )}

                                </Grid>
                              </Grid>
                              {model?.observacao ? (
                                <Grid item md={12}>
                                <Typography variant="h6" gutterBottom>
                                  <b>Observações do cliente</b>
                                </Typography>
                                <Typography variant="subtitle2" gutterBottom>
                                  {model?.observacao}
                                </Typography>
                              </Grid>
                              ) : ('')}
                              {model?.motivoCancelamento ? (
                                <Grid item md={12}>
                                  <Typography variant="h6" gutterBottom>
                                  <b>Motivo do cancelamento</b>
                                </Typography>
                                <Typography variant="subtitle2" gutterBottom>
                                  {model?.motivoCancelamento}
                                </Typography>
                                </Grid>
                              ) : ('')}
                            </Grid>
                          </TabPanel>
                          <TabPanel value={value} index={1}>
                            <Grid container>
                              <Grid item md={7}>
                                <Grid container>
                                  <Grid item md={12}>
                                    <Grid container>
                                      <Grid item md={12}>
                                        <Typography variant="h4" gutterBottom>
                                          Detalhes do Frete
                                </Typography>
                                      </Grid>
                                      <Grid item md={3} >
                                        <Typography variant="h6" gutterBottom>
                                          <b>Faixa</b>
                                        </Typography>
                                      </Grid>
                                      <Grid item md={3}>
                                        <Typography variant="subtitle2" gutterBottom>
                                          {model?.configuracaoFrete?.nome}
                                        </Typography>
                                      </Grid>
                                      <Grid item md={3} >
                                        <Typography variant="h6" gutterBottom>
                                          <b>Valor calculado</b>
                                        </Typography>
                                      </Grid>
                                      <Grid item md={3}>
                                        <Typography variant="subtitle2" gutterBottom>
                                          R$&nbsp;{(model?.valorFrete ? model.valorFrete.toFixed(2) : "0,00") ?? '0,00'}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid item md={12}>
                                    <Grid container>
                                      <Grid item md={12}>
                                        <Typography variant="h4" gutterBottom>
                                          Endereço de entrega
                                    </Typography>
                                      </Grid>
                                      <Grid item md={12}>
                                        <p>
                                          {model?.enderecoLogradouro}, {model?.enderecoLogradouroNumero} <br />
                                          {model?.enderecoCep} - {model?.enderecoBairro} <br />
                                          {model?.enderecoCidade}-{model?.enderecoUf}
                                        </p>
                                      </Grid>
                                      <Grid item md={12}>
                                        &nbsp;
                                  </Grid>
                                      <Grid item md={3}>
                                        <Typography variant="h6" gutterBottom>
                                          <b>Ponto de referência</b>
                                        </Typography>
                                      </Grid>
                                      <Grid item md={4}>
                                        <Typography variant="subtitle2" gutterBottom>
                                          {(model?.enderecoReferencia ? model.enderecoReferencia : "N/D") ?? 'N/D'}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item md={5}>
                                <MyMapComponent isMarkerShown />
                              </Grid>
                            </Grid>
                          </TabPanel>
                          <TabPanel value={value} index={2}>
                            <Table size="small" aria-label="Today's orders">
                              <TableHead className={classes.thead}>
                                <TableRow>
                                  {model?.possuiIntegracaoSIAF === true && (
                                    <TableCell className={classes.headCell} variant="head">
                                      Nº SIAF
                                    </TableCell>
                                  )}

                                  <TableCell className={classes.headCell} variant="head">
                                    Descrição
                                  </TableCell>
                                  <TableCell className={classes.headCell} variant="head">
                                    Grade/Observações
                                  </TableCell>
                                  <TableCell className={classes.headCell} variant="head">
                                    Valor Unitário
                                  </TableCell>
                                  <TableCell className={classes.headCell} variant="head">
                                    Quantidade
                                  </TableCell>
                                  <TableCell className={classes.headCell} variant="head">
                                    Valor Total
                                  </TableCell>
                                  <TableCell className={classes.headCell} variant="head">
                                    Pagamento
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {model?.itens?.map(row => (
                                  <TableRow className={classes.pedidoRow} key={row.id}>
                                    {model?.possuiIntegracaoSIAF === true && (
                                      <TableCell><b>{(model?.siafId ? model.siafId : "N/D") ?? 'N/D'}</b></TableCell>
                                    )}
                                    <TableCell><b>{row.produtoDescricao}</b></TableCell>
                                    <TableCell><b>
                                      {((row?.produtoGrade?.gradeEixoColuna && row?.produtoGrade?.gradeEixoLinha) ? `${row?.produtoGrade?.gradeEixoLinha} - ${row?.produtoGrade?.gradeEixoColuna}` : "") ?? ''}
                                      {row?.observacaoCliente?.length > 0 && (
                                        <Tooltip title={row?.observacaoCliente}>
                                          <SubjectIcon></SubjectIcon>
                                        </Tooltip>
                                      )}
                                    </b></TableCell>
                                    <TableCell align="center">{row.itemValorUnitario ? `R$ ${row.itemValorUnitario.toFixed(2)}` : `Sem valor`}</TableCell>
                                    <TableCell align="center">{row.itemQuantidade}</TableCell>
                                    <TableCell align="center">{row.itemValorTotal ? `R$ ${row.itemValorTotal.toFixed(2)}` : `Sem valor`}</TableCell>
                                    <TableCell align="center">{model?.pagamentos ? model?.pagamentos.map(item => item.meioPagamentoDescricao) : ''}</TableCell>
                                  </TableRow>
                                ))}

                                {model?.itens?.length === 0 && (
                                  <TableRow style={{ height: 53 }}>
                                    <TableCell colSpan={5}>Nenhuma informação encontrada</TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                          </TabPanel>
                          <TabPanel value={value} index={3}>
                            <Table size="small" aria-label="Today's orders">
                              <TableHead className={classes.thead}>
                                <TableRow><TableCell className={classes.headCell} variant="head">
                                  Status Anterior
                                  </TableCell>
                                  <TableCell className={classes.headCell} variant="head">
                                    Novo Status
                                  </TableCell>
                                  <TableCell className={classes.headCell} variant="head">
                                    Usuário
                                  </TableCell>
                                  <TableCell className={classes.headCell} variant="head">
                                    Data
                                  </TableCell>
                                  <TableCell className={classes.headCell} variant="head">
                                    Ação
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {model?.historicos?.map(row => (
                                  <TableRow className={classes.pedidoRow} key={row.id}>
                                    <TableCell align="left"><b>{(row?.situacaoDescricaoAnterior ? row.situacaoDescricaoAnterior : "N/D") ?? 'N/D'}</b></TableCell>
                                    <TableCell align="left"><b>{row.situacaoDescricao}</b></TableCell>
                                    <TableCell align="left">{(row?.usuario ? row.usuario.email : "N/D") ?? 'N/D'}</TableCell>
                                    <TableCell align="left">{format(new Date(row.dataHora), 'dd/MM/yyyy HH:mm')}</TableCell>
                                    <TableCell align="left">{(row?.acao ? row.acao : "N/D") ?? 'N/D'}</TableCell>
                                  </TableRow>
                                ))}

                                {model?.historico?.length === 0 && (
                                  <TableRow style={{ height: 53 }}>
                                    <TableCell colSpan={5}>Nenhuma informação encontrada</TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>

                          </TabPanel>
                        </div>
                      </Grid>
                    </Grid>
                  </CardContent>
                  {/* <CardActions className={classes.modalActions}>
                  </CardActions> */}
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={12}>
            <div>
              <div style={{ display: 'none' }}>
                <PedidoReport pedido={model} ref={componentRef} />
              </div>
            </div>
          </Grid>
        </Grid>)
      }
    </Container >
  );
};

export default withRouter(PedidoEdit);
