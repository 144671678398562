import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from '@material-ui/core';

import { useStyles } from './styles';

class PedidoReport extends React.Component {
  model = null;
  classes = null;
  constructor(item) {
    super();
    this.model = item.pedido;
  }

  render() {
    this.classes = useStyles();
    return (
      <>
        <Grid container style={{ paddingTop: 50, paddingLeft: 50 }}>
          <Grid item md={12} xs={12}>
            <Typography variant="h1" gutterBottom>
              <b>Pedido {this.model?.numero}</b>
            </Typography>
          </Grid>
          <Grid item md={6} xs={6}>
            <Grid container>
              <Grid item md={12} xs={12}>
                <Typography variant="h4" gutterBottom>
                  <b>Dados do pedido</b>
                </Typography>
              </Grid>
              <Grid item md={6} xs={6}>
                <Typography variant="h6" gutterBottom>
                  <b>Nome completo</b>
                </Typography>
              </Grid>
              <Grid item md={6} xs={6}>
                <Typography variant="subtitle2" gutterBottom>
                  {(this.model?.clienteNome ? this.model.clienteNome : "N/D") ?? 'N/D'}
                </Typography>
              </Grid>
              <Grid item md={6} xs={6}>
                <Typography variant="h6" gutterBottom>
                  <b>Telefone</b>
                </Typography>
              </Grid>
              <Grid item md={6} xs={6}>
                <Typography variant="subtitle2" gutterBottom>
                  {(this.model?.cliente?.telefone ? this.model?.cliente?.telefone : "N/D") ?? 'N/D'}
                </Typography>
              </Grid>
              <Grid item md={6} xs={6}>
                <Typography variant="h6" gutterBottom>
                  <b>CPF</b>
                </Typography>
              </Grid>
              <Grid item md={6} xs={6}>
                <Typography variant="subtitle2" gutterBottom>
                  {(this.model?.clienteCpfCnpj ? this.model.clienteCpfCnpj : "N/D") ?? 'N/D'}
                </Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <Typography variant="h4" gutterBottom>
                  Pedido
                </Typography>
              </Grid>
              {this.model?.siafPedidoId && (
                <>
                  <Grid item md={6}>
                    <Typography variant="h6" gutterBottom>
                      <b>Id do pedido no SIAF</b>
                    </Typography>
                  </Grid>
                  <Grid item md={6}>
                    <Typography variant="subtitle2" gutterBottom>
                      {(this.model?.siafPedidoId ? this.model?.siafPedidoId : "N/D") ?? 'N/D'}
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
          <Grid item md={6} xs={6}>
            <Grid container>
              <Grid item md={12} xs={12}>
                <Typography variant="h4" gutterBottom>
                  &nbsp;
            </Typography>
              </Grid>
              <Grid item md={6} xs={6}>
                <Typography variant="h6" gutterBottom>
                  <b>Valor da compra</b>
                </Typography>
              </Grid>
              <Grid item md={6} xs={6}>
                <Typography variant="subtitle2" gutterBottom>
                  R$&nbsp;{(this.model?.valorProduto ? this.model.valorProduto.toFixed(2) : "0,00") ?? '0,00'}
                </Typography>
              </Grid>
              <Grid item md={6} xs={6}>
                <Typography variant="h6" gutterBottom>
                  <b>Desconto</b>
                </Typography>
              </Grid>
              <Grid item md={6} xs={6}>
                <Typography variant="subtitle2" gutterBottom>
                  - R$&nbsp;{(this.model?.valorDesconto ? this.model.valorDesconto.toFixed(2) : "0,00") ?? '0,00'}
                </Typography>
              </Grid>
              <Grid item md={6} xs={6}>
                <Typography variant="h6" gutterBottom>
                  <b>Acréscimo</b>
                </Typography>
              </Grid>
              <Grid item md={6} xs={6}>
                <Typography variant="subtitle2" gutterBottom>
                  R$&nbsp;{(this.model?.valorAcrescimo ? this.model.valorAcrescimo.toFixed(2) : "0,00") ?? '0,00'}
                </Typography>
              </Grid>
              <Grid item md={6} xs={6}>
                <Typography variant="h6" gutterBottom>
                  <b>Frete</b>
                </Typography>
              </Grid>
              <Grid item md={6} xs={6}>
                <Typography variant="subtitle2" gutterBottom>
                  R$&nbsp;{(this.model?.valorFrete ? this.model.valorFrete.toFixed(2) : "0,00") ?? '0,00'}
                </Typography>
              </Grid>
              <Grid item md={6} xs={6}>
                <Typography variant="h6" gutterBottom>
                  <b>Total</b>
                </Typography>
              </Grid>
              <Grid item md={6} xs={6}>
                <Typography variant="subtitle2" gutterBottom>
                  <b>R$&nbsp;{(this.model?.valorTotal ? this.model.valorTotal.toFixed(2) : "0,00") ?? '0,00'}</b>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={12} xs={12}>
            <Typography variant="h6" gutterBottom>
              <b>Observações do cliente</b>
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              {(this.model?.observacao ? this.model.observacao : "N/D") ?? 'N/D'}
            </Typography>
          </Grid>
          <Grid item md={12} xs={12}>
            <p>&nbsp;</p>
          </Grid>
          <Grid item md={12} xs={12}>
            <Typography variant="h4" gutterBottom>
              <b>Informações de Frete</b>
            </Typography>
          </Grid>
          <Grid item md={12} xs={12}>
            <Grid container md={12} xs={12}>
              <Grid item md={12} xs={12}>
                <Grid container>
                  <Grid item md={6} xs={6}>
                    <Grid container>
                      <Grid item md={12} xs={12}>
                        <Typography variant="h5" gutterBottom>
                          Detalhes do Frete
                      </Typography>
                      </Grid>
                      <Grid item md={3} xs={3}>
                        <Typography variant="h6" gutterBottom>
                          <b>Valor calculado</b>
                        </Typography>
                      </Grid>
                      <Grid item md={3} xs={3}>
                        <Typography variant="subtitle2" gutterBottom>
                          R$&nbsp;{(this.model?.valorFrete ? this.model.valorFrete.toFixed(2) : "0,00") ?? '0,00'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={6} xs={6}>
                    <Grid container>
                      <Grid item md={12} xs={12}>
                        <Typography variant="h5" gutterBottom>
                          Endereço de entrega
                                    </Typography>
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <p>
                          {this.model?.enderecoLogradouro}, {this.model?.enderecoLogradouroNumero} <br />
                          {this.model?.enderecoCep} - {this.model?.enderecoBairro} <br />
                          {this.model?.enderecoCidade}-{this.model?.enderecoUf} <br />
                        Ponto de referência: {(this.model?.enderecoReferencia ? this.model.enderecoReferencia : "N/D") ?? 'N/D'}
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={12} xs={12}>
            <p>&nbsp;</p>
          </Grid>
          <Grid item md={12} xs={12}>
            <Typography variant="h4" gutterBottom>
              <b>Itens do pedido</b>
            </Typography>
          </Grid>
          <Grid item md={11} xs={11}>
            <Table size="small" aria-label="Itens do pedido">
              <TableHead className={this.classes.thead}>
                <TableRow>
                  {this.model?.itens[0]?.siafId && (
                    <TableCell className={this.classes.headCell} variant="head">
                      Nº SIAF
                    </TableCell>
                  )}

                  <TableCell style={{ width: '40%' }} variant="head">
                    Descrição
                  </TableCell>
                  <TableCell variant="head">
                    Grade
                  </TableCell>
                  <TableCell className={this.classes.headCell} variant="head">
                    Vl. Unit.
                  </TableCell>
                  <TableCell className={this.classes.headCell} variant="head">
                    Qt.
                  </TableCell>
                  <TableCell className={this.classes.headCell} variant="head">
                    Vl. Total
                  </TableCell>
                  <TableCell className={this.classes.headCell} variant="head">
                    Pagamento
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.model?.itens?.map(row => (
                  <TableRow className={this.classes.pedidoRow} key={row.id}>
                    {this.model?.itens[0]?.siafId && (
                      <TableCell className={this.classes.headCell} variant="head">
                        <TableCell><b>{(row?.siafId ? row?.siafId : "N/D") ?? 'N/D'}</b></TableCell>
                      </TableCell>
                    )}
                    <TableCell><b>{row.produtoDescricao}</b></TableCell>
                    <TableCell><b>
                      {((row?.produtoGrade?.gradeEixoColuna && row?.produtoGrade?.gradeEixoLinha) ? `${row?.produtoGrade?.gradeEixoLinha} - ${row?.produtoGrade?.gradeEixoColuna}` : "") ?? ''}
                    </b></TableCell>
                    <TableCell align="center">{row.itemValorUnitario ? `R$ ${row.itemValorUnitario.toFixed(2)}` : `Sem valor`}</TableCell>
                    <TableCell align="center">{row.itemQuantidade}</TableCell>
                    <TableCell align="center">{row.itemValorTotal ? `R$ ${row.itemValorTotal.toFixed(2)}` : `Sem valor`}</TableCell>
                    <TableCell align="center">{this.model?.pagamentos ? this.model?.pagamentos.map(item => item.meioPagamentoDescricao) : ''}</TableCell>
                  </TableRow>
                ))}

                {this.model?.itens?.length === 0 && (
                  <TableRow style={{ height: 53 }}>
                    <TableCell colSpan={5}>Nenhuma informação encontrada</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
        {/* <div style={{display: 'block', pageBreakBefore: 'always'}}></div>
      <div> teste </div> */}
      </>
    );
  }
}

export default withStyles(useStyles, { withTheme: true })(PedidoReport);
