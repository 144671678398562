import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { Minimal as MinimalLayout, Main as MainLayout } from './layouts';

import {
  Login as LoginVIew,
  Home as HomeView,
  PedidosList as PedidosListView,
  PedidosEdit as PedidosEditView,
  ProdutoList as ProdutoListView,
  ProdutoEdit as ProdutoEditView,
  CupomDescontoEdit as CupomDescontoEditView,
  CupomDescontoList as CupomDescontoListView,
  Exportacao as ExportacaoView,
  EmpresaEdit as EmpresaEditView,
  EmpresaList as EmpresaListView,
  UsuarioEdit as UsuarioEditView,
  UsuarioList as UsuarioListView,
  Parametros as ParametrosView,
  GrupoProdutoList as GrupoProdutoListView,
  DepartamentoList as DepartamentoListView,
  DepartamentoEdit as DepartamentoEditView,
  MeioPagamentoList as MeioPagamentoListView,
  MeioPagamentoNew as MeioPagamentoNewView,
  BannerPromocaoList as BannerPromocaoListView,
  BannerPromocaoEdit as BannerPromocaoEditView,
  Sorteio as SorteioView,
  Setup as SetupView
} from './views';
import Frete from './views/Frete/Frete';

const Routes = () => {
  return (
    <Switch>
      <Route
        render={matchProps => (
          <MinimalLayout>
            <LoginVIew {...matchProps}></LoginVIew>
          </MinimalLayout>
        )}
        exact
        path="/login"
      />

      <RouteWithLayout component={HomeView} exact requireLogin layout={MainLayout} path="/" />
      <RouteWithLayout
        component={PedidosListView}
        exact
        requireLogin
        layout={MainLayout}
        title={'Pedidos'}
        path="/pedido" />

      <RouteWithLayout
        component={PedidosEditView}
        exact
        requireLogin
        layout={MainLayout}
        title={'Pedido'}
        path="/pedido/:id" />

      <RouteWithLayout
        component={ProdutoListView}
        exact
        requireLogin
        layout={MainLayout}
        path="/produto"
        title={'Produto'}
      />
      <RouteWithLayout
        component={CupomDescontoListView}
        exact
        requireLogin
        layout={MainLayout}
        path="/cupom-desconto"
        title={'Cupom de Desconto'}
      />
      <RouteWithLayout
        component={BannerPromocaoListView}
        exact
        requireLogin
        layout={MainLayout}
        path="/banner-promocao"
        title={'Banner de promoção'}
      />
      <RouteWithLayout
        component={ProdutoEditView}
        exact
        requireLogin
        layout={MainLayout}
        path="/produto/:id"
        title={'Produto'}
      />
      <RouteWithLayout
        component={CupomDescontoEditView}
        exact
        requireLogin
        layout={MainLayout}
        path="/cupom-desconto/:id"
        title={'Cupom de Desconto'}
      />

      <RouteWithLayout
        component={BannerPromocaoEditView}
        exact
        requireLogin
        layout={MainLayout}
        path="/banner-promocao/:id"
        title={'Banner de promoção'}
      />

      <RouteWithLayout
        component={GrupoProdutoListView}
        exact
        requireLogin
        layout={MainLayout}
        path="/grupo-produto"
        title={'Departamentos'}
      />
      <RouteWithLayout
        component={DepartamentoListView}
        exact
        requireLogin
        layout={MainLayout}
        path="/departamento"
        title={'Departamentos'}
      />
      <RouteWithLayout
        component={SorteioView}
        exact
        requireLogin
        layout={MainLayout}
        path="/sorteio"
        title={'Sorteios'}
      />
      <RouteWithLayout
        component={MeioPagamentoListView}
        exact
        requireLogin
        layout={MainLayout}
        path="/meio-pagamento"
        title={'Meio pagamento'}
      />
      <RouteWithLayout
        component={SetupView}
        exact
        requireLogin
        layout={MainLayout}
        path="/setup"
        title={'Integrar com SIAF'}
      />

      <RouteWithLayout
        component={MeioPagamentoListView}
        exact
        requireLogin
        layout={MainLayout}
        path="/meio-pagamento"
        title={'Meio pagamento'}
      />

      <RouteWithLayout
        component={Frete}
        exact
        requireLogin
        layout={MainLayout}
        path="/configuracao-frete"
        title={'Configurações de frete'}
      />

      <RouteWithLayout
        component={MeioPagamentoNewView}
        exact
        requireLogin
        layout={MainLayout}
        path="/meio-pagamento/new"
        title={'Meio pagamento'}
      />
      <RouteWithLayout
        component={MeioPagamentoNewView}
        exact
        requireLogin
        layout={MainLayout}
        path="/meio-pagamento/edit/:id"
        title={'Meio pagamento'}
      />

      <RouteWithLayout
        component={ExportacaoView}
        exact
        requireLogin
        layout={MainLayout}
        path="/exportacao"
        title={'Importar dados do SIAF'}
      />
      <RouteWithLayout
        component={EmpresaEditView}
        exact
        requireLogin
        layout={MainLayout}
        path="/empresa/edit/:id"
        title={'Empresa'}
      />

      <RouteWithLayout
        component={EmpresaListView}
        exact
        requireLogin
        layout={MainLayout}
        path="/empresa/list"
        title={'Empresa'}
      />

      <RouteWithLayout
        component={UsuarioEditView}
        exact
        requireLogin
        layout={MainLayout}
        path="/usuario/edit/:id"
        title={'Usuário'}
      />

      <RouteWithLayout
        component={UsuarioListView}
        exact
        requireLogin
        layout={MainLayout}
        path="/usuario/list"
        title={'Usuário'}
      />

      <RouteWithLayout
        component={ParametrosView}
        exact
        requireLogin
        layout={MainLayout}
        path="/parametros"
        title={'Parâmetros'}
      />
    </Switch>
  );
};

export default Routes;
