import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { AppBar, Toolbar, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { Title } from './../../../../styles/global';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    // backgroundColor: theme.palette.topbar 
  },
  flexGrow: {
    flexGrow: 1
  },
  container: {
    display: 'flex',
    justifyContent: 'center'
  }
}));

const Topbar = ({ className }) => {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} color="primary" position="fixed">
      <Toolbar>
        <Container className={classes.container} fixed>
          <Title>Backoffice</Title>
        </Container>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string
};

export default Topbar;
