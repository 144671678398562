import { apiStore } from '../api';

class MeioPagamentoService {
  getCartoesBandeira(idMeioPagamento, options) {
    return apiStore.get(`/api/backoffice/meio-pagamento/${idMeioPagamento}/cartoes-bandeira`, options);
  }

  getMeioPagamentos(options) {
    return apiStore.get(`/api/backoffice/meio-pagamento/`, options);
  }

  saveMeioPagamentos(data) {
    return apiStore.put(`/api/backoffice/meio-pagamento/`, data);
  }

  saveMeioPagamento(data) {
    return apiStore.post(`/api/backoffice/meio-pagamento/`, data);
  }

  updateMeioPagamento(id, data) {
    return apiStore.put(`/api/backoffice/meio-pagamento/${id}`, data);
  }

  getMeioPagamento(id, options) {
    return apiStore.get(`/api/backoffice/meio-pagamento/${id}`, options);
  }

  deleteMeioPagamento(id, options) {
    return apiStore.delete(`/api/backoffice/meio-pagamento/${id}`, options);
  }
}

export default new MeioPagamentoService();
