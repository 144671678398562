import { apiStore } from '../api';

class AuthService {
  authenticate(data) {
    return apiStore.post('/api/auth/session', data);
  }

  getUserInfo() {
    return apiStore.get('/api/auth/session/user-info');
  }
}
export default new AuthService();
