import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  Grid,
  Divider,
  CircularProgress,
  ListItem,
  ListItemText,
  Typography
} from '@material-ui/core';
import { ImageView, InputSearch } from '../../../components';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import { ModalCustom, ModalActions, ModalContainer, ModalWrapper } from '../../../styles/shared';
import produtoGrupoService from '../../../services/modules/produto-grupo.service';
import notification from '../../../services/notification';
import freteService from '../../../services/modules/frete.service';

const GrupoProdutoAdd = ({ data, show, onHide }) => {
  const labelRefPorte = useRef()

  const [loading, setLoading] = useState(false);
  const [model, setModel] = useState(null);
  const [produtosGrupo, setProdutosGrupo] = useState([]);
  const [produtosGrupoSelecionados, setProdutosGrupoSelecionados] = useState([]);

  const handleSubmit = async e => {
    setLoading(true);

    try {
      await produtoGrupoService.saveGruposEmpresa(produtosGrupoSelecionados);
      setLoading(false);
      notification.success('Grupo salva com sucesso!');
      onHide();
    } catch (error) {
      setLoading(false);
      notification.error(error);
    }
  };

  const handleToggle = (value) => () => {
    setProdutosGrupoSelecionados(arrSet => {
      //let newArrSet = [];
      const itemToRemove = arrSet.find(x => x.id === value.id)

      if (itemToRemove) {
        const index = arrSet.indexOf(itemToRemove);
        if (index > -1) {
          arrSet.splice(index, 1);
        }
      } else {
        arrSet.push(value);
      }

      return [...arrSet]
    })
  };

  useEffect(() => {

    produtoGrupoService.getGruposGenericos().then(result => {
      if (result.data) {
        const fiteredResult = result.data.filter(item => !data?.find(item2 => item2.produtoGrupo.id === item.id));

        setProdutosGrupo(fiteredResult);
      }
    })


    setModel({ ...data });
  }, [data]);

  if (!show) return;

  return (
    <ModalCustom open aria-labelledby="server-modal-title" aria-describedby="server-modal-description">
      <ModalContainer>
        <ModalWrapper>
          <h2>Selecione os Departamentos para adicionar:</h2>
          <Grid container orientation="column" style={{ paddingTop: 10 }} spacing={2}>
            {produtosGrupo?.length > 0 && produtosGrupo?.map(item => (
              <ListItem dense button onClick={handleToggle(item)} style={produtosGrupoSelecionados.find(x => item.id === x.id) ? { backgroundColor: '#198cf0' } : {}}>
                <ListItemIcon>
                  <ImageView image={item.imagemUri} rounded={true} />
                </ListItemIcon>
                <ListItemText style={{ marginLeft: 20 }}
                  disableTypography
                  primary={<Typography type="subtitle1" style={produtosGrupoSelecionados.find(x => item.id === x.id) ? { color: 'white' } : {}}>{item.descricao}</Typography>}
                />
              </ListItem>
            ))}
            {(produtosGrupo?.length <= 0 || !produtosGrupo) && (
              <ListItem dense button>
                <ListItemText style={{ marginLeft: 20 }}
                  disableTypography
                  primary={<Typography type="subtitle1" >Nenhum item para exibir</Typography>}
                />
              </ListItem>
            )}
          </Grid>
        </ModalWrapper>
        <Divider />
        <ModalActions>
          <Button color="primary" onClick={onHide}>
            Cancelar
          </Button>
          <Button disabled={loading} color="primary" variant="contained" onClick={() => handleSubmit()}>
            {loading ? (
              <>
                <CircularProgress size={24} /> 'Salvando...'
              </>
            ) : (
                'Salvar'
              )}
          </Button>
        </ModalActions>
      </ModalContainer>
    </ModalCustom >
  );
};

export default GrupoProdutoAdd;
