import React, { useState, useEffect, createRef } from 'react';
import { withRouter } from 'react-router-dom';
import { Card, CardContent, CardActions, Grid, CardHeader } from '@material-ui/core';
import { TableContainer, Table, TableHead, TableCell, TableRow, TableBody } from '@material-ui/core';
import {
  CircularProgress,
  IconButton,
  Container,
  Button,
  Tooltip,
  Chip,
  Menu,
  MenuItem,
  Typography
} from '@material-ui/core';

import ReactDragListView from 'react-drag-listview/lib/index.js';

import {
  Star as IconStar,
  StarBorder as IconStarBorder,
  DragIndicator as IconDragIndicator,
  EditSharp as IconEditSharp,
  AddOutlined as IconAddOutlined,
  MoreVert as IconMore,
  DoneAll as IconDoneAll
} from '@material-ui/icons';

import notification from '../../../services/notification';
import grupoService from '../../../services/modules/produto-grupo.service';

import { ImageView, InputSearch } from '../../../components';
import { ContentedButtons } from '../../../styles/shared';

import GrupoProdutoEditar from '../GrupoProdutoEdit';
import GrupoProdutoAdicionar from '../GrupoProdutoAdd';

const GrupoProdutoList = ({ history }) => {
  const refInputSearch = createRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [grupoProduto, setGrupoProduto] = useState();
  const [showAdicionar, setShowAdicionar] = useState(false);
  const [showEditar, setShowEditar] = useState(false);
  const [showDestaque, setShowDestaque] = useState(false);

  const [dataSource, setDataSource] = useState({
    initialData: [],
    data: []
  });

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const results = [...dataSource.data];
      const item = results.splice(fromIndex, 1)[0];
      results.splice(toIndex, 0, item);
      setDataSource(data => ({
        ...data,
        data: [...results.map(item => ({ ...item, ordem: results.indexOf(item) + 1 }))]
      }));
    },
    nodeSelector: 'tr',
    handleSelector: '#drag'
  };

  const handleChangeDestaque = (produtoGrupo) => {
    setDataSource(source => {

      let listInputs = source.data.map(input => {
        if (input.id === produtoGrupo?.id) {
          var produtoGrupoAlterado = { ...input, destaque: !produtoGrupo.destaque };

          grupoService.updateGrupo(produtoGrupoAlterado.id, produtoGrupoAlterado);

          return produtoGrupoAlterado;
        } else {
          return { ...input }
        }
      });

      listInputs = listInputs.map(input => {
        return { ...input }
      })

      return {
        ...source,
        data: [...listInputs]
      };
    });
  }

  const filterList = event => {
    const query = event.target.value;
    if (query.trim() !== '') {
      setDataSource(data => {
        const results = data.initialData.filter(function (item) {
          return item.produtoGrupo?.descricao?.toLowerCase().search(query?.toLowerCase()) !== -1;
        });

        return {
          ...data,
          data: [...results]
        };
      });
    } else {
      setDataSource(data => ({
        ...data,
        data: [...data.initialData]
      }));
    }
  };

  const handleDestaque = id => {
    setDataSource(({ data, initialData }) => {
      const destaques = data.filter(item => item.destaque);
      const destaqueIndex = destaques.findIndex(item => item.id === id);
      const countDestaque = destaqueIndex === -1 ? destaques.length + 1 : destaques.length - 1;
      if (countDestaque > 6) {
        notification.warning('Limite excedido de grupos selecionados!');
        return { initialData, data };
      }
      const index = data.findIndex(item => item.id === id);
      data[index] = {
        ...data[index],
        destaque: !data[index].destaque
      };
      return { initialData, data: [...data] };
    });
  };

  const handleSubmit = async event => {
    event.preventDefault();

    try {
      setLoading(true);
      setShowDestaque(false);

      await grupoService.updateGrupos([...dataSource.data]);
      notification.success('Grupos salvo com sucesso!');
      setLoading(false);

      loadData();
    } catch (error) {
      setLoading(false);
      notification.error(error);
    }
  };

  const loadData = async () => {
    try {
      setLoading(true);
      const { data } = await grupoService.getGrupos();
      if (data)
        setDataSource({
          initialData: [...data],
          data: [...data]
        });
      else
        setDataSource({
          initialData: [],
          data: []
        });

      setLoading(false);
    } catch (error) {
      notification.error(error);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <Container fixed>
        <Card>
          <CardHeader title="Departamentos" />
          <CardActions style={{ justifyContent: 'space-between' }}>
            <InputSearch inputRef={refInputSearch} onChange={filterList} disabled={showDestaque} />

            <ContentedButtons>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => { setShowAdicionar(true); }}
              >
                <IconAddOutlined /> Adicionar
            </Button>
              <IconButton
                aria-label="display more actions"
                color="inherit"
                style={{ color: '#666' }}
                onClick={event => setAnchorEl(event.currentTarget)}
              >
                <IconMore />
              </IconButton>

              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
              >
                <MenuItem
                  onClick={() => {
                    refInputSearch.current.value = '';
                    setDataSource(data => ({
                      ...data,
                      data: [...data.initialData]
                    }));
                    setAnchorEl(null);
                    setShowDestaque(true);
                  }}
                >
                  Alterar destaque/ordem
                </MenuItem>
              </Menu>
            </ContentedButtons>
          </CardActions>
          {showDestaque && (
            <>
              <CardActions style={{ justifyContent: 'flex-end' }}>
                <Grid>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ marginLeft: 10, marginRight: 10 }}
                    onClick={handleSubmit}
                  >
                    <IconDoneAll /> Salvar Departamentos
                  </Button>
                  <Button variant="contained" size="small" onClick={() => setShowDestaque(false)}>
                    Cancelar ação
                  </Button>
                </Grid>
              </CardActions>
            </>
          )}
          <CardContent style={{ padding: 0 }}>
            <ReactDragListView {...dragProps}>
              <TableContainer>
                <Table stickyHeader size="small" aria-label="">
                  <TableHead>
                    <TableRow>
                      {showDestaque && <TableCell>#</TableCell>}
                      <TableCell colSpan={2}>Imagem/Descrição</TableCell>

                      {!showDestaque && <TableCell variant="head" align="right"></TableCell>}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {loading ? (
                      <TableRow>
                        <TableCell colSpan={5} rowSpan="all" align="center">
                          <CircularProgress />
                          <p>Carregando...</p>
                        </TableCell>
                      </TableRow>
                    ) : (
                        <>
                          {dataSource.data.map(row => (
                            <TableRow key={row.id}>
                              {showDestaque && (
                                <TableCell style={{ width: 30 }}>
                                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <Tooltip title="Mover">
                                      <IconButton id="drag">
                                        <IconDragIndicator />
                                      </IconButton>
                                    </Tooltip>

                                    <Tooltip title="Destaque">
                                      <IconButton onClick={() => handleDestaque(row.id)}>
                                        {row?.destaque ? <IconStar /> : <IconStarBorder />}
                                      </IconButton>
                                    </Tooltip>
                                  </div>
                                </TableCell>
                              )}

                              <TableCell style={{ width: 30 }}>
                                <ImageView image={row?.imagemUri} rounded={true} />
                              </TableCell>
                              <TableCell>
                                <Typography>{row?.produtoGrupo?.descricao}</Typography>
                                <Chip
                                size="small"
                                style={{cursor: 'pointer'}}
                                color={row?.destaque ? 'primary' : 'default'}
                                variant={row?.destaque ? 'default' : 'outlined'}
                                avatar={row?.destaque ? <IconStar color='primary' /> : <IconStarBorder color='default' />}
                                label="Destaque"
                                onClick={(event) => handleChangeDestaque(row)}
                              />
                                <Chip
                                  style={{ marginLeft: 10 }}
                                  size="small"
                                  color={row?.desativado ? 'secondary' : 'primary'}
                                  label={row?.desativado ? 'Desativado' : 'Ativo'}
                                />
                              </TableCell>
                              {!showDestaque && (
                                <TableCell align="right">
                                  <Tooltip title="Editar">
                                    <IconButton
                                      aria-label="Editar"
                                      onClick={() => {
                                        setShowEditar(true);
                                        setGrupoProduto(row);
                                      }}
                                    >
                                      <IconEditSharp />
                                    </IconButton>
                                  </Tooltip>
                                </TableCell>
                              )}
                            </TableRow>
                          ))}
                        </>
                      )}

                    {dataSource.data.length === 0 && !loading && (
                      <TableRow>
                        <TableCell colSpan={5}>Nenhuma informação encontrada</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </ReactDragListView>
          </CardContent>
          {showDestaque && (
            <>
              <CardActions style={{ justifyContent: 'flex-end' }}>
                <Grid>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ marginLeft: 10, marginRight: 10 }}
                    onClick={handleSubmit}
                  >
                    <IconDoneAll /> Salvar Departamentos
                  </Button>
                  <Button variant="contained" size="small" onClick={() => setShowDestaque(false)}>
                    Cancelar ação
                  </Button>
                </Grid>
              </CardActions>
            </>
          )}
        </Card>
      </Container>
      {showEditar && (
        <GrupoProdutoEditar
          show={showEditar}
          data={grupoProduto}
          onHide={() => {
            setShowEditar(false);
            loadData();
          }}
        />
      )}

      {showAdicionar && (
        <GrupoProdutoAdicionar
          show={showAdicionar}
          data={dataSource?.data}
          onHide={() => {
            setShowAdicionar(false);
            loadData();
          }}
        />
      )}
    </>
  );
};

export default withRouter(GrupoProdutoList);
