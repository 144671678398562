export const ACTIVATION = '@auth/ACTIVATION';
export const RESTORE_DATA = '@auth/RESTORE_DATA';
export const CLEAR_DATA = '@auth/CLEAR_DATA';
export const LOGIN = '@auth/LOGIN';
export const LOGOUT = '@auth/LOGOUT';

export function activation() {
  return {
    type: ACTIVATION
  };
}

export function restoreData({ activated, authenticated, empresaLicenciada }) {
  return {
    type: RESTORE_DATA,
    activated,
    authenticated,
    empresaLicenciada
  };
}

export function login() {
  return {
    type: LOGIN
  };
}

export function logout() {
  return {
    type: LOGOUT
  };
}
