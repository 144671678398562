import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardContent } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import { CardLink, useStyles } from './styles';
import { Storage } from '@capacitor/core';

const CardEmpresa = () => {
  const classes = useStyles();

  const [empresaLicenciada, setEmpresaLicenciada] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      const { value: empresaLicenciada } = await Storage.get({
        key: 'empresaLicenciada'
      });

      setEmpresaLicenciada(JSON.parse(empresaLicenciada));
    };

    loadData();
  }, []);

  return (
    <>
      <CardLink to={`empresa/edit/${empresaLicenciada?.id}`}>
        <Card>
        {empresaLicenciada?.administrador && (<CardHeader title="ADMINISTRADOR" />)}
        {(empresaLicenciada?.administrador !== true) && (<CardHeader title="EMPRESA" />)}
          
          <CardContent>
            <>
              <Typography variant="h5" component="h5">
                {empresaLicenciada?.razaoSocial} 
              </Typography>
              <Typography variant="body2">CPF/CNPJ: {empresaLicenciada?.cpfCnpj}</Typography>
              <Typography className={classes.address} variant="h5">
                {empresaLicenciada?.enderecoLogradouro} {empresaLicenciada?.enderecoLogradouroNumero} {','}
                {empresaLicenciada?.enderecoBairro}
                <br />
                {empresaLicenciada?.enderecoCidade} {','}
                {empresaLicenciada?.enderecoUf} {'-'}
                {empresaLicenciada?.enderecoCep}
              </Typography>
              <Divider className={classes.divider} variant="fullWidth" />

              <Typography variant="h5">
                {empresaLicenciada?.telefone}
                <br />
                {empresaLicenciada?.email}
              </Typography>
            </>
          </CardContent>
        </Card>
      </CardLink>
    </>
  );
};

export default CardEmpresa;
