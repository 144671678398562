import React, { useState, useEffect } from 'react';
import { format } from 'date-fns'
import { Card, CardHeader, CardContent } from '@material-ui/core';
import PedidoService from '../../../../services/modules/pedido.service';
import { Loading } from '../../../../components';
import { PROPS } from "../../../../utils/constantes";

import { Storage } from '@capacitor/core';
import {
  Table,
  TableBody,
  TableCell,
  Chip,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import { Container, useStyles } from './styles';


const CardPedidos = ({ history }) => {
  const classes = useStyles();
  const [pedidos, setPedidos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [empresa, setEmpresa] = useState(null);

  const getClassColor = (situacaoId) => {
    let styleClass = classes.colorBlack;
    switch (situacaoId) {
      case PROPS.PEDIDO.SITUACAO.AGUARDANDO_APROVACAO:
        styleClass = classes.colorRed
        break;

      case PROPS.PEDIDO.SITUACAO.AGUARDANDO_ENVIO:
        styleClass = classes.colorRed
        break;

      case PROPS.PEDIDO.SITUACAO.APROVADO:
        styleClass = classes.colorGreen
        break;

      case PROPS.PEDIDO.SITUACAO.CONCLUIDO:
        styleClass = classes.colorGreen
        break;

      case PROPS.PEDIDO.SITUACAO.FATURADO:
        styleClass = classes.colorGray
        break;

      case PROPS.PEDIDO.SITUACAO.PREPARACAO:
        styleClass = classes.colorBlack
        break;

      case PROPS.PEDIDO.SITUACAO.REPROVADO:
        styleClass = classes.colorRed
        break;

      case PROPS.PEDIDO.SITUACAO.TRANSITO:
        styleClass = classes.colorGray
        break;

      case PROPS.PEDIDO.SITUACAO.CANCELADO:
        styleClass = classes.colorGray
        break;

      default:
        break;
    }

    return styleClass;
  }

  const getQueryString = () => {
    let queryString = `?page=1&limit=10`;

    return queryString;
  };

  const hadleClickPedido = (event, pedidoId) => {
    history.push(`/pedido/${pedidoId}`)
  };


  useEffect(() => {
    const loadData = async () => {
      setLoading(true);

      const queryString = getQueryString();
      const { data: pedidosRes } = await PedidoService.getPorData(new Date(), queryString);
      setPedidos(pedidosRes?.rows?.filter(x => x.situacao === 1 || x.situacao === 2));

      const { value: empresaLicenciada } = await Storage.get({
        key: 'empresaLicenciada'
      });
      setEmpresa(JSON.parse(empresaLicenciada));

      setLoading(false);
    }

    loadData();
  }, [])


  return (
    <Container>
      <Card className={classes.root}>
        <CardHeader title={`Hoje (${format(new Date(), 'dd/MM/yyyy')})`} />
        <CardContent>
          <TableContainer>
            {loading && (
              <Loading />
            )}
            {!loading && (
              <Table size="small" aria-label="Today's orders">
                <TableHead>
                  <TableRow>
                    <TableCell>Cod.</TableCell>
                    {empresa?.administrador !== true && (<TableCell>Doc.</TableCell>)}
                    {empresa?.administrador === true && (<TableCell>Empresa</TableCell>)}
                    <TableCell>Cliente</TableCell>
                    <TableCell align="right">Total</TableCell>
                    <TableCell align="center">Situação</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pedidos?.map(row => (
                    <TableRow className={classes.pedidoRow} key={row.numero} onClick={(event) => { hadleClickPedido(event, row.id) }}>
                      <TableCell><b>{row.numero} &nbsp;</b>
                        {row.tipoPagamento === 2 && (<CreditCardIcon style={{ marginBottom: '-7px', color: 'green' }} />)}
                      </TableCell>
                      {empresa?.administrador !== true && (<TableCell>{row.siafPedidoId}</TableCell>)}
                      {empresa?.administrador === true && (<TableCell>{row.empresa.nomeFantasia}</TableCell>)}
                      <TableCell><b>{row.clienteNome}</b></TableCell>
                      <TableCell align="right">{row.valorTotal ? `R$ ${row.valorTotal}` : `Sem valor`}</TableCell>
                      <TableCell align="center" className={getClassColor(row.situacao)}>
                        <b>{row.situacaoDescricao}</b>
                      </TableCell>
                      <TableCell>
                        <VisibilityIcon />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        </CardContent>
      </Card>
    </Container>
  );
};

export default CardPedidos;
