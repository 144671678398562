import { apiStore } from '../api';

class UsuarioService {
  getUsuario(usuarioId) {
    return apiStore.post(`/api/backoffice/usuario/${usuarioId}`);
  }

  getAll(querystring, data) {
    return apiStore.post(`/api/backoffice/usuario/get/all${querystring}`, data);
  }

  saveUsuario(data) {
    return apiStore.post('/api/backoffice/usuario', data);
  }

  updateUsuario(data) {
    return apiStore.put('/api/backoffice/usuario', data);
  }

  updateFirebaseToken(data) {
    return apiStore.post('/api/backoffice/usuario/0/update-firebase-token', data);
  }
}
export default new UsuarioService();
