import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Divider,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';

import dataURLtoFile from '../../../utils/dataURLtoFile';

import { ImageCropper, ImageView } from '../../../components';
import { ModalCustom, ModalActions, ModalContainer, ModalWrapper } from '../../../styles/shared';
import produtoGrupoService from '../../../services/modules/produto-grupo.service';
import notification from '../../../services/notification';
import freteService from '../../../services/modules/frete.service';

const GrupoProdutoEdit = ({ data, show, onHide }) => {
  const labelRefPorte = useRef()
  const labelWidthPorte = labelRefPorte.current ? labelRefPorte.current.clientWidth : 0

  const [loading, setLoading] = useState(false);
  const [model, setModel] = useState(null);
  const [portes, setPortes] = useState([]);
  const [isCropping, setIsCropping] = useState(false);
  const [tempImage, setTempImage] = useState(null);
  const [image, setImage] = useState({
    originalname: '',
    fileName: data.imagemUri,
    fileData: null
  });

  const handleChangeImage = event => {
    const file = event.target.files[0];

    const reader = new FileReader();
    reader.onload = function () {
      setTempImage(reader.result);
      setIsCropping(true);
      setImage(data => ({ ...data, originalname: file.name }));
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleChange = (event) => {
    event.persist();
      setModel(data => ({
        ...data,
        [event.target.name]: event.target.value
      }))
  }

  const handleRemoveImage = () => {
    setImage({
      fileName: '',
      fileData: null
    });
  };

  const handleSubmit = async e => {
    //e.preventDefault();
    setLoading(true);

    let fileName = model?.imagem;
    if (image.fileData) {
      let formData = new FormData();
      formData.append('image', image.fileData);

      const { data } = await produtoGrupoService.addImagem(formData);
      fileName = data.fileName;
    }

    try {
      await produtoGrupoService.updateGrupo(model?.id, { ...model, imagem: fileName });

      setLoading(false);
      notification.success('Grupo salva com sucesso!');
      onHide();
    } catch (error) {
      setLoading(false);
      notification.error(error);
    }
  };

  useEffect(() => {
    freteService.getPortes().then(data => {
      setPortes(data.data)
    });

    setModel({ ...data });
  }, [data]);

  if (!show) return;

  return (
    <ModalCustom open aria-labelledby="server-modal-title" aria-describedby="server-modal-description">
      <ModalContainer>
        <ModalWrapper>
          <Grid container orientation="column" spacing={2}>
            <Grid item orientation="column">
              <ImageView
                image={image?.fileName}
                imageSize="large"
                handleChangeImage={handleChangeImage}
                handleRemoveImage={handleRemoveImage}
                rounded={true}
                showActions={false}
              />
              {isCropping && (
                <ImageCropper
                  image={tempImage}
                  open={isCropping}
                  onSave={croppedFile => {
                    setIsCropping(false);
                    setTempImage(false);

                    const file = dataURLtoFile(croppedFile, image.originalname);
                    setImage(data => ({
                      ...data,
                      fileName: croppedFile,
                      fileData: file
                    }));
                  }}
                  onHide={() => {
                    setIsCropping(false);
                    setTempImage(false);
                  }}
                />
              )}
            </Grid>
            <Grid item orientation="column">
              <Grid container orientation="row" spacing={2}>
                <Grid item md={12}>
                  <TextField
                    id="text-descricao"
                    name="descricao"
                    label="Descrição"
                    disabled
                    variant="outlined"
                    fullWidth
                    value={model?.produtoGrupo?.descricao}
                    onChange={e => { handleChange(e) }}
                  />
                </Grid>
                <Grid item md={12}>
                  <FormControl style={{ width: '100%' }} variant="outlined">
                    <InputLabel ref={labelRefPorte}  id="demo-simple-select-label">Porte</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="tipoPorteFreteId"
                      labelWidth={labelWidthPorte}
                      name="tipoPorteFreteId"
                      value={model?.tipoPorteFreteId}
                      onChange={e =>
                        setModel(data => ({
                          ...data,
                          tipoPorteFreteId: e.target.value
                        }))
                      }

                    >
                      <MenuItem value={null}>Nenhum</MenuItem>
                      {portes.map(porte => (
                        <MenuItem value={porte.id}>{porte.nome}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        checked={model?.desativado ?? false}
                        onChange={() =>
                          setModel(data => ({
                            ...data,
                            desativado: !model?.desativado ?? false
                          }))
                        }
                      />
                    }
                    label="Desativado"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ModalWrapper>
        <Divider />
        <ModalActions>
          <Button color="primary" onClick={onHide}>
            Cancelar
          </Button>
          <Button disabled={loading} color="primary" variant="contained" onClick={() => handleSubmit()}>
            {loading ? (
              <>
                <CircularProgress size={24} /> 'Salvando...'
              </>
            ) : (
                'Salvar'
              )}
          </Button>
        </ModalActions>
      </ModalContainer>
    </ModalCustom>
  );
};

export default GrupoProdutoEdit;
