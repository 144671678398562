import {
  BEFORE_LODING_DATA,
  AFTER_LOADING_DATA,
  CHANGE_SELECTION_ALL,
  CHANGE_SELECTION_ONE,
  ADD_PRODUCTS,
  REMOVE_PRODUCT,
  CLEAR_PRODUCTS,
  CHANGE_GROUP_PRODUCT,
  CHANGE_FRACIONAMENTO,
  CHANGE_PERMITE_FRACIONAMENTO,
  CHANGE_ALL_GROUP_PRODUCT,
  CHANGE_ALL_FRACIONAMENTO,
  SET_PAGE_COUNT
} from './actions';

import produce from 'immer';

const INITIAL_STATE = {
  loading: false,
  filters: {},
  checked: false,
  rows: [],
  rowsCount: 0,
  page: 0,
  rowsPerPage: 10,
  rowsPerPageOptions: [10, 25, 50, 100],
  selection: [],
  products: []
};

export default function produtos(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_PRODUCTS: {
      return {
        ...state,
        products: produce(state.products, draft => {
          const { selection } = state;

          selection.forEach(s => {
            const selectedIndex = draft.findIndex(product => product.id === s.id);
            if (selectedIndex === -1) {
              draft.push(s);
            }
          });
        }),
        checked: false,
        selection: []
      };
    }

    case REMOVE_PRODUCT: {
      return {
        ...state,
        products: produce(state.products, draft => {
          const selectedIndex = draft.findIndex(product => product.id === action.id);

          draft.splice(selectedIndex, 1);
        })
      };
    }

    case CHANGE_GROUP_PRODUCT: {
      return {
        ...state,
        products: produce(state.products, draft => {
          const selectedIndex = draft.findIndex(product => product.id === action.product.id);

          draft[selectedIndex].grupo.grupoProdutoGenericoId = action.grupoProdutoGenericoId;
          draft[selectedIndex] = { ...draft[selectedIndex] }
        })
      };
    }

    case CHANGE_FRACIONAMENTO: {
      return {
        ...state,
        products: produce(state.products, draft => {
          const selectedIndex = draft.findIndex(product => product.id === action.product.id);

          draft[selectedIndex].passoFracionado = action.fracionamento;
          draft[selectedIndex] = { ...draft[selectedIndex] }
        })
      };
    }

    case CHANGE_PERMITE_FRACIONAMENTO: {
      return {
        ...state,
        products: produce(state.products, draft => {
          const selectedIndex = draft.findIndex(product => product.id === action.product.id);

          draft[selectedIndex].permiteFracionar = action.permiteFracionamento;
          draft[selectedIndex] = { ...draft[selectedIndex] }
        })
      };
    }

    case CHANGE_ALL_GROUP_PRODUCT: {
      return {
        ...state,
        products: produce(state.products, draft =>
          draft.map(item => ({ ...item, grupo: { ...item.grupo, grupoProdutoGenericoId: action.grupoProdutoGenericoId } }))
        )
      };
    }

    case CHANGE_ALL_FRACIONAMENTO: {
      return {
        ...state,
        products: produce(state.products, draft =>
          draft.map(item => (
            {
              ...item,
              permiteFracionar: action.fracionamento !== 0,
              passoFracionado: ((item.permiteFracionar === true || action.fracionamento !== 0) ? action.fracionamento : null)
            }
          ))
        )
      };
    }

    case CLEAR_PRODUCTS: {
      return {
        ...state,
        products: []
      };
    }

    case CHANGE_SELECTION_ALL: {
      return {
        ...state,
        checked: !state.checked,
        selection: produce(state.selection, draft => {
          const isChecked = !state.checked;
          const { rows } = state;
          rows.forEach(row => {
            const selectedIndex = draft.findIndex(d => d.id === row.id);
            if (isChecked) {
              if (selectedIndex === -1) draft.push(row);
            } else {
              if (selectedIndex !== -1) draft.splice(selectedIndex, 1);
            }
          });
        })
      };
    }

    case CHANGE_SELECTION_ONE: {
      return {
        ...state,
        selection: produce([], draft => {
          const selectedRows = [...state.selection.map(s => ({ ...s }))];
          let newSelected = [];

          const selectedIndex = state.selection.findIndex(d => d.id === action.product.id);

          if (selectedIndex === -1) {
            selectedRows.push(action.product)
            newSelected = selectedRows;
          } else if (selectedIndex > 0) {
            for (let index = 0; index < selectedRows.length; index++) {
              if (selectedRows[index].id !== action.product.id) {
                newSelected.push(state.selection.find(itemProduct => itemProduct.id === selectedRows[index].id));
              }
            }
          }

          draft.push(...newSelected.map(s => ({ ...s })));
        })
      };
    }

    case BEFORE_LODING_DATA:
      return {
        ...state,
        loading: true,
        checked: false,
        page: action.page,
        rowsPerPage: action.rowsPerPage,
        filters: action.filters
      };

    case AFTER_LOADING_DATA:
      return {
        ...state,
        rows: action.rows,
        rowsCount: action.rowsCount,
        loading: false
      };

    case SET_PAGE_COUNT:
      return {
        ...state,
        page: action.page
      }

    default:
      return state;
  }
}
