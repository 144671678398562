import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    margin: 10,
  },
  dividerTitle:{
    paddingTop: 10,
  },
  faixaFreteH5:{
    color: '#eb5757',
    fontWeight: 'bold'
  },
  gridExterno:{
    paddingLeft: 25
  },
  gridExternoExpansionPanel:{
    paddingLeft: 25,
    marginTop: 25
  },
  gridInterno:{
    paddingLeft: 15
  },
  textFieldValores: {
    margin: 5
  },  
  gridTamanhoBox: {
    margin: 15
  },  
  addFaixaButton: { 
    margin: 10
  },
  centered:{
    marginLeft: 'auto',
    marginRight: 'auto'
  }
}));