import toast from 'cogo-toast';
import Swal from 'sweetalert2';

const Toast = Swal.mixin({
  toast: true,
  target: 'body',
  showConfirmButton: false,
  timer: 2500,
  position: 'top'
});

class Notification {
  success(message, callback) {
    Toast.fire({
      icon: 'success',
      title: message
    }).then(() => {
      if (callback) callback();
    });
  }

  pushNotification(title, message, callback) {
    const toastNotification = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 5000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })
    
    toastNotification.fire({
      title: title,
      text: message,
      icon: 'info',
    }).then(() => {
      if (callback) callback();
    });
  }

  warning(message, callback) {
    Toast.fire({
      icon: 'warning',
      title: message
    }).then(() => {
      if (callback) callback();
    });
  }

  loading(message) {
    return Swal.fire({
      title: message,
      backdrop: true,
      onBeforeOpen: () => {
        Swal.showLoading();
      }
    });
  }

  showInputAlert(message, buttonText) {
    return Swal.fire({
      text: message,
      input: 'text',
      showCancelButton: true,
      confirmButtonText: buttonText,
      cancelButtonText: 'Cancelar'
    });
  }

  showSelectAlert(message, buttonText, options) {
    return Swal.fire({
      text: message,
      input: 'select',
      inputOptions: options,
      inputPlaceholder: 'Selecione um',
      showCancelButton: true,
      confirmButtonText: buttonText,
      cancelButtonText: 'Cancelar',
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value !== undefined && value !== null) {
            resolve()
          } else {
            resolve('Você deve selecionar ao menos um item')
          }
        })
      }
    });
  }

  loadingLongOperation(message) {
    return Swal.mixin({
      toast: true,
      target: 'body',
      showConfirmButton: false,
      position: 'bottom-end',
      onBeforeOpen: () => {
        Swal.showLoading();
      }
    }).fire({
      title: message
    });
  }

  erroValidacao(message) {
    Toast.fire({
      icon: 'error',
      title: 'Ops!',
      timer: 3000,
      text: message,
      heightAuto: true
    });
  }

  error(error) {

    console.tron.log(error.message);

    toast.error(error.message, {
      position: 'bottom-right',
      heading: 'Ocorreu um erro'
    });

    // Toast.fire({
    //   icon: 'error',
    //   title: 'Ocorreu um erro',
    //   text: error.message,
    //   heightAuto: true
    // });
  }

  confirm(message, obsText){
    return Swal.fire({
      title: message,
      text: obsText,
      icon: "warning",
      confirmButtonText: "OK",
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
      dangerMode: true,
    })
  }
}

export default new Notification();
