import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import CardEmpresa from './components/CardEmpresa';
import CardMenu from './components/CardMenu';
import CardTotalizadores from './components/CardTotalizadores';
import CardPedidos from './components/CardPedidos';
import PedidoService from '../../services/modules/pedido.service';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';

import * as productActions from '../../store/modules/products/actions';
import { Storage } from '@capacitor/core';
const cardImages = {
  configuracao: require('../../assets/images/configuracao.png'),
  parametro: require('../../assets/images/parametro.png'),
  exportar: require('../../assets/images/exportar.png'),
  produto: require('../../assets/images/produto.png'),
  pedido: require('../../assets/images/pedido.png')
};

const Home = ({ history }) => {
  const dispatch = useDispatch();
  const [pedidosPendentes, setPedidosPendentes] = useState(0);
  const [empresa, setEmpresa] = useState(null);
  const loadData = async () => {
    const { data: pedidosNaoFinalizados } = await PedidoService.getAll(true, `?page=1&limit=10000000000`);
    setPedidosPendentes(pedidosNaoFinalizados?.rows?.length);

    const { value: empresaLicenciada } = await Storage.get({
      key: 'empresaLicenciada'
    });
    setEmpresa(JSON.parse(empresaLicenciada));
  };

  useEffect(() => {
    loadData();
  }, [])
  return (
    <Container fixed>
      <CssBaseline />

      <Grid container spacing={2} direction="row">
        {(pedidosPendentes > 0 && empresa?.administrador !== true) && (
          <Grid item md={12} xs={12}>
            <div
              style={{
                color: 'white',
                backgroundColor: '#e03f3f',
                padding: '8px 16px',
                borderRadius: 4
              }}
            >
              <p>
                <ReportProblemIcon style={{ marginBottom: '-7px' }} />
              Você possui {pedidosPendentes} pedidos pendentes para serem processados, <a href="/#/pedido" style={{ color: 'white' }}>clique aqui</a> para visualizá-los
            </p>
            </div>
          </Grid>
        )}
        {(empresa?.desativado && empresa?.administrador !== true) === true && (
          <Grid item md={12} xs={12}>
            <div
              style={{
                color: 'white',
                backgroundColor: '#e0c03f',
                padding: '8px 16px',
                borderRadius: 4
              }}
            >
              <p>
                <ReportProblemIcon style={{ marginBottom: '-7px' }} />
              Sua empresa está desativada, isso fará com que ninguém encontre ela no APP. <a href={`/#/empresa/edit/${empresa.id}`} style={{ color: 'white' }}>Clique aqui</a> para alterar a configuração
            </p>
            </div>
          </Grid>
        )}
        {empresa?.desativado !== true && empresa?.atividadeSuspensa && empresa?.administrador !== true && (
          <Grid item md={12} xs={12}>
            <div
              style={{
                color: 'white',
                backgroundColor: '#8a8a8a',
                padding: '8px 16px',
                borderRadius: 4
              }}
            >
              <p>
                <ReportProblemIcon style={{ marginBottom: '-7px' }} />
              Sua empresa está marcada com atividade suspensa, ela será exibida como "Fechado" no APP. <a href={`/#/empresa/edit/${empresa.id}`} style={{ color: 'white' }}>Clique aqui</a> para alterar a configuração
            </p>
            </div>
          </Grid>
        )}

        <Grid item md={4} xs={12}>
          <Grid spacing={2} container direction="row">
            <Grid item md={12} xs={12}>
              <CardEmpresa />
            </Grid>
            {(empresa?.administrador !== true) && (
              <>
                <Grid item md={6} xs={6}>
                  <CardMenu label="PRODUTOS" axius="vertical" icon={cardImages['produto']} href="/produto" onClick={dispatch(productActions.setPageCount(0))} />
                </Grid>

                <Grid item md={6} xs={6}>
                  <CardMenu label="PARÂMETROS" axius="vertical" icon={cardImages['parametro']} href="/parametros" />
                </Grid>

                <Grid item md={12} xs={12}>
                  <CardMenu label="IMPORTAR DADOS DO SIAF" axius="horizontal" icon={cardImages['exportar']} href="/exportacao" />
                </Grid>

                <Grid item md={6} xs={6}>
                  <CardMenu label="PEDIDOS" axius="vertical" icon={cardImages['pedido']} href="/pedido" />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        <Grid item md={8} xs={12}>
          <Grid spacing={2} container direction="row">
            <Grid item md={12} xs={12}>
              <CardTotalizadores />
            </Grid>

            <Grid item md={12} xs={12}>
              <CardPedidos history={history} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default withRouter(Home);
