import React from 'react';
import { InputBase } from '@material-ui/core';
import { Search as IconSearch } from '@material-ui/icons';
import { useStyles, InputSearchWrapper, InputSearchIcon } from './styles';

function InputSearch(props) {
  const classes = useStyles();

  return (
    <InputSearchWrapper>
      <InputSearchIcon>
        <IconSearch />
      </InputSearchIcon>
      <InputBase
        placeholder="Pesquisar…"
        classes={{
          input: classes.inputInput
        }}
        inputProps={{ 'aria-label': 'Pesquisa' }}
        {...props}
      />
    </InputSearchWrapper>
  );
}

export default InputSearch;
