import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Plugins } from '@capacitor/core';
import AuthService from '../../services/modules/auth.service';
import EmpresaService from '../../services/modules/empresa.service';
import { inicializarFirebase, pedirPermissaoParaReceberNotificacoes } from '../../services/modules/push-notification.service';
import { Container, Grid, Typography, Fade, CircularProgress, TextField, Snackbar, Button } from '@material-ui/core';
import { useStyles, Header, HeaderLogo } from './styles';
import firebase from 'firebase';
import * as AuthActions from '../../store/modules/auth/actions';

const { Storage } = Plugins;

const Login = ({ history, login, auth }) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [baseApiUrl] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    Transition: Fade
  });
  const [inputLogin, setLogin] = useState('');
  const [inputPassword, setPassword] = useState('');

  const handleLogin = async e => {
    e.preventDefault();

    if (inputLogin.trim() === '' || inputPassword.trim() === '') {
      setSnackbar({
        open: true,
        message: 'Usuário e senha devem ser preenchidos'
      });
      return;
    }

    setLoading(true);

    if (baseApiUrl === '')
      await Storage.set({
        key: 'baseApiUrl',
        value: null
      });
    else
      await Storage.set({
        key: 'baseApiUrl',
        value: baseApiUrl
      });

    const messaging = await firebase.messaging();
    var response = (await Notification.requestPermission())
    let firebaseToken = response

    if (response === 'granted') {
      firebaseToken = await messaging.getToken();
    }

    AuthService.authenticate({ email: inputLogin, senha: inputPassword, firebaseToken: firebaseToken })
      .then(async item => {
        if (item.isAxiosError) {
          throw new Error('Credencial incorreta. Confira seus dados e tente novamente')
        }

        const { token } = item.data;

        await Storage.set({
          key: 'usuarioToken',
          value: token
        });

        const { data: empresa } = await EmpresaService.getEmpresa();

        if (empresa !== null) {
          await Storage.set({
            key: 'empresaLicenciada',
            value: JSON.stringify(empresa)
          });

          if (empresa.possuiIntegracaoSIAF === true && empresa.dadosIntegracaoSIAF) {
            const dadosIntegracao = JSON.parse(empresa.dadosIntegracaoSIAF);

            if (dadosIntegracao.baseApiUrl === '' || dadosIntegracao.baseApiUrl === false) {
              await Storage.set({
                key: 'baseApiUrl',
                value: null
              })
            } else
              await Storage.set({
                key: 'baseApiUrl',
                value: dadosIntegracao.baseApiUrl
              })

            await Storage.set({
              key: 'chavePrivada',
              value: dadosIntegracao.chavePrivada
            });
            await Storage.set({
              key: 'storeToken',
              value: dadosIntegracao.storeToken
            });

            await Storage.set({
              key: 'siafToken',
              value: dadosIntegracao.siafToken
            });
          }


          login();

          if (token !== null) {
            try {
              pedirPermissaoParaReceberNotificacoes(true);
            } catch (error) {
              console.error(error);
            }
          }
          history.push('/');
        } else {
          setLoading(false);
          const message =
            'Parece que você não está autorizado a entrar, verifique seu acesso juntamente com nosso suporte.';
          setSnackbar({ open: true, message });
        }
      })
      .catch(({ message }) => {
        setLoading(false);
        setSnackbar({ open: true, message });
      });
  };

  return (
    <>
      <Container className={classes.root}>
        <Grid className={classes.container} container spacing={2}>
          <Grid item xs={12}>
            <Header>
              <HeaderLogo src={require('../../assets/images/login.png')} alt="" />
              <Typography className={classes.title} variant="h2">
                Login
              </Typography>
            </Header>
            <form noValidate onSubmit={handleLogin}>
              {loading ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    minHeight: 120
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                <>
                  <TextField
                    className={classes.textField}
                    variant="outlined"
                    name="login"
                    label="Login"
                    type="text"
                    fullWidth
                    onChange={e => setLogin(e.target.value)}
                    value={inputLogin || ''}
                  />
                  <TextField
                    className={classes.textField}
                    variant="outlined"
                    label="Senha"
                    name="password"
                    fullWidth
                    type="password"
                    onChange={e => setPassword(e.target.value)}
                    value={inputPassword || ''}
                  />
                  {/* <Tooltip title="Deixe em branco para usar o servidor pré-configurado">
                      <TextField
                        className={classes.textField}
                        variant="outlined"
                        label="Url da API"
                        name="baseApiUrl"
                        fullWidth
                        type="text"
                        onChange={e => setBaseApiUrl(e.target.value)}
                        value={baseApiUrl || ''}
                      />
                    </Tooltip> */}
                </>
              )}

              <Button
                className={classes.signInButton}
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Login
              </Button>
            </form>
          </Grid>
        </Grid>
      </Container>

      <Snackbar
        open={snackbar.open}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        TransitionComponent={snackbar.Transition}
        message={snackbar.message}
      />
    </>
  );
};

Login.propTypes = {
  history: PropTypes.object
};

const mapStateToProps = state => ({
  ...state.auth,
  auth: state.auth
});

const mapDispatchToProps = dispatch => bindActionCreators(AuthActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Login);
