export const useStyles = theme => ({
  root: {
    marginBottom: 15
  },
  detailLabel:{
    marginBottom: 15
  },
  alert: {
    marginBottom: 3
  },
  media: {
  },
  formControl: {
    margin: 1,
    minWidth: 120,
  },
  paperBorder:{
    marginTop: 50,
    marginBottom: 80,
    marginLeft: 80,
    marginRight: 80,
  },
  formGroup: {
    marginBottom: 3
  },
  fieldGroup: {
    display: 'flex',
    alignItems: 'center'
  },
  fieldHint: {
    margin: 1
  },
  tags: {
    marginTop: 1,
    '& > * + *': {
      marginLeft: 1
    }
  },
  flexGrow: {
    flexGrow: 1
  },
  dateField: {
    '& + &': {
      marginLeft: 2
    }
  },
  modalActions: {
    borderTop: '1px solid #E4E7EB',
    padding: '15px',
    display: 'flex',
    buttonRight: {
      marginLeft: 'auto'
    },
    buttonLeft: {
      marginRight: 'auto'
    }
  },
  colorRed: {
    backgroundColor: 'red !important',
    color: 'white',
    marginTop: 15,
  },
  colorBlack:{
    backgroundColor: 'black !important',
    color: 'white',
    marginTop: 15,
  },
  colorGreen:{
    backgroundColor: 'green !important',
    color: 'white',
    marginTop: 15,
  },
  colorBlue:{
    backgroundColor: 'blue !important',
    color: 'white',
    marginTop: 15,
  },
  colorGray:{
    backgroundColor: 'gray !important',
    color: 'white',
    marginTop: 15,
  },
  divider: {
    marginTop: 15,
    marginBottom: 15
  },
  buttonAction:{
    width: '100%',
    marginTop: 5,
    marginBottom: 5
  }
});
