import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import { List, ListItem, ListItemIcon, ListItemText, Container, Grid, IconButton } from '@material-ui/core';
import { Storage } from '@capacitor/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CachedIcon from '@material-ui/icons/Cached';

import {
  Home as IconHome,
  ShoppingCart as IconShoppingCart,
  ViewList as IconViewList,
  CreditCard as IconCreditCard,
  CloudUpload as IconCloudUpload,
  Folder as IconFolder,
  LocalShipping as LocalShippingIcon,
  ConfirmationNumber as ConfirmationNumberIcon,
  AccountBalance as AccountBalanceIcon,
  Person as PersonIcon,
  ViewCarousel as ViewCarouselIcon
} from '@material-ui/icons';

import { Topbar } from './components';
// import { Footer } from '../../components';

const drawerWidth = 280;
const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    paddingTop: 64,
    display: 'flex'
  },
  content: {
    flexGrow: 1,
    height: `calc(100vh - 120px)`,
    overflow: 'auto',
    marginTop: -15
  },
  title: {
    display: 'flex',
    flex: 1,
    justifyContent: 'start',
    alignItems: 'center',
    fontSize: 16,
    textTransform: 'uppercase',
    color: '#636363',
    padding: '20px',
    marginBottom: '-10px',
    marginTop: '-10px'
  },
  backbutton: {
    cursor: 'pointer'
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9)
    }
  }
}));

const Main = ({ children, title, history }) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [empresa, setEmpresa] = useState(null);
  const handleDrawerOpen = () => {
    setOpen(state => !state);
  };

  const handleBack = () => {
    history.goBack();
  };

  const loadData = async () => {
    const { value: empresaLicenciada } = await Storage.get({
      key: 'empresaLicenciada'
    });
    setEmpresa(JSON.parse(empresaLicenciada));
  }

  useEffect(() => { loadData() }, []);

  return (
    <div
      className={clsx({
        [classes.root]: true
      })}
    >
      <Topbar handleDrawerOpen={handleDrawerOpen} open={open} />

      {empresa?.administrador !== true && (
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose)
          }}
          open={open}
        >
          <List>
            <ListItem button onClick={() => history.push('/')}>
              <ListItemIcon>
                <IconHome />
              </ListItemIcon>
              <ListItemText primary="Inicio" />
            </ListItem>
            <ListItem button onClick={() => history.push('/pedido')}>
              <ListItemIcon>
                <IconShoppingCart />
              </ListItemIcon>
              <ListItemText primary="Meus Pedidos" />
            </ListItem>
            <ListItem button onClick={() => history.push('/cupom-desconto')}>
              <ListItemIcon>
                <ConfirmationNumberIcon />
              </ListItemIcon>
              <ListItemText primary="Cupons de Desconto" />
            </ListItem>
            <ListItem button onClick={() => history.push('/produto')}>
              <ListItemIcon>
                <IconViewList />
              </ListItemIcon>
              <ListItemText primary="Produtos" />
            </ListItem>
            <ListItem button onClick={() => history.push('/grupo-produto')}>
              <ListItemIcon>
                <IconFolder />
              </ListItemIcon>
              <ListItemText primary="Departamentos" />
            </ListItem>
            <ListItem button onClick={() => history.push('/meio-pagamento')}>
              <ListItemIcon>
                <IconCreditCard />
              </ListItemIcon>
              <ListItemText primary="Meios de pagamento" />
            </ListItem>
            <ListItem button onClick={() => history.push('/configuracao-frete')}>
              <ListItemIcon>
                <LocalShippingIcon />
              </ListItemIcon>
              <ListItemText primary="Configurações de Frete" />
            </ListItem>
          </List>
          {empresa?.possuiIntegracaoSIAF === true && (
            <>
              <Divider />
              <List>
                <ListItem button onClick={() => history.push('/exportacao')}>
                  <ListItemIcon>
                    <IconCloudUpload />
                  </ListItemIcon>
                  <ListItemText primary="Importar dados do SIAF" secondary="Produtos" />
                </ListItem>
              </List>
            </>
          )}
        </Drawer>
      )}
      {empresa?.administrador === true && (
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose)
          }}
          open={open}
        >
          <List>
            <ListItem button onClick={() => history.push('/')}>
              <ListItemIcon>
                <IconHome />
              </ListItemIcon>
              <ListItemText primary="Inicio" />
            </ListItem>
            <ListItem button onClick={() => history.push('/empresa/list')}>
              <ListItemIcon>
                <AccountBalanceIcon />
              </ListItemIcon>
              <ListItemText primary="Empresas" />
            </ListItem>
            <ListItem button onClick={() => history.push('/usuario/list')}>
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText primary="Usuários" />
            </ListItem>
            <ListItem button onClick={() => history.push('/pedido')}>
              <ListItemIcon>
                <IconShoppingCart />
              </ListItemIcon>
              <ListItemText primary="Pedidos" />
            </ListItem>
            <ListItem button onClick={() => history.push('/cupom-desconto')}>
              <ListItemIcon>
                <ConfirmationNumberIcon />
              </ListItemIcon>
              <ListItemText primary="Cupons de Desconto" />
            </ListItem>
            <ListItem button onClick={() => history.push('/departamento')}>
              <ListItemIcon>
                <IconFolder />
              </ListItemIcon>
              <ListItemText primary="Departamentos" />
            </ListItem>
            <ListItem button onClick={() => history.push('/banner-promocao')}>
              <ListItemIcon>
                <ViewCarouselIcon />
              </ListItemIcon>
              <ListItemText primary="Banners de promoção" />
            </ListItem>
            <ListItem button onClick={() => history.push('/sorteio')}>
              <ListItemIcon>
                <CachedIcon />
              </ListItemIcon>
              <ListItemText primary="Sorteios" />
            </ListItem>
          </List>
        </Drawer>
      )}

      <Grid container>
        <Grid item md={12}>
          <Container>
            <div className={classes.title}>
              <IconButton
                className={classes.backbutton}
                onClick={e => {
                  handleBack();
                }}
              >
                <ArrowBackIcon />
              </IconButton>
              &nbsp; {title}
            </div>
          </Container>
        </Grid>
        <Grid item md={12}>
          <main className={classes.content}>{children}</main>
        </Grid>
      </Grid>
      {/* <Footer /> */}
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default withRouter(Main);
