import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { withRouter, useParams } from 'react-router-dom';
import { apiSiaf } from '../../../services/api';
import { Loading } from '../../../components';
import { Container, CardContent, Card, CardActions } from '@material-ui/core';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import { Storage } from '@capacitor/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import meioPagamentoService from '../../../services/modules/meio-pagamento.service';
import { CurrencyFormatCustom } from '../../../components'
import {
  FormControlLabel,
  Paper,
  Switch,
  Button,
  FormControl,
  InputLabel,
  Select,
  ListItemText,
  MenuItem,
  Grid,
  Divider,
  Link,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  CircularProgress,
  Checkbox,
  InputAdornment,
  Typography,
  Tooltip
} from '@material-ui/core';
import {
  Search as IconSearch,
  Delete as IconDelete,
  PhotoCamera as IconPhotoCamera,
  Cancel as IconCancel,
  ImportExport as ImportExportIcon,
  Close as CloseIcon
} from '@material-ui/icons';
import notification from '../../../services/notification';
import empresaService from '../../../services/modules/empresa.service';
import {
  PhoneTextMaskCustom,
  CellPhoneTextMaskCustom,
  CepTextMaskCustom,
  CNPJTextMaskCustom,
  TimeTextMaskCustom,
  ImageCropper,
  DiasSemana
} from '../../../components';
import { useStyles } from './styles';
import dataURLtoFile from '../../../utils/dataURLtoFile';

const EmpresaEdit = ({ history }) => {
  const classes = useStyles();
  let { id } = useParams();
  const fileUpload = React.createRef();
  const [loading, setLoading] = useState(false);
  const [isCropping, setIsCropping] = useState(false);
  const [categorias, setCategorias] = useState([]);
  const [vendedores, setVendedores] = useState([]);
  const [categoriaSelecionada, setCategoriaSelecionada] = useState(null);
  const [tempLogo, setTempLogo] = useState(null);
  const [openColor, setOpenColor] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [horarioFuncionamento, setHorarioFuncionamento] = useState(null);
  const [empresaLicenciada, setEmpresaLicenciada] = useState(null);
  const [bandeiras, setBandeiras] = useState([]);
  const [logo, setLogo] = useState({
    originalname: '',
    fileName: null,
    fileData: null
  });
  const [model, setModel] = useState({
    razaoSocial: '',
    nomeFantasia: '',
    enderecoLogradouro: '',
    enderecoLogradouroNumero: '',
    enderecoBairro: '',
    enderecoCep: '',
    enderecoCidade: '',
    enderecoUf: '',
    telefone: '',
    email: '',
    numeroSerie: '',
    percentualComissao: 0,
    atividadeSuspensa: true
  });

  const coresPrincipais = [
    { color: 'Red', name: 'Vermelho', darkContent: false },
    { color: 'Pink', name: 'Rosa', darkContent: true },
    { color: 'Purple', name: 'Roxo', darkContent: false },
    { color: '#551a4d', name: 'Roxo Escuro', darkContent: false },
    { color: 'Indigo', name: 'Indigo', darkContent: false },
    { color: 'Blue', name: 'Azul', darkContent: false },
    { color: '#698bd0', name: 'Azul Claro', darkContent: false },
    { color: 'Cyan', name: 'Ciano', darkContent: true },
    { color: 'Teal', name: 'Teal', darkContent: false },
    { color: 'Green', name: 'Verde', darkContent: false },
    { color: '#a5d610', name: 'Verde Claro', darkContent: false },
    { color: 'Lime', name: 'Lima', darkContent: true },
    { color: 'Yellow', name: 'Amarelo', darkContent: true },
    { color: '#ffd8ab', name: 'Amber', darkContent: true },
    { color: 'Orange', name: 'Laranja', darkContent: false },
    { color: '#e9692c', name: 'Laranja Escuro', darkContent: false }
  ];

  const handleOpen = () => {
    setOpen(true);
  };

  const handleRemoveIntegracaoSIAF = async () => {
    notification
      .confirm(
        'Remover Integração com SIAF',
        'Tem certeza que deseja remover a integração com SIAF?'
      )
      .then(async result => {
        if (result.value === true) {
          await Storage.set({
            key: 'baseApiUrl',
            value: null
          })

          await Storage.set({
            key: 'chavePrivada',
            value: null
          });

          await Storage.set({
            key: 'storeToken',
            value: null
          });

          await Storage.set({
            key: 'siafToken',
            value: null
          });

          const { data: empresaLicenciadaAtualizada } = await empresaService.getEmpresa(0);

          empresaLicenciadaAtualizada.dadosIntegracaoSIAF = null

          empresaLicenciadaAtualizada.possuiIntegracaoSIAF = false;

          await empresaService.updateEmpresa(empresaLicenciadaAtualizada);

          await Storage.set({
            key: 'empresaLicenciada',
            value: JSON.stringify(empresaLicenciadaAtualizada)
          });

          history.push('/');
        }
      });

  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenColor = () => {
    setOpenColor(true);
  };

  const handleCloseColor = () => {
    setOpenColor(false);
  };

  const handleChangeColor = cor => {
    setModel(data => {
      data.cor = cor.color;
      data.darkContent = cor.darkContent;

      return { ...data };
    });

    handleCloseColor();
  };

  const handleChangeBandeiras = event => {
    event.persist();

    const selected = event.target.value || [];
    setModel(data => ({ ...data, esitefCartoesBandeiraPermitidos: [...selected] }));
  };

  useEffect(() => {
    const bootstrapAsync = async () => {
      let source = axios.CancelToken.source();
      setLoading(true);
      const options = {
        cancelToken: source.token
      };
      try {
        let model = null;

        if (+id === 0) {
          model = {
            razaoSocial: '',
            nomeFantasia: '',
            cpfCnpj: '',
            valorMinimoPedido: 0,
            numeroSerie: '',
            email: '',
            categoriaId: null,
            textoSlogan: '',
            textoRodape: '',
            darkContent: true,
            logo: '',
            enderecoLogradouro: '',
            enderecoLogradouroNumero: '',
            enderecoVisivel: true,
            enderecoReferencia: '',
            enderecoBairro: '',
            enderecoCidade: '',
            enderecoUf: '',
            enderecoCep: '',
            cor: '',
            atividadeSuspensa: true,
            percentualComissao: 0,
            desativado: true,
            administrador: false,
            horarioAtendimento: ''
          };
        } else {
          const { data } = await empresaService.getEmpresa(id);
          model = data;
        }

        try {
          const { data: _vendedores } = await apiSiaf.get('/api/faturamento/vendedores', options);

          setVendedores(_vendedores);
        } catch (e) {
          console.error(e);
        }

        const { data: cartoesBandeira } = await meioPagamentoService.getCartoesBandeira(id, options);
        setBandeiras(cartoesBandeira);

        const { data: categoriasData } = await empresaService.getAllCategorias();
        setCategorias(categoriasData);

        setCategoriaSelecionada(categoriasData.find(item => item.id === model.categoriaId));

        const { value: empresaLicenciadaStorage } = await Storage.get({
          key: 'empresaLicenciada'
        });
        setEmpresaLicenciada(JSON.parse(empresaLicenciadaStorage));

        setModel({
          ...model,
          esitefCartoesBandeiraPermitidos: model.esitefCartoesBandeiraPermitidos ? model.esitefCartoesBandeiraPermitidos.split(',').map(x => +x) : []
        });
        var horarioFuncionamento = [];
        if (model.horarioAtendimento && model.horarioAtendimento.length > 0)
          horarioFuncionamento = JSON.parse(model.horarioAtendimento);

        if (!horarioFuncionamento || horarioFuncionamento.length < 7) {
          let diaCont = 0;

          while (diaCont <= 6) {
            horarioFuncionamento.push({
              diaSemana: diaCont,
              aberto: true,
              horarios: [{ horaInicio: '08:00', horaFim: '18:00' }]
            });

            diaCont = diaCont + 1;
          }
        }

        setHorarioFuncionamento(horarioFuncionamento);

        setLogo({
          originalname: model?.logo,
          fileName: model?.logoUri ?? null,
          fileData: null
        });

        setLoading(false);
      } catch (error) {
        setLoading(false);
        notification.error(error);
      }
    };
    bootstrapAsync();
  }, [id]);

  const handleChange = e => {
    e.persist();

    setModel(data => ({ ...data, [e.target.id]: e.target.value}));
  };

  const handleRemoveHorario = (diaSemana, hora) => {
    setHorarioFuncionamento(horarios => {
      const list = horarios;
      var newList = [];
      list.forEach(item => {
        var horaParaExcluir = item.horarios.find(x => x === hora);
        var index = item.horarios.indexOf(horaParaExcluir);

        if (index >= 0) item.horarios.splice(index, 1);

        newList.push(item);
      });
      return [...newList];
    });
  };

  const handleActivateSIAF = () => {
    history.push('/setup');
  };

  const handleAddHorario = diaSemana => {
    setHorarioFuncionamento(horarios => {
      const list = horarios;
      var dia = list.find(x => x.diaSemana === diaSemana);

      dia.horarios.push({ horaInicio: '00:00', horaFim: '00:00' });
      return [...list];
    });
  };

  const getTextDiaSemana = diaSemana => {
    return DiasSemana.find(x => +x.value === diaSemana)?.text;
  };

  const handleAutocompleteChange = (event, horario, hora) => {
    event.persist();
    setHorarioFuncionamento(data => {
      const listInputs = data.map(input => {
        if (input === horario) {
          input.horarios = input.horarios.map(horaTem => {
            if (horaTem === hora) {
              return { ...horaTem, [event.target.name]: event.target.value };
            } else {
              return { ...horaTem };
            }
          });
        }
        return { ...input };
      });

      return [...listInputs];
    });
  };

  const handleInputsChange = (event, itemLista) => {
    event.persist();
    setHorarioFuncionamento(data => {
      const listInputs = data.map(input => {
        if (input === itemLista) {
          if (event.target.value) return { ...input, [event.target.name]: event.target.value };
          else return { ...input, [event.target.name]: event.target.checked };
        } else {
          return { ...input };
        }
      });

      return [...listInputs];
    });
  };

  const handleLogoChange = event => {
    const file = event.target.files[0];

    const reader = new FileReader();
    reader.onload = function () {
      setTempLogo(reader.result);
      setIsCropping(true);
      setLogo(data => ({ ...data, originalname: file.name }));
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveLogo = () => {
    setLogo({
      fileName: '',
      fileData: null
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);

    let fileName = model.logo;
    if (logo.fileData) {
      let formData = new FormData();
      formData.append('image', logo.fileData);

      const { data } = await empresaService.addLogo(formData);
      fileName = data.fileName;
    }

    if (model.valorMinimoPedido) {
      model.valorMinimoPedido = model?.valorMinimoPedido.replace(',', '.')
    }

    if(!model?.email || model?.email?.length <= 0){
      notification.erroValidacao('O campo "e-mail" é de preenchimento obrigatório')
      setLoading(false);
      return;
    }

    try {
      model.horarioAtendimento = JSON.stringify(horarioFuncionamento);
      const response =
        model.id > 0
          ? await empresaService.updateEmpresa({ ...model, logo: fileName, esitefCartoesBandeiraPermitidos: model.esitefCartoesBandeiraPermitidos.join(',') })
          : await empresaService.saveEmpresa({ ...model, logo: fileName, esitefCartoesBandeiraPermitidos: model.esitefCartoesBandeiraPermitidos.join(',') });

      const empresa = response.data;

      if (empresa.id === empresaLicenciada.id) {
        await Storage.set({ key: 'empresaLicenciada', value: JSON.stringify(empresa) });
      }

      setLoading(false);
      history.push('/');
      notification.success('Empresa salva com sucesso!');
    } catch (error) {
      setLoading(false);
      notification.error(error);
    }
  };

  const handleConsultaCep = async cep => {
    const api = axios.create({});
    setLoading(true);
    try {
      const { data: responseData } = await api.get(`https://viacep.com.br/ws/${cep}/json/`);
      setModel({
        ...model,
        enderecoLogradouro: responseData.logradouro,
        enderecoLogradouroNumero: '',
        enderecoBairro: responseData.bairro,
        enderecoCidade: responseData.localidade,
        enderecoUf: responseData.uf
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      notification.error(error);
    }
  };

  return (
    <>
      {isCropping && (
        <ImageCropper
          image={tempLogo}
          open={isCropping}
          onSave={croppedFile => {
            setIsCropping(false);
            setTempLogo(false);

            const file = dataURLtoFile(croppedFile, logo.originalname);
            setLogo(data => ({
              ...data,
              fileName: croppedFile,
              fileData: file
            }));
          }}
          onHide={() => {
            setIsCropping(false);
            setTempLogo(false);
          }}
        />
      )}

      {loading ? (
        <Loading />
      ) : (
          <>
            <Container className={classes.root} fixed>
              <Card>
                <CardContent>
                  <Grid container orientation="row" spacing={2}>
                    <Grid item md={3} lg={2} orientation="column">
                      <Paper>
                        <div className={classes.logoContainer}>
                          {logo.fileName ? (
                            <img src={logo?.fileName} className={classes.logoImage} alt="Logomarca da empresa" />
                          ) : (
                              <img
                                src={require('../../../assets/images/not-found.png')}
                                className={classes.logoImage}
                                alt="Informar logo"
                              />
                            )}

                          <input
                            accept="image/*"
                            className={classes.logoInputFile}
                            ref={fileUpload}
                            type="file"
                            onChange={handleLogoChange}
                          />
                        </div>
                        <Divider />
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <IconButton aria-label="Remover imagem" onClick={() => fileUpload.current.click()}>
                            <IconPhotoCamera />
                          </IconButton>

                          <IconButton aria-label="Remover logo" onClick={() => handleRemoveLogo()}>
                            <IconDelete />
                          </IconButton>
                        </div>
                      </Paper>
                    </Grid>
                    <Grid item md={9} lg={10} orientation="column">
                      <Grid container orientation="row" spacing={2}>
                        <Grid item md={12}>
                          <Typography variant="h5" component="h5">
                            {model?.razaoSocial}
                          </Typography>
                          <Typography variant="body2">CPF/CNPJ: {model.cpfCnpj}</Typography>
                        </Grid>
                        {empresaLicenciada?.administrador && (
                          <Grid item md={3}>
                            <TextField
                              id="cpfCnpj"
                              label="CNPJ"
                              value={model?.cpfCnpj}
                              onChange={handleChange}
                              variant="outlined"
                              fullWidth
                              InputProps={{
                                inputComponent: CNPJTextMaskCustom
                              }}
                            />
                          </Grid>
                        )}
                        <Grid item md={5}>
                          <TextField
                            id="nomeFantasia"
                            label="Nome fantasia"
                            value={model?.nomeFantasia}
                            onChange={handleChange}
                            variant="outlined"
                            fullWidth
                          />
                        </Grid>

                        {empresaLicenciada?.administrador && (
                          <Grid item md={4}>
                            <TextField
                              id="razaoSocial"
                              label="Razão Social"
                              value={model?.razaoSocial}
                              onChange={handleChange}
                              variant="outlined"
                              fullWidth
                            />
                          </Grid>
                        )}

                        <Grid item md={12}>
                          <TextField
                            id="textoSlogan"
                            label="Texto do título da página"
                            value={model?.textoSlogan || ''}
                            onChange={handleChange}
                            variant="outlined"
                            fullWidth
                          />
                        </Grid>
                        <Grid item md={3}>
                          <TextField
                            id="valorMinimoPedido"
                            label="Valor mínimo do pedido"
                            value={model?.valorMinimoPedido ? model?.valorMinimoPedido : ''}
                            variant="outlined"
                            onChange={handleChange}
                            fullWidth
                            InputProps={{
                              inputComponent: CurrencyFormatCustom,
                              startAdornment: <InputAdornment position="start">R$</InputAdornment>
                            }}
                          />
                        </Grid>
                        {empresaLicenciada?.administrador && (
                          <Grid item md={3}>
                            <TextField
                              id="percentualComissao"
                              label="Percentual da comissão"
                              value={model?.percentualComissao}
                              onChange={handleChange}
                              variant="outlined"
                              fullWidth
                              InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>
                              }}
                            />
                          </Grid>
                        )}
                        {empresaLicenciada?.administrador && (
                          <Grid item md={6}>
                            <Autocomplete
                              id="categorias"
                              value={categoriaSelecionada}
                              onChange={(event, newValue) => {
                                setCategoriaSelecionada(newValue);
                                setModel(item => {
                                  return { ...item, categoriaId: newValue?.id };
                                });
                              }}
                              options={categorias}
                              getOptionLabel={option => option.descricao}
                              fullWidth
                              renderInput={params => <TextField {...params} label="Categoria" variant="outlined" />}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item md={12}>
                      <TextField
                        id="textoRodape"
                        label="Texto do rodapé da página"
                        value={model?.textoRodape || ''}
                        onChange={handleChange}
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={3}
                      />
                    </Grid>
                    <Divider className={classes.divider} variant="fullWidth" />
                    <Grid item md={4}>
                      <TextField
                        id="enderecoCep"
                        label="CEP"
                        value={model?.enderecoCep}
                        onChange={handleChange}
                        variant="outlined"
                        InputProps={{
                          inputComponent: CepTextMaskCustom,
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => handleConsultaCep(model.enderecoCep)}
                              >
                                {!loading && <IconSearch />}
                                {loading && <CircularProgress size="small" />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                        fullWidth
                      />
                    </Grid>

                    <Grid item md={8}>
                      <TextField
                        id="enderecoLogradouro"
                        label="Logradouro"
                        value={model?.enderecoLogradouro}
                        onChange={handleChange}
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>

                    <Grid item md={4}>
                      <TextField
                        id="enderecoLogradouroNumero"
                        label="Número"
                        value={model?.enderecoLogradouroNumero}
                        onChange={handleChange}
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                    <Grid item md={6}>
                      <TextField
                        id="enderecoCidade"
                        label="Cidade"
                        value={model?.enderecoCidade}
                        variant="outlined"
                        onChange={handleChange}
                        disabled
                        fullWidth
                      />
                    </Grid>

                    <Grid item md={2}>
                      <TextField
                        id="enderecoUf"
                        label="UF"
                        value={model?.enderecoUf}
                        variant="outlined"
                        onChange={handleChange}
                        disabled
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Divider className={classes.divider} variant="fullWidth" />
                  <Grid container orientation="row" spacing={2}>
                    <Grid item md={4}>
                      <TextField
                        id="telefone"
                        label="Telefone"
                        value={model?.telefone}
                        variant="outlined"
                        onChange={handleChange}
                        InputProps={{
                          inputComponent: PhoneTextMaskCustom
                        }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item md={4}>
                      <TextField
                        id="whatsapp"
                        label="WhatsAPP"
                        value={model?.whatsapp}
                        variant="outlined"
                        onChange={handleChange}
                        InputProps={{
                          inputComponent: CellPhoneTextMaskCustom
                        }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item md={4}>
                      <TextField
                        id="email"
                        label="email"
                        value={model?.email}
                        variant="outlined"
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid item md={12}>
                    <FormControlLabel
                    control={
                      <Switch
                        checked={model?.enderecoVisivel ?? false}
                        onChange={() => {
                          setModel(data => ({ ...data, enderecoVisivel: !data.enderecoVisivel }));
                        }}
                      />
                    }
                    label="Endereço visível no APP"
                  />
                    </Grid>
                  </Grid>
                  <Divider className={classes.divider} variant="fullWidth" />
                  <h5>Configurações</h5>
                  {model?.possuiIntegracaoSIAF === true && (
                    <Grid container orientation="row" spacing={2} style={{ marginTop: 15 }}>
                      <Grid item md={3}>
                        <Tooltip title="Selecione o vendedor padrão para ser usado nos pedidos do SIAF">
                          <Autocomplete
                            id="Vendedor"
                            value={vendedores.find(x => x.id === model?.vendedorSiafId)}
                            onChange={(event, newValue) => {
                              setModel(item => { return { ...item, vendedorSiafId: newValue?.id } })
                            }}
                            options={vendedores}
                            getOptionLabel={(option) => option.nome}
                            style={{ width: '100%' }}
                            renderInput={(params) => <TextField {...params} label="Vendedor padrão" variant="outlined" />}
                          />
                        </Tooltip>
                      </Grid>
                    </Grid>
                  )}
                  <Grid container orientation="row" spacing={2} style={{ marginTop: 15 }}>
                    {/* Desabilitado até ajuste do app for concluído */}
                    {/* <Grid item>
                      <Button
                        style={{
                          marginRight: 'auto',
                          backgroundColor: model?.cor,
                          color: model?.darkContent ? 'black' : 'white'
                        }}
                        variant="contained"
                        onClick={handleOpenColor}
                      >
                        <ColorLensIcon /> &nbsp; Cor do tema do APP
                      </Button>
                    </Grid> */}
                    <Grid item>
                      <Button
                        style={{
                          marginRight: 'auto',
                          backgroundColor: model?.possuiIntegracaoSIAF === true ? '#0da177' : '#0d47a1',
                          color: 'white'
                        }}
                        variant="contained"
                        onClick={handleActivateSIAF}
                      >
                        <ImportExportIcon /> &nbsp; {model.possuiIntegracaoSIAF === true ? 'Reconfigurar integração com SIAF' : 'Configurar integração com SIAF'}
                      </Button>
                    </Grid>
                    {model?.possuiIntegracaoSIAF === true && (
                      <Grid item>
                        <Button style={{
                          marginRight: 'auto',
                          backgroundColor: '#a10d17',
                          color: 'white'
                        }} variant="contained" onClick={handleRemoveIntegracaoSIAF}>
                          <CloseIcon /> &nbsp; Remover Integração com SIAF
                  </Button>
                      </Grid>
                    )}


                    <Grid item>
                      <Button style={{ marginRight: 'auto' }} variant="contained" onClick={handleOpen}>
                        <AvTimerIcon /> &nbsp; Horários de funcionamento
                    </Button>
                    </Grid>
                  </Grid>

                  {empresaLicenciada?.administrador && (
                    <>
                      <Divider className={classes.divider} variant="fullWidth" />
                      <h5>Parametrização do e-Sitef</h5>
                      <Grid container orientation="row" spacing={2} style={{ marginTop: 15 }}>
                        <Grid item md={4}>
                          <TextField
                            id="esitefMerchantId"
                            label="Código da loja no e-SiTef"
                            value={model?.esitefMerchantId}
                            variant="outlined"
                            onChange={handleChange}
                            fullWidth
                          />
                        </Grid>
                        <Grid item md={8}>
                          <TextField
                            id="esitefMerchantKey"
                            label="Chave de autenticação da loja no e-SiTef"
                            value={model?.esitefMerchantKey}
                            variant="outlined"
                            onChange={handleChange}
                            fullWidth
                          />
                        </Grid>
                        <Grid item md={12}>
                          <FormControl variant="outlined" fullWidth>
                            <InputLabel id="grupo">Bandeiras</InputLabel>
                            <Select
                              labelId="grupo"
                              id="select-grupo"
                              label="Grupo"
                              multiple
                              value={model?.esitefCartoesBandeiraPermitidos ?? []}
                              onChange={e => handleChangeBandeiras(e)}
                              renderValue={selected =>
                                selected.map(s => bandeiras.find(bandeira => bandeira.id === s)?.descricao)?.join(', ')
                              }
                              fullWidth
                            >
                              {bandeiras.map(bandeira => (
                                <MenuItem key={bandeira.id} value={bandeira.id}>
                                  <Checkbox
                                    color="primary"
                                    checked={
                                      model?.esitefCartoesBandeiraPermitidos.indexOf(
                                        model?.esitefCartoesBandeiraPermitidos.find(b => b === bandeira.id)
                                      ) > -1
                                    }
                                  />
                                  <img src={bandeira.imagemUrl} alt="Bandeira" style={{ width: 40 }} /> &nbsp;
                                  <ListItemText>{bandeira.descricao}</ListItemText>
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </CardContent>
                <CardActions className={classes.modalActions}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={model?.atividadeSuspensa ?? false}
                        onChange={() => {
                          setModel(data => ({ ...data, atividadeSuspensa: !data.atividadeSuspensa }));
                        }}
                      />
                    }
                    label="Suspender atividade"
                  />
                  {empresaLicenciada?.administrador && (
                    <>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={model?.desativado ?? false}
                            onChange={() => {
                              if (model?.desativado !== true)
                                notification
                                  .confirm(
                                    'Desativar loja',
                                    'Tem certeza que deseja desativar a loja? Isso fará com que ninguém mais possa encontrar ela no APP.'
                                  )
                                  .then(result => {
                                    if (result.value === true) {
                                      setModel(data => ({ ...data, desativado: !data.desativado }));
                                    }
                                  });
                              else setModel(data => ({ ...data, desativado: !data.desativado }));
                            }}
                          />
                        }
                        label="Desativar loja do App"
                      />

                      <FormControlLabel
                        control={
                          <Switch
                            checked={model?.permiteInventariado ?? false}
                            onChange={() => {
                              setModel(data => ({ ...data, permiteInventariado: !data.permiteInventariado }));
                            }}
                          />
                        }
                        label="Permitir produtos inventariados"
                      />
                    </>
                  )}
                  <Button style={{ marginLeft: 'auto' }} variant="contained" color="primary" onClick={handleSubmit}>
                    Salvar
                </Button>
                </CardActions>
              </Card>
            </Container>

            <Dialog
              open={open}
              onClose={handleClose}
              scroll={'paper'}
              aria-labelledby="scroll-dialog-title"
              aria-describedby="scroll-dialog-description"
            >
              <DialogTitle id="scroll-dialog-title">Horários de funcionamento</DialogTitle>
              <DialogContent dividers={true}>
                {horarioFuncionamento?.map(horario => (
                  <>
                    <Grid container spacing={2}>
                      <Grid item md={12}>
                        <h4>
                          <FormControlLabel
                            className={classes.formControleAberto}
                            control={
                              <Checkbox
                                id="aberto"
                                name="aberto"
                                checked={horario.aberto}
                                color="primary"
                                onChange={event => {
                                  handleInputsChange(event, horario);
                                }}
                              />
                            }
                          />
                          {getTextDiaSemana(horario.diaSemana)}
                        </h4>
                      </Grid>
                      <Grid item container md={12}>
                        <Grid item md={2}></Grid>
                        <Grid item container md={10}>
                          {horario.aberto &&
                            horario.horarios.map(hora => (
                              <Grid item container md={12}>
                                <Grid item md={5}>
                                  <TextField
                                    id="horaInicio"
                                    name="horaInicio"
                                    label="Início"
                                    value={hora.horaInicio}
                                    size="small"
                                    onChange={event => {
                                      handleAutocompleteChange(event, horario, hora);
                                    }}
                                    InputProps={{
                                      inputComponent: TimeTextMaskCustom
                                    }}
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item md={5}>
                                  <TextField
                                    id="horaFim"
                                    name="horaFim"
                                    label="Fim"
                                    value={hora.horaFim}
                                    size="small"
                                    onChange={event => {
                                      handleAutocompleteChange(event, horario, hora);
                                    }}
                                    InputProps={{
                                      inputComponent: TimeTextMaskCustom
                                    }}
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item md={2}>
                                  <IconButton
                                    aria-label="delete"
                                    onClick={event => {
                                      handleRemoveHorario(horario.diaSemana, hora);
                                    }}
                                  >
                                    <IconCancel />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            ))}
                          <Grid item md={12} style={{ marginBottom: 8, marginTop: 8, cursor: 'pointer' }}>
                            {horario.aberto && (
                              <Link
                                onClick={event => {
                                  handleAddHorario(horario.diaSemana);
                                }}
                              >
                                Adicionar horário de funcionamento
                              </Link>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Divider />
                  </>
                ))}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  OK
              </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={openColor}
              onClose={handleCloseColor}
              scroll={'paper'}
              aria-labelledby="scroll-dialog-title"
              aria-describedby="scroll-dialog-description"
            >
              <DialogTitle id="scroll-dialog-title">Selecione uma cor tema para o APP</DialogTitle>
              <DialogContent dividers={true} style={{ width: 345 }}>
                {coresPrincipais.map(cor => (
                  <Tooltip title={cor.name} style={{ cursor: 'pointer' }}>
                    <Button
                      style={{
                        marginRight: 10,
                        marginTop: 10,
                        backgroundColor: cor.color,
                        color: cor?.darkContent ? 'black' : 'white'
                      }}
                      variant="contained"
                      onClick={e => handleChangeColor(cor)}
                    >
                      &nbsp;
                  </Button>
                  </Tooltip>
                ))}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseColor} color="primary">
                  Cancelar
              </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
    </>
  );
};

export default withRouter(EmpresaEdit);
