import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

const CustomSelect = props => {
  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel>{props.label}</InputLabel>
      <Select
        name={props.name}
        label={props.label}
        value={props.value}
        onChange={props.handleChange}
        renderValue={selected => {
          const option = props.options.find(tipo => tipo.id === selected);
          return option ? `${option.id} - ${option.descricao}` : '';
        }}
        inputProps={{ id: props.name }}
      >
        {(props.options || []).map(option => (
          <MenuItem label={option.descricao} key={option.id} value={option.id}>
            {option.id} - {option.descricao}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
export default CustomSelect;
