import { apiStore } from '../api';

class FreteService {
  getPortes() {
    return apiStore.get('/api/backoffice/frete/portes');
  }

  getRaios() {
    return apiStore.get('/api/backoffice/frete/raios');
  }
  saveRaios(item) {
    return apiStore.post('/api/backoffice/frete/raios', item);
  }

  deleteRaios(item) {
    return apiStore.post('/api/backoffice/frete/delete/raios', item);
  }
}
export default new FreteService();
