import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

const RouteWithLayout = ({
  auth,
  layout: Layout,
  component: Component,
  title,
  requireLogin,
  location,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={matchProps =>
        requireLogin && !auth.authenticated ? (
          <Redirect to={{ pathname: '/login', state: { from: location } }} />
        ) : (
          <Layout title={title}>
            <Component {...matchProps} />
          </Layout>
        )
      }
    />
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string,
  requireLogin: PropTypes.bool
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(RouteWithLayout);
