import { fade, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {},
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '100%'
  },
  pageTitle: {
    color: '#666'
  },
  appbar: {
    boxShadow: 'none'
  },
  toolbar: {
    backgroundColor: '#fff',
    padding: 0
  },
  title: {
    flexGrow: 1,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    }
  },
  tab: {
    borderBottom: '1px solid #ccc'
  },
  search: {
    position: 'relative',
    borderRadius: '10px',
    backgroundColor: '#F3F3F3',
    '&:hover': {
      backgroundColor: fade('#F3F3F3', 0.8)
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto'
    }
  },
  searchIcon: {
    color: '#333',
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputInput: {
    border: 'none',
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch'
      }
    }
  },
  thead: {
    backgroundColor: 'transparent'
  },
  headCell: {
    fontSize: '0.9em'
  }
}));
