import React from 'react';
import { 
  CircularProgress,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell
} from '@material-ui/core';

const Loading = ({ title }) => {
  return (
    <>
      <Table stickyHeader size="small" aria-label="">
        <TableBody>
          <TableRow>
            <TableCell colSpan={5} rowSpan="all" align="center">
              <CircularProgress />
              <Typography> {title ? title : 'Carregando...'}</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

export default Loading;
