import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    minHeight: '450px'
  },
  colorRed: {
    color: 'red !important',
  },
  colorBlack:{
    color: 'black !important',
  },
  colorGreen:{
    color: 'green !important',
  },
  colorBlue:{
    color: 'blue !important',
  },
  colorGray:{
    color: 'gray !important',
  },
  pedidoRow: {
    cursor: 'pointer'
  }
});

export const Container = styled.div`
  .MuiCardHeader-root {
    padding: 10px 16px;
    .MuiCardHeader-title {
      font-size: 0.9em;
      color: #666;
    }
    .MuiCardHeader-action {
      padding-top: 5px;
    }
  }
`;
