import React, { useEffect, useState, useRef } from 'react';
import { withRouter, useParams } from 'react-router-dom';
import { format } from 'date-fns'
import hashGenerator from 'hash-generator'
import Autocomplete from '@material-ui/lab/Autocomplete';
import DateFnsUtils from "@date-io/date-fns";
import deLocale from "date-fns/locale/pt-BR";

import empresaService from '../../../services/modules/empresa.service';
import { Storage } from '@capacitor/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from '@material-ui/pickers';

import {
  Card,
  CardHeader,
  CardContent,
  InputAdornment,
  TextField,
  Container,
  Button,
  Typography,
  AppBar,
  Tabs,
  Tab,
  DialogContent,
  DialogActions,
  Grid,
  FormControlLabel,
  Switch,
  CardActions,
  InputLabel,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Box,
  DialogTitle,
  Dialog,
  Select,
  MenuItem,
  FormControl,
  Divider
} from '@material-ui/core';
import { IconButton, Tooltip } from '@material-ui/core';

import { EditSharp as EditSharpIcon, Delete as DeleteIcon } from '@material-ui/icons';

import notification from '../../../services/notification';
import { CurrencyFormatCustom } from '../../../components';
import { useStyles } from './styles';
import Loading from '../../../components/Loading';
import cupomDescontoService from '../../../services/modules/cupom-desconto.service';
import grupoService from '../../../services/modules/produto-grupo.service';
import produtoService from '../../../services/modules/produto.service';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const CupomDescontoEdit = ({ history }) => {
  const classes = useStyles();
  let { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [value, setValue] = React.useState(0);
  const [model, setModel] = useState(null);

  const [empresas, setEmpresas] = useState([]);
  const [tipoDescontoSelecionado, setTipoDescontoSelecionado] = useState(null);
  const [tipoDescontoItemSelecionado, setTipoDescontoItemSelecionado] = useState(null);
  const [empresaSelecionada, setEmpresaSelecionada] = useState(null);
  const [empresaItemSelecionada, setEmpresaItemSelecionada] = useState(null);
  const [produtoItemSelecionado, setProdutoItemSelecionado] = useState(null);
  const [itemParaEditar, setItemParaEditar] = useState(null);
  const [empresaLicenciada, setEmpresaLicenciada] = useState(null);
  const [gruposProdutos, setGruposProdutos] = useState([]);
  const [produtos, setProdutos] = useState([]);
  const [openItem, setOpenItem] = React.useState(false);
  const [tiposDesconto] = useState([
    { id: 1, descricao: 'Valor' },
    { id: 2, descricao: 'Percentual' }
  ]);

  const labelRef = useRef()
  const labelWidth = labelRef.current ? labelRef.current.clientWidth : 0

  const handleOpenItem = async (item) => {
    setOpenItem(true);
    setItemParaEditar(item);
    setEmpresaItemSelecionada(empresaLicenciada?.administrador ? empresas?.find(x => x.id === item.empresaId) : empresaLicenciada);

    const gruposRes = await grupoService.getGruposDeEmpresa(empresaLicenciada?.administrador ? item.empresaId : empresaLicenciada.id);
    setGruposProdutos(gruposRes.data)

    const produtosRes = await produtoService.getProdutosByEmpresa(empresaLicenciada?.administrador ? item.empresaId : empresaLicenciada.id);
    setProdutos(produtosRes.data)
    setProdutoItemSelecionado(produtosRes?.data?.find(x => x.id === item.produtoId))

    setTipoDescontoItemSelecionado(tiposDesconto?.find(x => x.id === item.tipoDesconto))
  };

  const handleDeleteItem = async (item) => {
    if (item.id > 0) {
      await cupomDescontoService.deleteItem(item.id);
    }

    bootstrapAsync();
  }

  const handleSaveItem = async () => {
    if (itemParaEditar.id > 0) {
      await cupomDescontoService.updateItem({ ...itemParaEditar, valor: (itemParaEditar.valor + '').replace(',', '.') });
    }
    else {
      await cupomDescontoService.saveItem({ ...itemParaEditar, valor: (itemParaEditar.valor + '').replace(',', '.') });
    }

    handleCloseItem();
    bootstrapAsync();
  }

  const handleCloseItem = () => {
    setOpenItem(false);
    setItemParaEditar(null);
    setEmpresaItemSelecionada(null);
    setTipoDescontoItemSelecionado(null);
    setProdutoItemSelecionado(null);
    setProdutos(null);
  };

  const bootstrapAsync = async () => {
    setLoading(true);
    try {
      const { value: empresaLicenciadaStorage } = await Storage.get({
        key: 'empresaLicenciada'
      });

      setEmpresaLicenciada(JSON.parse(empresaLicenciadaStorage));

      const { data: empresasData } = await empresaService.getAll();
      setEmpresas(empresasData);


      if (id > 0) {
        const { data } = await cupomDescontoService.getCupomDesconto(id);
        setModel({ ...data });
        setTipoDescontoSelecionado(tiposDesconto?.find(x => x.id === data?.tipoDesconto));
        setEmpresaSelecionada(JSON.parse(empresaLicenciadaStorage)?.administrador === true ? empresasData?.find(item => item.id === data?.empresaId) : empresaLicenciadaStorage)
      } else {
        setModel({
          empresaId: JSON.parse(empresaLicenciadaStorage)?.id,
          codigo: '',
          descricao: '',
          tipoDesconto: 0,
          valor: 0,
          quantidade: 0,
          valorMinimoCompra: 0,
          validoAte: new Date(),
          situacao: 1,
          descricaoSituacao: 'Ativo'
        })
        setEmpresaSelecionada(JSON.parse(empresaLicenciadaStorage)?.administrador === true ? empresasData?.find(item => item.id === empresaLicenciadaStorage.id) : empresaLicenciadaStorage)
      }



      setLoading(false);

    } catch (error) {
      setLoading(false);
      notification.error(error);
    }
  };

  const handleChange = event => {
    event.persist();

    setModel(data => ({ ...data, [event.target.name]: event.target.value }));
  };

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };

  const handleGenerateCode = () => {
    const hashGenerated = hashGenerator(8)
    setModel(data => ({ ...data, codigo: hashGenerated.toUpperCase() }));

  }

  const handleSubmit = async e => {
    e.preventDefault();

    setLoading(true);

    try {
      let result = null;
      if (model.id > 0) {
        result = await cupomDescontoService.updateCupomDesconto(id, { ...model, valor: !model?.valor ? null : (model?.valor + '').replace(',', '.'), valorMinimoCompra: +((model?.valorMinimoCompra + '').replace(',', '.')) });
      } else {
        result = await cupomDescontoService.insertCupomDesconto({ ...model, valor: !model?.valor ? null : (model?.valor + '').replace(',', '.'), valorMinimoCompra: +((model?.valorMinimoCompra + '').replace(',', '.')) })
      }

      if (result.response?.status === 500) {
        notification.erroValidacao(result.response.data.message)
      } else {
        notification.success('Cupom salvo com sucesso!');
        history.push('/cupom-desconto');
      }

      setLoading(false);


    } catch (error) {
      setLoading(false);
      notification.error(error);
    }
  };

  const handleDateChange = (date) => {
    setModel(data => ({ ...data, validoAte: date.toISOString() }));
  };

  useEffect(() => {
    const bootstrapIntAsync = async () => {
      setLoading(true);
      try {
        const { value: empresaLicenciadaStorage } = await Storage.get({
          key: 'empresaLicenciada'
        });

        setEmpresaLicenciada(JSON.parse(empresaLicenciadaStorage));

        const { data: empresasData } = await empresaService.getAll();
        setEmpresas(empresasData);


        if (id > 0) {
          const { data } = await cupomDescontoService.getCupomDesconto(id);
          setModel({ ...data });
          setTipoDescontoSelecionado(tiposDesconto?.find(x => x.id === data?.tipoDesconto));
          setEmpresaSelecionada(JSON.parse(empresaLicenciadaStorage)?.administrador === true ? empresasData?.find(item => item.id === data?.empresaId) : empresaLicenciadaStorage)
        } else {
          setModel({
            empresaId: JSON.parse(empresaLicenciadaStorage)?.id,
            codigo: '',
            descricao: '',
            tipoDesconto: 0,
            valor: 0,
            quantidade: 0,
            valorMinimoCompra: 0,
            validoAte: new Date(),
            situacao: 1,
            descricaoSituacao: 'Ativo'
          })
          setEmpresaSelecionada(JSON.parse(empresaLicenciadaStorage)?.administrador === true ? empresasData?.find(item => item.id === empresaLicenciadaStorage.id) : empresaLicenciadaStorage)
        }



        setLoading(false);

      } catch (error) {
        setLoading(false);
        notification.error(error);
      }
    };
    bootstrapIntAsync();
  }, [id, tiposDesconto]);
  return (
    <Container className={classes.root} fixed>
      {loading ?
        (
          <Loading></Loading>
        )
        : (<Grid container orientation="row" spacing={2}>
          <Grid item md={12} orientation="column">
            <Grid container orientation="row" spacing={2}>
              <Grid item md={12}>
                <Card>
                  <CardHeader title="Editar Cupom de Desconto" />
                  <CardContent>
                    <Grid container orientation="row" spacing={2}>
                      <Grid item md={4}>
                        <TextField
                          id="text-codigo"
                          name="codigo"
                          label="Cupom"
                          value={model?.codigo.toUpperCase() ?? ''}
                          onChange={handleChange}
                          variant="outlined"
                          fullWidth
                          InputProps={{
                            readOnly: !(model?.id === null || model?.id === undefined || model?.id <= 0),
                          }}
                        />
                      </Grid>
                      <Grid item md={2}>
                        {(true) && (
                          <Button
                            style={{ marginLeft: 'auto', height: '50px' }}
                            variant="contained"
                            color="primary"
                            onClick={() => { handleGenerateCode() }}
                            disabled={!(model?.id === null || model?.id === undefined || model?.id <= 0)}
                          >
                            Gerar
                          </Button>
                        )}

                      </Grid>
                      <Grid item md={12}>
                        <TextField
                          id="text-descricao"
                          name="descricao"
                          label="Descrição"
                          value={model?.descricao ?? ''}
                          onChange={handleChange}
                          variant="outlined"
                          fullWidth
                          rows={3}
                        />
                      </Grid>
                      <Grid item md={12}>
                        <h4>Configurações</h4>
                      </Grid>

                      {empresaLicenciada?.administrador === true && (
                        <Grid item md={3}>
                          <Autocomplete
                            id="empresas"
                            value={empresaSelecionada}
                            onChange={(event, newValue) => {
                              setEmpresaSelecionada(newValue);
                              setModel(item => { return { ...item, empresaId: newValue?.id } })
                            }}
                            options={empresas}
                            getOptionLabel={(option) => option.razaoSocial}
                            style={{ width: 300 }}
                            renderInput={(params) => <TextField {...params} label="Empresa" variant="outlined" />}
                          />
                        </Grid>)}

                      {!(model?.itens?.length > 0) && (
                        <>
                          <Grid item md={2}>
                            <Autocomplete
                              id="Tipo"
                              value={tipoDescontoSelecionado}
                              onChange={(event, newValue) => {
                                setTipoDescontoSelecionado(newValue);
                                setModel(item => { return { ...item, tipoDesconto: newValue?.id } })
                              }}
                              options={tiposDesconto}
                              getOptionLabel={(option) => option.descricao}
                              style={{ width: '100%' }}
                              renderInput={(params) => <TextField {...params} label="Tipo de desconto" variant="outlined" />}
                            />
                          </Grid>
                          <Grid item md={2}>
                            <TextField
                              id="text-valor"
                              name="valor"
                              label="Valor do desconto"
                              value={model?.valor ?? ''}
                              onChange={handleChange}
                              variant="outlined"
                              fullWidth
                              InputProps={{
                                inputComponent: CurrencyFormatCustom,
                                startAdornment: <InputAdornment position="start">{tipoDescontoSelecionado?.id === 1 && ("R$")}</InputAdornment>,
                                endAdornment: <InputAdornment position="end">{tipoDescontoSelecionado?.id === 2 && ("%")}</InputAdornment>
                              }}
                              rows={3}
                            />
                          </Grid>
                        </>
                      )}
                      <Grid item md={2}>
                        <Tooltip title="Valor mínimo da compra para o cupom ser válido">
                          <TextField
                            id="text-valor-minimo"
                            name="valorMinimoCompra"
                            label="Valor mínimo da compra"
                            value={model?.valorMinimoCompra ?? ''}
                            onChange={handleChange}
                            variant="outlined"
                            fullWidth
                            InputProps={{
                              inputComponent: CurrencyFormatCustom,
                              startAdornment: <InputAdornment position="start">R$</InputAdornment>
                            }}
                            rows={3}
                          />
                        </Tooltip>
                      </Grid>
                      <Grid item md={2}>
                        <Tooltip title="Quantidade máxima de vezes que o cupom poderá ser usado. (Use 0 (zero) para Ilimitado)">
                          <TextField
                            id="text-quantidade"
                            name="quantidade"
                            label="Quantidade máxima"
                            value={model?.quantidade ?? ''}
                            onChange={handleChange}
                            variant="outlined"
                            fullWidth
                            rows={3}
                          />
                        </Tooltip>
                      </Grid>
                      <Grid item md={12}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
                          <Grid container>
                            <Grid item md={3}>
                              <KeyboardDateTimePicker
                                margin="normal"
                                id="date-picker-dialog"
                                inputVariant="outlined"
                                label="Valido até (data)"
                                format="dd/MM/yyyy HH:mm"
                                variant="outlined"
                                ampm={false}
                                value={model?.validoAte}
                                onChange={handleDateChange}
                                KeyboardButtonProps={{
                                  'aria-label': 'Valido até',
                                }}
                              />
                            </Grid>
                          </Grid>
                        </MuiPickersUtilsProvider>
                      </Grid>

                    </Grid>
                    <Grid container>
                      <Grid item>
                        &nbsp;
                    </Grid>
                    </Grid>
                    <Divider />
                    <Grid container md={12}>
                      <Grid item md={12}>
                        <div className={classes.root}>
                          <AppBar position="static" color="default">
                            <Tabs
                              value={value}
                              onChange={handleChangeTabs}
                              indicatorColor="primary"
                              textColor="primary"
                              variant="fullWidth"
                              aria-label="full width tabs example"
                            >
                              <Tab label="Produtos Participantes" {...a11yProps(0)} />
                              <Tab label="Histórico" {...a11yProps(1)} />
                            </Tabs>
                          </AppBar>
                          <TabPanel value={value} index={0}>
                            <Table size="small" aria-label="Today's orders">
                              <TableHead className={classes.thead}>
                                <TableRow>
                                  {empresaLicenciada?.administrador === true && (
                                    <TableCell align="center" variant="head">
                                      Empresa
                                    </TableCell>
                                  )}
                                  <TableCell align="center" variant="head">
                                    Departamento
                                  </TableCell>
                                  <TableCell align="center" variant="head">
                                    Produto
                                  </TableCell>
                                  <TableCell align="center" variant="head">
                                    Valor
                                  </TableCell>
                                  <TableCell align="center" variant="head">
                                    Tipo
                                  </TableCell>
                                  <TableCell align="center" variant="head">
                                    Quantidade máxima por compra
                                  </TableCell>
                                  <TableCell align="center" variant="head">

                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {model?.itens?.map(row => (
                                  <TableRow className={classes.pedidoRow} key={row?.id}>
                                    {empresaLicenciada?.administrador === true && (
                                      <TableCell align="center"><b>{(row?.empresaId ? row.empresa?.razaoSocial : "N/D") ?? 'N/D'}</b></TableCell>
                                    )}
                                    <TableCell align="center"><b>{(row?.produtoGrupoEmpresaId ? row?.produtoGrupoEmpresa?.produtoGrupo?.descricao : "N/D") ?? 'N/D'}</b></TableCell>
                                    <TableCell align="center">{(row?.produtoId ? row?.produto.nome : "N/D") ?? 'N/D'}</TableCell>
                                    <TableCell align="center">{row?.tipoDesconto === 1 && ("R$ ")}{(row?.valor ? (+row?.valor).toFixed(2) : "N/D") ?? 'N/D'} {row?.tipoDesconto === 2 && (" %")}</TableCell>
                                    <TableCell align="center">{(row?.tipoDesconto ? tiposDesconto?.find(x => x.id === row?.tipoDesconto).descricao : "N/D") ?? 'N/D'}</TableCell>
                                    <TableCell align="center">{(row?.quantidadeMaxima ? row?.quantidadeMaxima : "N/D") ?? 'N/D'}</TableCell>
                                    <TableCell align="right">
                                      <Grid container>
                                        <Grid item md={6}>
                                          <Tooltip title="Editar">
                                            <IconButton aria-label="Editar" onClick={() => handleOpenItem(row)}>
                                              <EditSharpIcon />
                                            </IconButton>
                                          </Tooltip>
                                        </Grid>
                                        <Grid item md={6}>
                                          <Tooltip title="Excluir">
                                            <IconButton aria-label="Excluir" onClick={() => handleDeleteItem(row)}>
                                              <DeleteIcon />
                                            </IconButton>
                                          </Tooltip>
                                        </Grid>
                                      </Grid>
                                    </TableCell>
                                  </TableRow>
                                ))}

                                {model?.itens?.length === 0 && (
                                  <TableRow style={{ height: 53 }}>
                                    <TableCell colSpan={5}>Nenhuma informação encontrada</TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                            <br />
                            {model?.id > 0 && (
                              <Button style={{ marginLeft: 'auto' }} disabled={model?.situacao === 3} variant="contained" color="primary" onClick={() => handleOpenItem({ cupomId: model?.id, produtoGrupoEmpresaId: null, produtoId: null, valor: 0, tipoDesconto: 1, empresaId: (empresaSelecionada?.id ? empresaSelecionada?.id : empresaLicenciada.id) })}>
                                Adicionar item
                              </Button>
                            )}
                          </TabPanel>
                          <TabPanel value={value} index={1}>
                            <Table size="small" aria-label="Today's orders">
                              <TableHead className={classes.thead}>
                                <TableRow>
                                  <TableCell className={classes.headCell} variant="head">
                                    Pedido
                                  </TableCell>
                                  {empresaLicenciada?.administrador === true && (
                                    <TableCell className={classes.headCell} variant="head">
                                      Empresa
                                    </TableCell>
                                  )}
                                  <TableCell className={classes.headCell} variant="head">
                                    Cliente
                                  </TableCell>
                                  <TableCell className={classes.headCell} variant="head">
                                    Valor do desconto
                                  </TableCell>
                                  <TableCell className={classes.headCell} variant="head">
                                    Data
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {model?.historico?.map(row => (
                                  <TableRow className={classes.pedidoRow} key={row.id}>
                                    <TableCell align="left"><b>{(row?.pedido ? row.pedido.numero : "N/D") ?? 'N/D'}</b></TableCell>
                                    {empresaLicenciada?.administrador === true && (
                                      <TableCell align="left"><b>{row.empresa.nomeFantasia}</b></TableCell>
                                    )}
                                    <TableCell align="left">{(row?.cliente ? row.cliente.nome : "N/D") ?? 'N/D'}</TableCell>
                                    <TableCell align="left">{(row?.valorDescontoAplicado ? 'R$ ' + row.valorDescontoAplicado.toFixed(2) : "N/D") ?? 'N/D'}</TableCell>
                                    <TableCell align="left">{format(new Date(row.data), 'dd/MM/yyyy HH:mm')}</TableCell>
                                  </TableRow>
                                ))}

                                {model?.historico?.length === 0 && (
                                  <TableRow style={{ height: 53 }}>
                                    <TableCell colSpan={5}>Nenhuma informação encontrada</TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>

                          </TabPanel>
                        </div>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardActions className={classes.modalActions}>
                    <FormControlLabel
                      control={
                        <Switch
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                          checked={model?.situacao === 1 ?? false}
                          onChange={() =>
                            setModel(data => ({
                              ...data,
                              situacao: (model?.situacao === 1 ? 2 : (model?.situacao === 2 ? 1 : 3)),
                              descricaoSituacao: (model?.situacao === 1 ? 'Inativo' : (model?.situacao === 2 ? 'Ativo' : 'Expirado'))
                            }))
                          }
                        />
                      }
                      label={model?.descricaoSituacao}
                    />

                    <Button style={{ marginLeft: 'auto' }} disabled={model?.situacao === 3} variant="contained" color="primary" onClick={handleSubmit}>
                      Salvar
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>)
      }

      <Dialog
        open={openItem}
        onClose={handleCloseItem}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Editar item de Cupom de Desconto</DialogTitle>
        <DialogContent dividers={true} style={{ width: 345 }}>
          <Grid container>
            {empresaLicenciada?.administrador === true && (
              <Grid item md={12} className={classes.Grid}>
                <Autocomplete
                  id="empresas"
                  value={empresaItemSelecionada}
                  onChange={async (event, newValue) => {
                    setEmpresaItemSelecionada(newValue);
                    setItemParaEditar(item => { return { ...item, empresaId: newValue?.id } })

                    const gruposRes = await grupoService.getGruposDeEmpresa(newValue?.id);
                    setGruposProdutos(gruposRes.data)
                    const produtosRes = await produtoService.getProdutosByEmpresa(newValue?.id);
                    setProdutos(produtosRes.data)
                  }}
                  options={empresas}
                  getOptionLabel={(option) => option.razaoSocial}
                  style={{ width: 300 }}
                  renderInput={(params) => <TextField {...params} label="Empresa" variant="outlined" />}
                />
              </Grid>
            )}

            <Grid item md={12} className={classes.Grid}>
              <FormControl style={{ width: '100%' }} variant="outlined" >
                <InputLabel ref={labelRef} id="demo-simple-select-outlined-label">Departamento</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  labelWidth={labelWidth}
                  id="produtoGrupoId"
                  name="produtoGrupoId"
                  value={itemParaEditar?.produtoGrupoEmpresaId}
                  onChange={(event) => {
                    event.persist();
                    setItemParaEditar(data => { return { ...data, produtoGrupoEmpresaId: event.target.value, produtoId: null } });
                    setProdutoItemSelecionado(null);

                  }}
                >
                  {gruposProdutos?.map(grupos => (
                    <MenuItem value={grupos?.id}>{grupos?.produtoGrupo?.descricao}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={12} className={classes.Grid}>
              <Autocomplete
                id="produtos"
                value={produtoItemSelecionado}
                onChange={async (event, newValue) => {
                  event.persist();
                  setProdutoItemSelecionado(newValue);
                  setItemParaEditar(item => { return { ...item, produtoId: newValue?.id, produtoGrupoEmpresaId: null } })


                }}
                options={produtos}
                getOptionLabel={(option) => option.nome}
                style={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label="Produto" variant="outlined" />}
              />
            </Grid>
            <Grid item md={12} className={classes.Grid}>
              <Autocomplete
                id="Tipo"
                value={tipoDescontoItemSelecionado}
                onChange={(event, newValue) => {
                  setTipoDescontoItemSelecionado(newValue);
                  setItemParaEditar(item => { return { ...item, tipoDesconto: newValue?.id } })
                }}
                options={tiposDesconto}
                getOptionLabel={(option) => option.descricao}
                style={{ width: '100%' }}
                renderInput={(params) => <TextField {...params} label="Tipo de desconto" variant="outlined" />}
              />
            </Grid>
            <Grid item md={12} className={classes.Grid}>
              <TextField
                id="text-valor"
                name="valor"
                label="Valor do desconto"
                value={itemParaEditar?.valor ?? ''}
                onChange={(event) => {
                  event.persist();
                  setItemParaEditar(item => { return { ...item, valor: event.target.value } })
                }}
                variant="outlined"
                fullWidth
                InputProps={{
                  inputComponent: CurrencyFormatCustom,
                  startAdornment: <InputAdornment position="start">{tipoDescontoItemSelecionado?.id === 1 && ("R$")}</InputAdornment>,
                  endAdornment: <InputAdornment position="end">{tipoDescontoItemSelecionado?.id === 2 && ("%")}</InputAdornment>
                }}
                rows={3}
              />
            </Grid>
            <Grid item md={12} className={classes.Grid}>
              <Tooltip title="Quantidade máxima de produtos que podem ser comprados por usuário">
                <TextField
                  id="text-quantidade"
                  name="quantidadeMaxima"
                  label="Quantidade máxima"
                  value={itemParaEditar?.quantidadeMaxima ?? ''}
                  onChange={(event) => {
                    event.persist();
                    setItemParaEditar(item => { return { ...item, quantidadeMaxima: event.target.value } })
                  }}
                  variant="outlined"
                  fullWidth
                  rows={3}
                />
              </Tooltip>
            </Grid>

          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSaveItem} color="primary">
            Salvar
          </Button>
          <Button onClick={handleCloseItem} color="primary">
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

    </Container >
  );
};

export default withRouter(CupomDescontoEdit);
