import { apiStore } from '../api';

class BannerPromocaoService {
  getBannerPromocao(filter, data) {
    return apiStore.post(`/api/backoffice/banner-promocao/${filter}`, data);
  }

  getBannerPromocaoById(id) {
    return apiStore.get(`/api/backoffice/banner-promocao/${id}`);
  }

  updateBannerPromocao(id, data) {
    return apiStore.put(`/api/backoffice/banner-promocao/${id}`, data);
  }

  insertBannerPromocao(data) {
    return apiStore.post(`/api/backoffice/banner-promocao/save`, data);
  }

  updateItem(data) {
    return apiStore.put(`/api/backoffice/banner-promocao-item`, data);
  }

  deleteItem(itemId) {
    return apiStore.delete(`/api/backoffice/banner-promocao-item/${itemId}`);
  }

  saveItem(data) {
    return apiStore.post(`/api/backoffice/banner-promocao-item`, data);
  }

  addLogo(data) {
    return apiStore.post('/api/backoffice/banner-promocao/upload/imagem', data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
  }

  sendNotification(data) {
    return apiStore.post(`/api/backoffice/banner-promocao/notificacao/all`, data);
  }
}

export default new BannerPromocaoService();
