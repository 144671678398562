import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: 15
  },
  alert: {
    marginBottom: theme.spacing(3)
  },
  media: {
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  formGroup: {
    marginBottom: theme.spacing(3)
  },
  fieldGroup: {
    display: 'flex',
    alignItems: 'center'
  },
  fieldHint: {
    margin: theme.spacing(1, 0)
  },
  tags: {
    marginTop: theme.spacing(1),
    '& > * + *': {
      marginLeft: theme.spacing(1)
    }
  },
  flexGrow: {
    flexGrow: 1
  },
  dateField: {
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  },
  modalActions: {
    borderTop: '1px solid #E4E7EB',
    padding: '15px',
    display: 'flex',
    buttonRight: {
      marginLeft: 'auto'
    },
    buttonLeft: {
      marginRight: 'auto'
    }
  },
  Grid: {
    paddingBottom: 6
  },
  logoContainer: {
    position: 'relative',
    padding: '0'
  },
  logoImage: {
    display: 'block',
    height: '100%',
    width: '100%'
  },
  logoOverlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    height: '100%',
    opacity: 0,
    transition: '.3s ease',
    background: '#333',
    '&:hover': {
      opacity: 0.8
    }
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },  
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  logoInputFile: {
    display: 'none'
    
  }
}));
