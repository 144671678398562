import React, { useEffect, useState } from 'react';
import PedidoService from '../../../../services/modules/pedido.service'
import { Loading } from '../../../../components';
import notification from '../../../../services/notification';
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Typography,
  Divider
} from '@material-ui/core';

import { Container, useStyles } from './styles';

const CardTotalizadores = props => {
  const [loading, setLoading] = useState(false);
  const [countNovoPedidos, setCountNovoPedidos] = useState(0);
  const [countProntoEnvio, setCountProntoEnvio] = useState(0);
  const [countConcluido, setCountConcluido] = useState(0);
  const classes = useStyles();

  const loadData = async () => {
    const { data: pedidos } = await PedidoService.getPorData(new Date(),`?page=1&limit=10`);

    setCountNovoPedidos(pedidos?.rows?.filter(item => item.situacao === 1 || item.situacao === 2).length);
    setCountProntoEnvio(pedidos?.rows?.filter(item => item.situacao === 6).length);
    setCountConcluido(pedidos?.rows?.filter(item => item.situacao === 8).length);

  }

  useEffect(() => {
    const bootstrapAsync = async () => {
      setLoading(true);
      try {
        await loadData();
        setLoading(false);
      } catch (error) {
        setLoading(false);
        notification.error(error);
      }
    };
    bootstrapAsync();
  }, []);
  return (
    <Container>
      {loading ? (
        <Loading />
      ) :
        (
          <Card className={classes.root}>
            <CardHeader title="Pedidos hoje" />
            <CardContent>
              <Grid container alignItems="center" justify="center">
                <Grid item md={4} xs={4}>
                  <Typography variant="h1" component="h1">
                    {countNovoPedidos}
                  </Typography>
                  <Typography variant="body2">Novos</Typography>
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid item md={3} xs={3}>
                  <Typography variant="h1" component="h1">
                    {countProntoEnvio}
              </Typography>
                  <Typography variant="body2">Prontos para envio</Typography>
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid item md={4} xs={4}>
                  <Typography variant="h1" component="h1">
                    {countConcluido}
              </Typography>
                  <Typography variant="body2">Concluídos</Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        )
      }
    </Container>
  );
};

export default CardTotalizadores;
