import firebase from 'firebase';
import notification from '../../services/notification';
import usuarioService from '../../services/modules/usuario.service';
export const inicializarFirebase = async () => {
  var firebaseConfig = {
    apiKey: "AIzaSyB0YTya7BKu1g7xOnEqO5g_Ym9cT0eyG8c",
    authDomain: "siaf-store.firebaseapp.com",
    databaseURL: "https://siaf-store.firebaseio.com",
    projectId: "siaf-store",
    storageBucket: "siaf-store.appspot.com",
    messagingSenderId: "270982248402",
    appId: "1:270982248402:web:80c5f01e82623399e37a0b",
    measurementId: "G-J67MXMTD03"
  };
  // Initialize Firebase
  await firebase.initializeApp(firebaseConfig);
  await firebase.analytics();

  if ("serviceWorker" in navigator) {
    navigator.serviceWorker
      .register("./firebase-messaging-sw.js")
      .then(function (registration) {
        console.log("Registration successful, scope is:", registration.scope);
      })
      .catch(function (err) {
        console.log("Service worker registration failed, error:", err);
      });
  }
}

export const pedirPermissaoParaReceberNotificacoes = async (autenticated) => {
  try {
    const messaging = await firebase.messaging();
    const result = await Notification.requestPermission();
    let token = result;
    if (result === 'granted'){
      token = await messaging.getToken();
    }

    messaging.onMessage((payload) => {
      if (payload?.notification) {
        //bell.play();
        notification.pushNotification(payload.notification.title, payload.notification.body);
      }
    })

    if (autenticated === true && result === 'granted') {
      await usuarioService.updateFirebaseToken({ firebaseToken: token });
    }
    
    return token;
  } catch (error) {
    console.error(error);
  }
}

