import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Divider,
  Checkbox,
  Chip,
  CircularProgress,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import dataURLtoFile from '../../../utils/dataURLtoFile';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { ImageCropper, ImageView } from '../../../components';
import { ModalCustom, ModalActions, ModalContainer, ModalWrapper } from '../../../styles/shared';
import produtoGrupoService from '../../../services/modules/produto-grupo.service';
import empresaCategoriaService from '../../../services/modules/empresa-categoria.service';
import notification from '../../../services/notification';

const DepartamentoEdit = ({ data, show, onHide }) => {
  const labelRefPorte = useRef()
  const labelWidthPorte = labelRefPorte.current ? labelRefPorte.current.clientWidth : 0

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const [loading, setLoading] = useState(false);
  const [model, setModel] = useState(null);
  const [isCropping, setIsCropping] = useState(false);
  const [tempImage, setTempImage] = useState(null);
  const [categoriasAtivas, setCategoriasAtivas] = useState([]);
  const [image, setImage] = useState({
    originalname: '',
    fileName: data.imagemUri,
    fileData: null
  });

  const handleChangeImage = event => {
    const file = event.target.files[0];

    const reader = new FileReader();
    reader.onload = function () {
      setTempImage(reader.result);
      setIsCropping(true);
      setImage(data => ({ ...data, originalname: file.name }));
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleChange = (event) => {
    event.persist();
    setModel(data => ({
      ...data,
      [event.target.name]: event.target.value
    }))
  }

  const handleRemoveImage = () => {
    setImage({
      fileName: '',
      fileData: null
    });
  };

  const handleSubmit = async e => {
    //e.preventDefault();
    setLoading(true);

    let fileName = model?.imagem;
    if (image.fileData) {
      let formData = new FormData();
      formData.append('image', image.fileData);

      const { data } = await produtoGrupoService.addImagem(formData);
      fileName = data.fileName;
    }

    try {
      if (model?.id > 0)
        await produtoGrupoService.updateGrupoGenerico(model?.id, { ...model, imagem: fileName });
      else
        await produtoGrupoService.saveGrupoGenerico({ ...model, imagem: fileName });

      setLoading(false);
      notification.success('Departamento salvo com sucesso!');
      onHide();
    } catch (error) {
      setLoading(false);
      notification.error(error);
    }
  };

  useEffect(() => {
    empresaCategoriaService.getTodasCategoriasAtivas().then(x => {
      setCategoriasAtivas(x.data.map(y => ({ produtoGrupoId: data?.id, empresaCategoriaId: y.id, empresaCategoria: y })));
    })
    setModel({ ...data });

  }, [data]);

  if (!show) return;

  return (
    <ModalCustom open aria-labelledby="server-modal-title" aria-describedby="server-modal-description">
      <ModalContainer style={{ width: 520 }}>
        <ModalWrapper>
          <Grid container orientation="column" spacing={2}>
            <Grid item orientation="column" style={{ width: 520 }}>
              <ImageView
                image={image?.fileName}
                imageSize="large"
                handleChangeImage={handleChangeImage}
                handleRemoveImage={handleRemoveImage}
                rounded={true}
                showActions={true}
              />
              {isCropping && (
                <ImageCropper
                  image={tempImage}
                  open={isCropping}
                  onSave={croppedFile => {
                    setIsCropping(false);
                    setTempImage(false);

                    const file = dataURLtoFile(croppedFile, image.originalname);
                    setImage(data => ({
                      ...data,
                      fileName: croppedFile,
                      fileData: file
                    }));
                  }}
                  onHide={() => {
                    setIsCropping(false);
                    setTempImage(false);
                  }}
                />
              )}
            </Grid>
            <Grid item orientation="column">
              <Grid container orientation="row" spacing={2}>
                <Grid item md={12}>
                  <TextField
                    id="text-descricao"
                    name="descricao"
                    label="Descrição"
                    variant="outlined"
                    fullWidth
                    value={model?.descricao}
                    onChange={e => { handleChange(e) }}
                  />
                </Grid>
                <Grid item md={12}>
                  <Autocomplete
                    multiple
                    id="fixed-tags-demo"
                    value={model?.produtoGrupoCategoriaEmpresa}
                    onChange={(event, newValue) => {
                      const duplicated = newValue.filter(item => newValue.filter(item2 => item.empresaCategoriaId === item2.empresaCategoriaId).length >= 2);

                      let newValueRemovedDuplicates = [];
                      if (duplicated.length > 0)
                        newValueRemovedDuplicates = newValue.filter(item => duplicated.filter(item2 => item.empresaCategoriaId !== item2.empresaCategoriaId).length > 0);
                      else
                        newValueRemovedDuplicates = newValue;

                      setModel(model => ({ ...model, produtoGrupoCategoriaEmpresa: [...newValueRemovedDuplicates] }))
                    }}
                    options={categoriasAtivas}
                    getOptionLabel={(option) => option.empresaCategoria.descricao}
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip
                          label={option.empresaCategoria.descricao}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    style={{ width: 500 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Categorias de empresas" variant="outlined" placeholder="Categorias" />
                    )}
                    renderOption={(option, item) => (
                      <React.Fragment>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={model?.produtoGrupoCategoriaEmpresa?.find(item => item.empresaCategoriaId === option.empresaCategoriaId && item.produtoGrupoId === option.produtoGrupoId)}
                        />
                        {option.empresaCategoria.descricao}
                      </React.Fragment>
                    )}
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        checked={model?.desativado ?? false}
                        onChange={() =>
                          setModel(data => ({
                            ...data,
                            desativado: !model?.desativado ?? false
                          }))
                        }
                      />
                    }
                    label="Desativado"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ModalWrapper>
        <Divider />
        <ModalActions>
          <Button color="primary" onClick={onHide}>
            Cancelar
          </Button>
          <Button disabled={loading} color="primary" variant="contained" onClick={() => handleSubmit()}>
            {loading ? (
              <>
                <CircularProgress size={24} /> 'Salvando...'
              </>
            ) : (
                'Salvar'
              )}
          </Button>
        </ModalActions>
      </ModalContainer>
    </ModalCustom>
  );
};

export default DepartamentoEdit;
