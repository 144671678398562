import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  TableRow,
  Checkbox,
  Tooltip,
  TextField,
  InputAdornment,
  TableCell,
  FormControlLabel,
  Switch,
  FormControl,
  InputLabel,
  IconButton,
  Select,
  MenuItem
} from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';
import { CurrencyFormatCustom, FilesDropzone, ImageView, DecimalThreePrecision } from '../../../../components';
import * as ProductActions from '../../../../store/modules/products/actions';

const TableItem = ({
  changeSelectionOne,
  removerProduct,
  changeFracionamento,
  changePermiteFracionamento,
  row,
  visibleCheckbox = true,
  allowDelete = false,
  gruposGenericos,
  changeGroupProduct
}) => {
  return (
    <TableRow>
      {visibleCheckbox && (
        <TableCell padding="checkbox">
          <Checkbox checked={row.checked} color="primary" onChange={event => changeSelectionOne(row)} />
        </TableCell>
      )}
      <TableCell>{row.id.toString().padStart(5, '0')}</TableCell>
      <TableCell>{row.descricao}</TableCell>
      {visibleCheckbox === true && (<TableCell>{row.grade !== true ?
        row.saldoEstoque :
        (row.produtoGrade.length > 1 ? (row.produtoGrade.reduce((a, b) => a.saldoEstoque + b.saldoEstoque, 0)) : row.produtoGrade[0].saldoEstoque)}</TableCell>)}
      {!visibleCheckbox && (
        <>
          <TableCell style={{ width: '25%' }}>
            <FormControl style={{ width: '100%' }} variant="outlined">
              <InputLabel id="demo-simple-select-label">&nbsp;</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="produtoGrupoId"
                name="produtoGrupoId"
                value={row.grupo.grupoProdutoGenericoId ? row.grupo.grupoProdutoGenericoId : 0}
                onChange={event => {
                  changeGroupProduct(row, event.target.value);
                }}
              >
                {gruposGenericos.map(grupo => (
                  <MenuItem value={grupo?.id}>{grupo?.descricao}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </TableCell>
          <TableCell align="right">
            <Tooltip title="Selecione se o produto é permite fracionado ou não e quantidade mínima de compra.">
              <TextField
                id="text-passo-fracionado"
                name="passoFracionado"
                disabled={row.permiteFracionar !== true}
                label="Fracionamento"
                variant="outlined"
                fullWidth
                InputProps={{
                  inputComponent: DecimalThreePrecision,
                  endAdornment: <InputAdornment position="end">{row?.unidade}</InputAdornment>,
                  startAdornment: <InputAdornment position="start">
                    <Switch
                      color="primary"
                      checked={row?.permiteFracionar ?? false}
                      onChange={event => {
                        changePermiteFracionamento(row, !row?.permiteFracionar);
                      }}
                    />
                  </InputAdornment>
                }}
                value={row?.passoFracionado ?? ''}
                onChange={event => {
                  changeFracionamento(row, event.target.value);
                }}
              />
            </Tooltip>
          </TableCell>
        </>
      )}
      <TableCell align="right">{row.precoVendaFormatado}</TableCell>
      {allowDelete && (
        <TableCell align="center">
          <IconButton aria-label="delete" size="small" onClick={() => removerProduct(row.id)}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        </TableCell>
      )}
    </TableRow>
  );
};

const mapStateToProps = state => ({
  ...state.products
});

const mapDispatchToProps = dispatch => bindActionCreators(ProductActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TableItem);
