import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Table, TableContainer, TableHead, TableRow, TableCell, Tooltip, Chip, TableBody } from '@material-ui/core';
import ProdutoGrupoService from '../../../../services/modules/produto-grupo.service';
import notification from '../../../../services/notification';
import * as ProductActions from '../../../../store/modules/products/actions';
import TableItem from '../TableItem';

const TableCarinhoExportacao = ({ products, changeAllGroupProduct, changeAllFracionamento }) => {
  const [gruposGenericos, setGruposGenericos] = useState([]);

  useEffect(() => {
    const bootstrapAsync = async () => {
      const { data: gruposResponse } = await ProdutoGrupoService.getGruposGenericos();

      setGruposGenericos(gruposResponse);
    };
    bootstrapAsync();
  }, []);

  const handleAlterarGrupoEmLote = async () => {
    const gruposInput = { Departamentos: {} };
    gruposGenericos.forEach(grupo => {
      gruposInput.Departamentos = { ...gruposInput.Departamentos, [grupo.id]: grupo.descricao };
    });
    console.log(gruposInput);
    const { value: grupoProdutoId } = await notification.showSelectAlert(
      'Selecione uma nova opção de departamento para os produtos',
      'Alterar',
      gruposInput
    );

    if (grupoProdutoId && Number(grupoProdutoId)) {
      changeAllGroupProduct(grupoProdutoId);
    } else if (grupoProdutoId !== undefined) {
      notification.erroValidacao('A opção inserida não é valida, tente novamente.');
    }
  };

  const handleAlterarFracionamentoEmLote = async () => {
    const { value: fracionamento } = await notification.showInputAlert(
      'Digite um passo para o fracionamento a ser utilizado no APP. (0 (zero) para não fracionado)',
      'Alterar'
    );

    const fracionamentoNumber = typeof fracionamento === 'string'
          ? Number(fracionamento.replace(/[^0-9.-]+/g, '.'))
          : fracionamento

    if (fracionamentoNumber !== undefined && fracionamentoNumber !== null) {
      changeAllFracionamento(fracionamentoNumber);
    } else if (fracionamentoNumber !== undefined) {
      notification.erroValidacao('A opção inserida não é valida, tente novamente.');
    }
  };

  return (
    <TableContainer style={{ marginTop: '15px' }}>
      <Table stickyHeader size="small" aria-label="Today's orders">
        <TableHead>
          <TableRow>
            <TableCell variant="head">Cod.</TableCell>
            <TableCell variant="head">Produto</TableCell>
            <TableCell variant="head">
              Departamento &nbsp;
              <Tooltip title="Alterar departamentos em lote">
                <Chip
                  size="small"
                  onClick={() => {
                    handleAlterarGrupoEmLote();
                  }}
                  style={{ cursor: 'pointer' }}
                  color="primary"
                  label="G"
                />
              </Tooltip>
            </TableCell>
            <TableCell variant="head">
              Fracionamento &nbsp;
              <Tooltip title="Alterar fracionamento em lote">
                <Chip
                  size="small"
                  onClick={() => {
                    handleAlterarFracionamentoEmLote();
                  }}
                  style={{ cursor: 'pointer' }}
                  color="primary"
                  label="F"
                />
              </Tooltip>
            </TableCell>
            <TableCell variant="head" align="right">
              Preço de venda
            </TableCell>
            <TableCell variant="head" align="center" />
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map((product, index) => (
            <TableItem
              key={index}
              row={product}
              visibleCheckbox={false}
              allowDelete
              gruposGenericos={gruposGenericos}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const mapStateToProps = state => ({
  ...state.products
});
const mapDispatchToProps = dispatch => bindActionCreators(ProductActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TableCarinhoExportacao);
