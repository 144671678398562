import axios from 'axios';
import jwt from 'jsonwebtoken';
import _ from 'lodash';
import { Plugins } from '@capacitor/core';
import configStore from '../config/store';

const { Storage } = Plugins;

const apiStore = axios.create();
apiStore.interceptors.request.use(
  async config => {
    // config.baseURL = 'https://siaf-store-test-api.azurewebsites.net/';
    config.baseURL = configStore.baseUrl;
    // const { value: baseApiUrl } = await Storage.get({
    //   key: 'baseApiUrl'
    // });

    // if (!baseApiUrl) config.baseURL = baseApiUrl;

    const { value: token } = await Storage.get({
      key: 'usuarioToken'
    });

    if (token) {
      config.headers = _.merge(config.headers, {
        Authorization: `Bearer ${token}`
      });
    }

    return config;
  },
  error => Promise.reject(error)
);

apiStore.interceptors.response.use(response => {
  return response;
},
async error => {
  //const dispatch = useDispatch();   

  if (error.response.status === 401) {

    await Storage.clear()

    window.location.reload();
    //teset
  }
  return error;
});

const apiSiaf = axios.create();

apiSiaf.interceptors.request.use(
  async config => {
    const getBaseUrl = async () => {
      const { value: servidor } = await Storage.get({
        key: 'servidorSiaf'
      });

      return `http://${servidor}`;
    };
    config.baseURL = await getBaseUrl();

    const { value: token } = await Storage.get({
      key: 'siafToken'
    });
    if (token) {
      config.headers = _.merge(config.headers, {
        Authorization: `Bearer ${token}`
      });
    }

    return config;
  },
  error => Promise.reject(error)
);

const generateToken = ({ cpfCnpj, numeroSerie }) => {
  try {
    return jwt.sign({ cpfCnpj, numeroSerie }, configStore.adsoftKey);
  } catch (error) {
    throw new Error(error.message);
  }
};

export { apiStore, apiSiaf, generateToken };
