import React, { useState, useEffect, createRef } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { Card, CardContent, CardActions } from '@material-ui/core';
import { TableContainer, Table, TableHead, TableCell, TableRow, TableBody } from '@material-ui/core';
import { Container, CircularProgress, Button, Typography, Chip, IconButton, Tooltip } from '@material-ui/core';
import {
  AddOutlined as IconAddOutlined,
  EditSharp as IconEditSharp,
  LocalShipping as IconLocalShipping,
  Payment as IconPayment
} from '@material-ui/icons';

import notification from '../../../services/notification';
import meioPagamentoService from '../../../services/modules/meio-pagamento.service';

import { InputSearch } from '../../../components';
import { ContentedButtons } from '../../../styles/shared';

const MeioPagamentoList = ({ history }) => {
  const refInputSearch = createRef();
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState({
    initialData: [],
    data: []
  });

  const filterList = event => {
    const query = event.target.value;
    if (query.trim() !== '') {
      setDataSource(data => {
        const results = data.initialData.filter(function (item) {
          return item?.descricao.toLowerCase().search(query.toLowerCase()) !== -1;
        });

        return {
          ...data,
          data: [...results]
        };
      });
    } else {
      setDataSource(data => ({
        ...data,
        data: [...data.initialData]
      }));
    }
  };

  const loadData = async source => {
    try {
      setLoading(true);
      const options = {
        cancelToken: source.token
      };

      const { data } = await meioPagamentoService.getMeioPagamentos(options);
      if (data)
        setDataSource({
          initialData: [...data],
          data: [...data]
        });
      else
        setDataSource({
          initialData: [],
          data: []
        });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      notification.error(error);
    }
  };

  useEffect(() => {
    let source = axios.CancelToken.source();

    loadData(source);
    return () => {
      source.cancel();
    };
  }, []);

  return (
    <Container fixed>
      <Card>
        <CardActions style={{ justifyContent: 'space-between' }}>
          <InputSearch inputRef={refInputSearch} onChange={filterList} />

          <ContentedButtons>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => history.push('/meio-pagamento/edit/0')}
            >
              <IconAddOutlined /> Adicionar
            </Button>
          </ContentedButtons>
        </CardActions>
        <CardContent style={{ padding: 0 }}>
          <TableContainer>
            <Table stickyHeader size="small" aria-label="">
              <TableHead>
                <TableRow>
                  <TableCell variant="head">Descrição</TableCell>
                  <TableCell variant="head" align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={5} rowSpan="all" align="center">
                      <CircularProgress />
                      <p>Carregando...</p>
                    </TableCell>
                  </TableRow>
                ) : (
                    <>
                      {dataSource.data.map(row => (
                        <TableRow key={row.id}>
                          <TableCell>
                            <Typography>{row?.descricao}</Typography>
                            <Chip
                              size="small"
                              color={row?.desativado ? 'secondary' : 'primary'}
                              label={row?.desativado ? 'Desativado' : 'Ativo'}
                            />
                            <Chip
                              style={{ marginLeft: 10 }}
                              size="small"
                              label={row?.tipoPagamento === 1 ? 'Pagamento na entrega' : 'Pagamento integrado'}
                              avatar={row?.tipoPagamento === 1 ? <IconLocalShipping /> : <IconPayment />}
                            />
                          </TableCell>
                          <TableCell align="right">
                            {/* <Tooltip title="Excluir">
                            <IconButton
                              aria-label="Excluir"
                              onClick={() => {
                                handleDelete(row)
                              }}
                            >
                              <ClearIcon />
                            </IconButton>
                          </Tooltip> */}
                            <Tooltip title="Editar">
                              <IconButton
                                aria-label="Editar"
                                onClick={() => {
                                  history.push(`/meio-pagamento/edit/${row.id}`);
                                }}
                              >
                                <IconEditSharp />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  )}

                {dataSource.data.length === 0 && !loading && (
                  <TableRow>
                    <TableCell colSpan={5}>Nenhuma informação encontrada</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </Container>
  );
};

export default withRouter(MeioPagamentoList);
