import { apiStore } from '../api';

class ParametroService {
  getParametro = () => {
    return apiStore.get('/api/backoffice/parametro');
  };
  saveParametro = data => {
    return apiStore.post('/api/backoffice/parametro', data);
  };
}

export default new ParametroService();
