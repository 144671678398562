import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import FreteService from '../../../services/modules/frete.service'
import notification from '../../../services/notification';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { CurrencyFormatCustom } from '../../../components';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import parametroService from '../../../services/modules/parametro.service';
import {
  Card,
  CardContent,
  CardActions,
  CardHeader,
  Typography,
  Button,
  Tooltip,
  Divider,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  TextField,
  InputAdornment,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress
} from '@material-ui/core';
import { ImageView, Loading } from '../../../components';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import {
  useStyles
} from './styles'
import freteService from '../../../services/modules/frete.service';
import { Storage } from '@capacitor/core';

const Frete = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [loadingButtonSave, setLoadingButtonSave] = useState(false);
  const [loadingButtonDelete, setloadingButtonDelete] = useState(false);
  const [portes, setPortes] = useState(null);
  const [raios, setRaios] = useState(null);
  const [cobraFrete, setCobraFrete] = useState(true)

  const handleInputsChange = (event, itemLista) => {
    event.persist();
    setRaios(data => {

      const listInputs = data.map(input => {
        if (input.id === itemLista.id) {
          return { ...input, [event.target.name]: event.target.value };
        } else {
          return { ...input }
        }
      });

      return [...listInputs];
    });
  }

  const addItem = async () => {
    const { value: empresaLicenciada } = await Storage.get({
      key: 'empresaLicenciada'
    });

    setRaios(state => {
      const list = state
      list.push({
        nome: '',
        empresaId: empresaLicenciada.empresaId,
        distanciaInicio: 0,
        distanciaFim: 0,
        configuracaoFreteItem: []
      })
      console.log(JSON.stringify(list))
      return [...list];
    });
  }

  const handleInputConfigFreteRaioChange = (event, itemLista, porte) => {
    event.persist();
    setRaios(data => {

      const listInputs = data.map(input => {
        if (input.id === itemLista.id) {
          var element = input.configuracaoFreteItem.find(x => x.tipoPorteFreteId === porte.id)
          var toChange = input.configuracaoFreteItem.indexOf(element)

          if (toChange !== -1)
            input.configuracaoFreteItem[toChange] = { ...input.configuracaoFreteItem[toChange], [event.target.name]: (event.target.value) }
          else {
            input.configuracaoFreteItem.push({
              tipoPorteFreteId: porte.id,
              configuracaoFreteId: input.id,
              [event.target.name]: (event.target.value)
            })
          }

          return { ...input };
        } else {
          return { ...input };
        }
      });

      return [...listInputs];
    });
  }

  const handleCheckboxConfigFreteRaioChange = (event, itemLista, porte) => {
    event.persist();
    setRaios(data => {

      const listInputs = data.map(input => {
        const index = data.indexOf(itemLista);
        if (index !== -1) {
          var element = input.configuracaoFreteItem.find(x => x.tipoPorteFreteId === porte.id)
          var toChange = input.configuracaoFreteItem.indexOf(element)
          if (toChange !== -1)
            input.configuracaoFreteItem[toChange] = { ...input.configuracaoFreteItem[toChange], [event.target.name]: (event.target.checked) }
          else {
            input.configuracaoFreteItem.push({
              tipoPorteFreteId: porte.id,
              configuracaoFreteId: input.id,
              [event.target.name]: (event.target.checked)
            })
          }
          return { ...input };
        } else {
          return { ...input };
        }
      });

      return [...listInputs];
    });
  }

  const handleSave = async (item) => {
    setLoadingButtonSave(true);

    item.valorUnico = typeof item.valorUnico === 'string' ? Number(item.valorUnico.replace(/[^0-9.-]+/g, '') / 100) : item.valorUnico
    item.valorUnicoPrazoEntrega = typeof item.valorUnicoPrazoEntrega === 'string' ? Number(item.valorUnicoPrazoEntrega.replace(/[^0-9.-]+/g, '')) : item.valorUnicoPrazoEntrega
    item.valorUnicoValorMinimoCompraGratuidade = typeof item.valorUnicoValorMinimoCompraGratuidade === 'string' ? Number(item.valorUnicoValorMinimoCompraGratuidade.replace(/[^0-9.-]+/g, '') / 100) : item.valorUnicoValorMinimoCompraGratuidade

    if (item.configuracaoFreteItem)
      item.configuracaoFreteItem = item.configuracaoFreteItem.map(config => {
        return {
          ...config,
          valorKm: typeof config.valorKm === 'string' ? Number(config.valorKm.replace(/[^0-9.-]+/g, '') / 100) : config.valorKm,
          valorMinimoCompraGratuidade: typeof config.valorMinimoCompraGratuidade === 'string' ? Number(config.valorMinimoCompraGratuidade.replace(/[^0-9.-]+/g, '') / 100) : config.valorMinimoCompraGratuidade
        }
      });

    (await freteService.saveRaios([item]))
    const raios = await FreteService.getRaios();
    setRaios(raios.data);

    setLoadingButtonSave(false);
    notification.success("Frete salvo com sucesso")
  }

  const handleDelete = async (item) => {
    let confirmDelete = await notification.confirm(`Excluir "${item.nome}"?`, `A exclusão será irreversível.`)
    if (!confirmDelete?.value) {
      return;
    }
    if (item.id > 0) {
      setloadingButtonDelete(true);
      var raios = (await freteService.deleteRaios([item])).data;
      var raioParaDeletar = raios.find(x => x.id === item.id);

      raios.splice(raios.indexOf(raioParaDeletar), 1);

      setRaios(raios);
      setloadingButtonDelete(false);
    } else {
      setloadingButtonDelete(true);
      const raios = await FreteService.getRaios();
      setRaios(raios.data);
      setloadingButtonDelete(false);
    }
  }

  const getPorteValue = (item, porte) => {
    return item.configuracaoFreteItem?.find(x => x.tipoPorteFreteId === porte.id)
  }

  useEffect(() => {
    const bootstrapAsync = async () => {
      setLoading(true);
      try {
        const portes = await FreteService.getPortes();
        const raios = await FreteService.getRaios();

        const respParametro = await parametroService.getParametro();
        setCobraFrete(respParametro?.data?.cobrarTaxaEntrega === true);

        setPortes(portes.data);
        setRaios(raios.data);

        setLoading(false);
      } catch (error) {
        setLoading(false);
        notification.error(error);
      }
    };
    bootstrapAsync();
  }, []);


  return (
    <Container fixed>
      <CssBaseline />
      {(cobraFrete !== true) && (
        <Grid item md={12} xs={12}>
          <div
            style={{
              color: 'white',
              backgroundColor: '#e03f3f',
              padding: '8px 16px',
              borderRadius: 4
            }}
          >
            <p>
              <ReportProblemIcon style={{ marginBottom: '-7px' }} />
              A cobrança de frete está desabilitada, <a href="/#/parametros" style={{ color: 'white' }}>clique aqui</a> para para ativá-lo nas configurações.
            </p>
          </div>
        </Grid>
      )}
      {(cobraFrete === true) && (
        <>
          <Typography variant="h1">Frete</Typography>
          <Typography variant="h5" className={classes.faixaFreteH5}>Faixas de frete</Typography>
          <Grid className={classes.dividerTitle} container spacing={2} direction="row">

            {loading ? (
              <Loading />
            ) :
              (
                <>
                  {raios?.map(item => (
                    <Grid item md={12}>
                      <Card>
                        <CardHeader title={item.nome} />
                        <CardContent>
                          <Grid container md={12}>
                            <Grid item md={3} className={classes.gridExterno}>
                              <Typography variant="h5" className={classes.faixaFreteH5}>Nome da faixa</Typography>
                              <TextField id="standard-basic" fullWidth label="Nome da faixa" value={item.nome}
                                onChange={(event) => handleInputsChange(event, item)} name="nome" />
                            </Grid>
                            <Divider orientation="vertical" flexItem style={{ margin: 10 }} />

                            <Grid item md={2} className={classes.gridExterno}>
                              <Typography variant="h5" className={classes.faixaFreteH5}>Tipo</Typography>
                              <FormControl className={classes.formControl} style={{ width: '100%' }}>
                                <InputLabel id="demo-simple-select-label" style={{ width: '100%' }}>Tipo</InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="tipo"
                                  name="tipo"
                                  value={item.tipo}
                                  onChange={(event) => handleInputsChange(event, item)}
                                >
                                  <MenuItem value={1}>Fixo</MenuItem>
                                  <MenuItem value={2}>Porte</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Divider orientation="vertical" flexItem style={{ margin: 10 }} />
                            <Tooltip title="use 0 (zero) em ambos os campos para distância ilimitada">
                              <Grid item md={4} className={classes.gridExterno}>
                                <Typography variant="h5" className={classes.faixaFreteH5}>Cumprimento da faixa</Typography>
                                <Grid container md={12}>
                                  <Grid item md={5}>
                                    <TextField
                                      label="Início da faixa (metros)"
                                      type="number"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      InputProps={{
                                        endAdornment: <InputAdornment position="start">m</InputAdornment>,
                                      }}
                                      onChange={(event) => handleInputsChange(event, item)} name="distanciaInicio" value={item.distanciaInicio}
                                    />
                                  </Grid>
                                  <Grid item md={5}>
                                    <TextField
                                      label="Fim da faixa (metros)"
                                      type="number"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      InputProps={{
                                        endAdornment: <InputAdornment position="start">m</InputAdornment>,
                                      }}
                                      onChange={(event) => handleInputsChange(event, item)} name="distanciaFim" value={item.distanciaFim}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Tooltip>
                            <Grid item md={12} className={classes.gridExternoExpansionPanel}>
                              {item.empresaId && (
                                <>
                                  {item?.tipo === 2 && (
                                    <ExpansionPanel>
                                      <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                      >
                                        <Typography className={classes.heading}><b>Configurações de porte desta faixa</b></Typography>
                                      </ExpansionPanelSummary >
                                      <ExpansionPanelDetails>
                                        <Grid item md={12} className={classes.gridExterno}>
                                          <Typography variant="h5" className={classes.faixaFreteH5}></Typography>
                                          <Grid container md={12}>
                                            {portes?.map(porte => (
                                              <>
                                                <Grid item md={3} className={classes.gridTamanhoBox}>
                                                  <Grid container md={12}>
                                                    <Grid item md={3} alignContent="center">
                                                      <ImageView imageSize={'small'} className={classes.centered} image={porte?.logoUri} />
                                                    </Grid>
                                                    <Grid item md={9} alignContent="center">
                                                      <Grid item md={12} className={classes.gridInterno}>
                                                        <b>{porte.nome}</b>
                                                      </Grid>
                                                      <Grid item md={12} className={classes.gridInterno}>
                                                        <Checkbox
                                                          color="primary"
                                                          name="freteGratuito" checked={Boolean(getPorteValue(item, porte)?.freteGratuito)}
                                                          onChange={(event) => { handleCheckboxConfigFreteRaioChange(event, item, porte) }}
                                                        /> <Typography variant="caption">Gratuito</Typography>
                                                      </Grid>
                                                      {!Boolean(getPorteValue(item, porte)?.freteGratuito) &&
                                                        <Grid item md={12} className={classes.gridInterno}>
                                                          <TextField
                                                            label="Valor por Km"
                                                            className={classes.textFieldValores}
                                                            InputLabelProps={{
                                                              shrink: true,
                                                            }}
                                                            InputProps={{
                                                              inputComponent: CurrencyFormatCustom
                                                            }}
                                                            name="valorKm" value={getPorteValue(item, porte)?.valorKm}
                                                            onChange={(event) => handleInputConfigFreteRaioChange(event, item, porte)}
                                                          />
                                                          <Tooltip title="use 0 (zero) para não ter valores com frete grátis">
                                                            <TextField
                                                              label="Frete grátis compras acima de"
                                                              className={classes.textFieldValores}
                                                              InputLabelProps={{
                                                                shrink: true,
                                                              }}
                                                              InputProps={{
                                                                inputComponent: CurrencyFormatCustom
                                                              }}
                                                              name="valorMinimoCompraGratuidade" value={getPorteValue(item, porte)?.valorMinimoCompraGratuidade}
                                                              onChange={(event) => handleInputConfigFreteRaioChange(event, item, porte)}
                                                            />
                                                          </Tooltip>
                                                        </Grid>
                                                      }
                                                      <Grid item md={12} className={classes.gridInterno}>
                                                        <TextField
                                                          label="Prazo entrega (dias)"
                                                          type="number"
                                                          className={classes.textFieldValores}
                                                          InputLabelProps={{
                                                            shrink: true,
                                                          }}
                                                          InputProps={{
                                                            endAdornment: <InputAdornment position="end">dias</InputAdornment>,
                                                          }}
                                                          name="prazoEntrega" value={getPorteValue(item, porte)?.prazoEntrega}
                                                          onChange={(event) => handleInputConfigFreteRaioChange(event, item, porte)}
                                                        />
                                                      </Grid>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                              </>
                                            ))}

                                          </Grid>
                                        </Grid>
                                      </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                  )}
                                  {item?.tipo === 1 && (
                                    <ExpansionPanel className={classes.expansionPanel}>
                                      <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                      >
                                        <Typography className={classes.heading}><b>Configurações de frete único desta faixa</b></Typography>
                                      </ExpansionPanelSummary >
                                      <ExpansionPanelDetails>
                                        <Grid container>
                                          <Grid item md={3} className={classes.gridExterno}>
                                            <Typography variant="h5" className={classes.faixaFreteH5}>Valor único</Typography>
                                            <Grid container md={12}>
                                              <TextField
                                                label="Valor (R$)"
                                                InputLabelProps={{
                                                  shrink: true,
                                                }}
                                                InputProps={{
                                                  inputComponent: CurrencyFormatCustom
                                                }}
                                                onChange={(event) => handleInputsChange(event, item)} name="valorUnico" value={item.valorUnico}
                                              />
                                            </Grid>
                                          </Grid>
                                          <Grid item md={3} className={classes.gridExterno}>
                                            <Typography variant="h5" className={classes.faixaFreteH5}>Frete grátis compras acima de</Typography>
                                            <Grid container md={12}>
                                              <Tooltip title="use 0 (zero) para não ter valores com frete grátis">
                                                <TextField
                                                  label="Valor (R$)"
                                                  InputLabelProps={{
                                                    shrink: true,
                                                  }}
                                                  InputProps={{
                                                    inputComponent: CurrencyFormatCustom
                                                  }}
                                                  onChange={(event) => handleInputsChange(event, item)} name="valorUnicoValorMinimoCompraGratuidade" value={item.valorUnicoValorMinimoCompraGratuidade}
                                                />
                                              </Tooltip>
                                            </Grid>
                                          </Grid>
                                          <Grid item md={3} className={classes.gridExterno}>
                                            <Typography variant="h5" className={classes.faixaFreteH5}>Prazo de entrega </Typography>
                                            <Grid container md={12}>
                                              <TextField
                                                label="Prazo entrega (dias)"
                                                type="number"
                                                className={classes.textFieldValores}
                                                InputLabelProps={{
                                                  shrink: true,
                                                }}
                                                InputProps={{
                                                  endAdornment: <InputAdornment position="end">dias</InputAdornment>,
                                                }}
                                                name="valorUnicoPrazoEntrega" value={item.valorUnicoPrazoEntrega}
                                                onChange={(event) => handleInputsChange(event, item)}
                                              />
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                  )}
                                </>
                              )}
                            </Grid>
                          </Grid>
                        </CardContent>
                        <CardActions>
                          <Button variant="contained" color="primary" size="small" disabled={loadingButtonSave} onClick={(event) => { handleSave(item) }}>{loadingButtonSave ? (<CircularProgress size={22} />) : (<>Salvar</>)}</Button>
                          <Button variant="contained" color="secondary" size="small" disabled={loadingButtonDelete} onClick={(event) => { handleDelete(item) }}>{loadingButtonDelete ? (<CircularProgress size={22} />) : (<>Excluir faixa</>)}</Button>
                        </CardActions>
                      </Card>
                    </Grid>
                  ))}
                </>
              )
            }
          </Grid>

          <Button variant="contained" className={classes.addFaixaButton} color="primary" size="small" onClick={(event) => { addItem() }}>Adicionar nova faixa</Button>
        </>
      )}
    </Container >
  );
};

export default withRouter(Frete);
