import React from 'react';
import MaskedInput from 'react-text-mask';

const PhoneTextMaskCustom = (props)  =>{
    const { inputRef, ...other } = props;

    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        placeholderChar={'\u2000'}
        showMask
      />
    );
}

const CellPhoneTextMaskCustom = (props)  =>{
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /\d/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

const CepTextMaskCustom = (props)  =>{
    const { inputRef, ...other } = props;

    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
        placeholderChar={'\u2000'}
        showMask
      />
    );
}

const CNPJTextMaskCustom = (props)  =>{
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/,'.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/,'-', /\d/, /\d/,]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

function timeMask(value) {
  const chars = value.split('');
  const hours = [
      /[0-2]/,
      chars[0] === '2' ? /[0-3]/ : /[0-9]/
  ];

  const minutes = [ /[0-5]/, /[0-9]/ ];

  return hours.concat(':').concat(minutes);
}

const TimeTextMaskCustom = props => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={timeMask}
      placeholderChar={'\u2000'}
      showMask
    />
  );
};

const DiasSemana = [
  {text: 'Domingo', value: '0'},
  {text: 'Segunda-feira', value: '1'},
  {text: 'Terça-feira', value: '2'},
  {text: 'Quarta-feira', value: '3'},
  {text: 'Quinta-feira', value: '4'},
  {text: 'Sexta-feira', value: '5'},
  {text: 'Sábado', value: '6'},
];

export { PhoneTextMaskCustom, CepTextMaskCustom, TimeTextMaskCustom, CNPJTextMaskCustom, CellPhoneTextMaskCustom, DiasSemana};
