import React, { useEffect, useState, useRef } from 'react';
import { withRouter, useParams } from 'react-router-dom';
import { format } from 'date-fns'
import hashGenerator from 'hash-generator'
import Autocomplete from '@material-ui/lab/Autocomplete';
import DateFnsUtils from "@date-io/date-fns";
import deLocale from "date-fns/locale/pt-BR";
import dataURLtoFile from '../../../utils/dataURLtoFile';
import empresaService from '../../../services/modules/empresa.service';
import CupomDescontoService from '../../../services/modules/cupom-desconto.service';
import { Storage } from '@capacitor/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from '@material-ui/pickers';

import {
  Card,
  CardHeader,
  Paper,
  CardContent,
  Checkbox,
  TextField,
  Container,
  Button,
  Typography,
  Radio,
  RadioGroup,
  Grid,
  FormControlLabel,
  CardActions,
  Box,
  Divider
} from '@material-ui/core';
import { IconButton, Tooltip } from '@material-ui/core';

import {
  EditSharp as EditSharpIcon,
  Delete as DeleteIcon,
  PhotoCamera as IconPhotoCamera,
  Delete as IconDelete,
} from '@material-ui/icons';

import notification from '../../../services/notification';
import { CurrencyFormatCustom, ImageCropper } from '../../../components';
import { useStyles } from './styles';
import Loading from '../../../components/Loading';
import BannerPromocaoService from '../../../services/modules/banner-promocao.service';
import ProdutoGrupoEmpresa from '../../../services/modules/produto-grupo.service';
import grupoService from '../../../services/modules/produto-grupo.service';
import ProdutoService from '../../../services/modules/produto.service';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const BannerPromocaoEdit = ({ history }) => {
  const classes = useStyles();
  let { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [value, setValue] = React.useState(0);
  const [model, setModel] = useState(null);
  const fileUpload = React.createRef();
  const [isCropping, setIsCropping] = useState(false);
  const [tempLogo, setTempLogo] = useState(null);
  const [empresas, setEmpresas] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);
  const [isEmpresaItem, setIsEmpresaItem] = useState(null)
  const [empresaSelecionada, setEmpresaSelecionada] = useState(null);
  const [produtoGrupoEmpresaSelecionado, setProdutoGrupoEmpresaSelecionada] = useState(null);
  const [cupomDescontoSelecionado, setCupomDescontoSelecionado] = useState(null);
  const [produtoSelecionado, setProdutoSelecionado] = useState(null);
  const [empresaItemSelecionada, setEmpresaItemSelecionada] = useState(null);
  const [produtoItemSelecionado, setProdutoItemSelecionado] = useState(null);
  const [cuponsDesconto, setCuponsDesconto] = useState(null);
  const [itemParaEditar, setItemParaEditar] = useState(null);
  const [empresaLicenciada, setEmpresaLicenciada] = useState(null);
  const [produtos, setProdutos] = useState([]);
  const [logo, setLogo] = useState([])

  const [openItem, setOpenItem] = React.useState(false);
  const [tiposDesconto] = useState([
    { id: 1, descricao: 'Valor' },
    { id: 2, descricao: 'Percentual' }
  ]);

  const labelRef = useRef()
  const labelWidth = labelRef.current ? labelRef.current.clientWidth : 0

  const handleDeleteItem = async (item) => {
    if (item.id > 0) {
      await BannerPromocaoService.deleteItem(item.id);
    }

    bootstrapAsync();
  }

  const handleLogoChange = event => {
    const file = event.target.files[0];

    const reader = new FileReader();
    reader.onload = function () {
      setTempLogo(reader.result);
      setIsCropping(true);
      setLogo(data => ({ ...data, originalname: file.name }));
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveLogo = () => {
    setLogo({
      fileName: '',
      fileData: null
    });
  };

  const handleRadioClicked = (event) => {
    event.persist();

    if (event.target.value === 'empresaItem') {
      setIsEmpresaItem(true)
    } else if (event.target.value === 'Cupom') {
      setIsEmpresaItem(false)
    }
  }

  const handleSaveItem = async () => {
    if (itemParaEditar.id > 0) {
      await BannerPromocaoService.updateItem({ ...itemParaEditar, valor: (itemParaEditar.valor + '').replace(',', '.') });
    }
    else {
      await BannerPromocaoService.saveItem({ ...itemParaEditar, valor: (itemParaEditar.valor + '').replace(',', '.') });
    }

    handleCloseItem();
    bootstrapAsync();
  }

  const handleCloseItem = () => {
    setOpenItem(false);
    setItemParaEditar(null);
    setEmpresaItemSelecionada(null);
    setProdutoItemSelecionado(null);
    setProdutos(null);
  };

  const handleNotification = (title, body, image) => {
    const data = {
      title,
      body,
      image
    };
  
    BannerPromocaoService.sendNotification(data);
  }

  const bootstrapAsync = async () => {
    setLoading(true);
    try {
      const { value: empresaLicenciadaStorage } = await Storage.get({
        key: 'empresaLicenciada'
      });

      setEmpresaLicenciada(JSON.parse(empresaLicenciadaStorage));

      const { data: empresasData } = await empresaService.getAll();
      setEmpresas(empresasData);


      if (id > 0) {
        const { data } = await BannerPromocaoService.getBannerPromocaoById(id);
        setModel({ ...data });
        setEmpresaSelecionada(JSON.parse(empresaLicenciadaStorage)?.administrador === true ? empresasData?.find(item => item.id === data?.empresaId) : empresaLicenciadaStorage)
      } else {
        setModel({
          empresaId: JSON.parse(empresaLicenciadaStorage)?.id,
          codigo: '',
          descricao: '',
          tipoDesconto: 0,
          valor: 0,
          quantidade: 0,
          valorMinimoCompra: 0,
          validoAte: new Date(),
          situacao: 1,
          descricaoSituacao: 'Ativo'
        })
        setEmpresaSelecionada(JSON.parse(empresaLicenciadaStorage)?.administrador === true ? empresasData?.find(item => item.id === empresaLicenciadaStorage.id) : empresaLicenciadaStorage)
      }

      setLoading(false);

    } catch (error) {
      setLoading(false);
      notification.error(error);
    }
  };

  const handleChange = event => {
    event.persist();

    setModel(data => ({ ...data, [event.target.name]: event.target.value }));
  };

  const handleSubmit = async e => {
    e.preventDefault();

    setLoading(true);

    try {
      let result = null;

      let fileName = model.logo;
      let fileUrl = '';
      if (logo.fileData) {
        let formData = new FormData();
        formData.append('image', logo.fileData);
        const { data } = await BannerPromocaoService.addLogo(formData);
        fileName = data.fileName;
        fileUrl = data.file;
      }

      console.log('DDDDDDDDDDDDDDDDDDDDDDDD', model);

      if (model.id > 0) {
        result = await BannerPromocaoService.updateBannerPromocao(id, { ...model, imagem: fileName });
      } else {
        result = await BannerPromocaoService.insertBannerPromocao({ ...model, imagem: fileName })
      }

      if (result.response?.status === 500) {
        notification.erroValidacao(result.response.data.message)
      } else {
        if(id == 0) {
          handleNotification(model.titulo, model.descricao, fileUrl);
        }
        notification.success('Banner salvo com sucesso!');
        history.push('/banner-promocao');
      }

      setLoading(false);

    } catch (error) {
      setLoading(false);
      notification.error(error);
    }
  };

  const handleDateInicioChange = (date) => {
    setModel(data => ({ ...data, dataInicio: date.toISOString() }));
  };
  const handleDateFimChange = (date) => {
    setModel(data => ({ ...data, dataFim: date.toISOString() }));
  };

  useEffect(() => {
    const bootstrapIntAsync = async () => {
      setLoading(true);
      try {
        const { value: empresaLicenciadaStorage } = await Storage.get({
          key: 'empresaLicenciada'
        });
        
        setEmpresaLicenciada(JSON.parse(empresaLicenciadaStorage));
        
        const { data: empresasData } = await empresaService.getAll();
        setEmpresas(empresasData);

        const { data: cupons } = await CupomDescontoService.getCuponsDesconto('', null);
        setCuponsDesconto(cupons.rows);

        if (id > 0) {
          const { data } = await BannerPromocaoService.getBannerPromocaoById(id);
          console.log('UAHSDUASUDASHDUSHAUDHSAUHSDADSA', data);
          setModel({ ...data });

          if (data?.empresaId > 0) {
            setIsEmpresaItem(true);

            setEmpresaSelecionada(JSON.parse(empresaLicenciadaStorage)?.administrador === true ? empresasData?.find(item => item.id === data?.empresaId) : empresaLicenciadaStorage)

            const { data: depts } = await ProdutoGrupoEmpresa.getGruposDeEmpresa(data?.empresaId);
            setDepartamentos(depts);

            if (data?.produtoGrupoEmpresaId > 0) {
              setProdutoGrupoEmpresaSelecionada(depts.find(item => item.id === data?.produtoGrupoEmpresaId))

              const { data: produtosRes } = await ProdutoService.getProdutosByEmpresa(data?.empresaId);
              const produtosFiltrados = produtosRes?.filter(item => item.produtoGrupoId === depts.find(item => item.id === data?.produtoGrupoEmpresaId)?.produtoGrupoId)

              setProdutos(produtosFiltrados);

              if (data?.produtoId > 0) {
                setProdutoSelecionado(produtosFiltrados.find(item => item.id === data?.produtoId));
              }
            }

          } else if (data?.cupomId > 0) {
            setIsEmpresaItem(false);
            setCupomDescontoSelecionado(cupons?.rows?.find(item => item.id === data?.cupomId))
          } else {
            setIsEmpresaItem(null);
          }
        } else {
          setModel({
            cupom_desconto_id: null,
            cupomDescontoId: null,
            cupomDesconto: null,
            dataInicio: new Date(),
            dataFim: new Date(),
            empresaId: JSON.parse(empresaLicenciadaStorage)?.id,
            codigo: '',
            descricao: '',
            tipoDesconto: 0,
            valor: 0,
            quantidade: 0,
            valorMinimoCompra: 0,
            validoAte: new Date(),
            situacao: 1,
            descricaoSituacao: 'Ativo'
          })
          setEmpresaSelecionada(JSON.parse(empresaLicenciadaStorage)?.administrador === true ? empresasData?.find(item => item.id === empresaLicenciadaStorage.id) : empresaLicenciadaStorage)
        }

        setLoading(false);

      } catch (error) {
        setLoading(false);
        notification.error(error);
      }
    };
    bootstrapIntAsync();
  }, [id, tiposDesconto]);
  return (
    <Container className={classes.root} fixed>
      {isCropping && (
        <ImageCropper
          height={510}
          width={247}
          aspectRatio={2.0647}
          image={tempLogo}
          open={isCropping}
          onSave={croppedFile => {
            setIsCropping(false);
            setTempLogo(false);

            const file = dataURLtoFile(croppedFile, logo.originalname);
            setLogo(data => ({
              ...data,
              fileName: croppedFile,
              fileData: file
            }));
          }}
          onHide={() => {
            setIsCropping(false);
            setTempLogo(false);
          }}
        />
      )}
      {loading ?
        (
          <Loading></Loading>
        )
        : (<Grid container orientation="row" spacing={2}>
          <Grid item md={4}>
            <Paper>
              <div className={classes.logoContainer}>
                {logo.fileName ? (
                  <img src={logo?.fileName} className={classes.logoImage} alt="Imagem do banner" />
                ) : (
                    <>
                      <img src={model ? model.fileName : null} className={classes.logoImage} />
                    </>
                  )}

                <input
                  accept="image/*"
                  className={classes.logoInputFile}
                  ref={fileUpload}
                  type="file"
                  onChange={handleLogoChange}
                />
              </div>
              <Divider />
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <IconButton aria-label="Remover imagem" onClick={() => fileUpload.current.click()}>
                  <IconPhotoCamera />
                </IconButton>

                <IconButton aria-label="Remover logo" onClick={() => handleRemoveLogo()}>
                  <IconDelete />
                </IconButton>
              </div>
            </Paper>
          </Grid>
          <Grid item md={8} orientation="column">
            <Grid container orientation="row" spacing={2}>
              <Grid item md={12}>
                <Card>
                  <CardHeader title="Editar Banner de promoção" />
                  <CardContent>
                    <Grid container orientation="row" spacing={2}>
                      <Grid item md={6}>
                        <TextField
                          id="text-codigo"
                          name="titulo"
                          label="Título"
                          value={model?.titulo?.toUpperCase() ?? ''}
                          onChange={handleChange}
                          variant="outlined"
                          fullWidth
                          InputProps={{
                            readOnly: !(model?.id === null || model?.id === undefined || model?.id <= 0),
                          }}
                        />
                      </Grid>
                      <Grid item md={12}>
                        <TextField
                          id="text-descricao"
                          name="descricao"
                          label="Descrição"
                          value={model?.descricao ?? ''}
                          onChange={handleChange}
                          variant="outlined"
                          fullWidth
                          rows={3}
                        />
                      </Grid>
                      <Grid item md={12}>
                        <h4>Objeto do banner</h4>
                      </Grid>
                      <Grid item md={12}>

                      </Grid>
                      <Grid item md={12}>
                        <RadioGroup row aria-label="position" name="position" defaultValue="top">
                          <FormControlLabel value="empresaItem" control={<Radio checked={isEmpresaItem === true} color="primary" onClick={(event) => { handleRadioClicked(event) }} />} label="Empresa/Produto" />
                          <FormControlLabel value="Cupom" control={<Radio checked={isEmpresaItem === false} color="primary" onClick={(event) => { handleRadioClicked(event) }} />} label="Cupom de desconto" />
                        </RadioGroup>
                      </Grid>
                      {isEmpresaItem === true && (
                        <>
                          {empresaLicenciada?.administrador === true && (
                            <Grid item md={5}>
                              <Autocomplete
                                id="empresa"
                                value={empresaSelecionada}
                                onChange={async (event, newValue) => {
                                  setEmpresaSelecionada(newValue);

                                  const { data: depts } = await ProdutoGrupoEmpresa.getGruposDeEmpresa(newValue?.id);
                                  setDepartamentos(depts);

                                  setModel(item => { return { ...item, empresaId: newValue?.id } })
                                }}
                                options={empresas}
                                getOptionLabel={(option) => option.razaoSocial}
                                style={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} label="Empresa" variant="outlined" />}
                              />
                            </Grid>)}
                          {empresaSelecionada && (
                            <Grid item md={7}>
                              <Autocomplete
                                id="produtoGrupoEmpresa"
                                value={produtoGrupoEmpresaSelecionado}
                                onChange={async (event, newValue) => {
                                  setProdutoGrupoEmpresaSelecionada(newValue);

                                  setModel(item => { return { ...item, produtoGrupoEmpresaId: newValue?.id } })

                                  const { data: produtosRes } = await ProdutoService.getProdutosByEmpresa(empresaSelecionada.id);
                                  const produtosFiltrados = produtosRes?.filter(item => item.produtoGrupoId === newValue.produtoGrupoId)

                                  setProdutos(produtosFiltrados)

                                }}
                                options={departamentos}
                                getOptionLabel={(option) => option.produtoGrupo?.descricao}
                                style={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} label="Departamento" variant="outlined" />}
                              />
                            </Grid>
                          )}

                          {produtoGrupoEmpresaSelecionado && (
                            <Grid item md={7}>
                              <Autocomplete
                                id="produto"
                                value={produtoSelecionado}
                                onChange={(event, newValue) => {
                                  setProdutoSelecionado(newValue);
                                  setModel(item => { return { ...item, produtoId: newValue?.id } })

                                }}
                                options={produtos}
                                getOptionLabel={(option) => option.nome}
                                style={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} label="Produto" variant="outlined" />}
                              />
                            </Grid>
                          )}
                        </>
                      )}
                      {isEmpresaItem === false && (
                        <Grid item md={7}>
                          <Autocomplete
                            id="cupomDesconto"
                            value={cupomDescontoSelecionado}
                            onChange={async (event, newValue) => {
                              setCupomDescontoSelecionado(newValue);

                              setModel(item => { return { 
                                ...item, 
                                cupomDesconto: newValue, 
                                cupomDescontoId: newValue?.id,
                                cupom_desconto_id: newValue?.id
                              } })
                            }}
                            options={cuponsDesconto}
                            getOptionLabel={(option) => option?.codigo + ' (' + option?.descricaoSituacao + ')'}
                            style={{ width: 300 }}
                            renderInput={(params) => <TextField {...params} label="Cupom desconto " variant="outlined" />}
                          />
                        </Grid>
                      )}
                      <Grid item md={12}>
                        <h4>Configurações</h4>
                      </Grid>
                      <Grid item md={4}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
                          <Grid container>
                            <Grid item md={12}>
                              <KeyboardDateTimePicker
                                margin="normal"
                                id="date-picker-dialog"
                                inputVariant="outlined"
                                label="Data de Início"
                                format="dd/MM/yyyy HH:mm"
                                variant="outlined"
                                ampm={false}
                                value={model?.dataInicio}
                                onChange={handleDateInicioChange}
                                KeyboardButtonProps={{
                                  'aria-label': 'Data de Início',
                                }}
                              />
                            </Grid>
                          </Grid>
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item md={4}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
                          <Grid container>
                            <Grid item md={12}>
                              <KeyboardDateTimePicker
                                margin="normal"
                                id="date-picker-dialog"
                                inputVariant="outlined"
                                label="Data de fim"
                                format="dd/MM/yyyy HH:mm"
                                variant="outlined"
                                ampm={false}
                                value={model?.dataFim}
                                onChange={handleDateFimChange}
                                KeyboardButtonProps={{
                                  'aria-label': 'Data de Fim',
                                }}
                              />
                            </Grid>
                          </Grid>
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item md={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={model?.mostrarNaAberturaDoAPP}
                              color="primary"

                              onClick={() => {
                                setModel(item => {
                                  item.mostrarNaAberturaDoAPP = !item.mostrarNaAberturaDoAPP;
                                  return { ...item };
                                })
                              }}
                            />
                          }
                          label="Mostrar popup na abertura do APP"
                        />

                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={model?.exibirNoAPP}
                              color="primary"

                              onClick={() => {
                                setModel(item => {
                                  item.exibirNoAPP = !item.exibirNoAPP;
                                  return { ...item };
                                })
                              }}
                            />
                          }
                          label="Exibir banner no app"
                        />

                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={model?.exibirNotificacao}
                              color="primary"

                              onClick={() => {
                                setModel(item => {
                                  item.exibirNotificacao = !item.exibirNotificacao;
                                  return { ...item };
                                })
                              }}
                            />
                          }
                          label="Notificar usuários"
                        />
                      </Grid>

                    </Grid>
                  </CardContent>
                  <CardActions className={classes.modalActions}>

                    <Button style={{ marginLeft: 'auto' }} disabled={model?.situacao === 3} variant="contained" color="primary" onClick={handleSubmit}>
                      Salvar
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>)
      }

    </Container >
  );
};

export default withRouter(BannerPromocaoEdit);
