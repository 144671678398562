import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

const CurrencyFormatCustom = props => {
  const { inputRef, onValueChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={onValueChange}
      thousandSeparator={'.'}
      decimalSeparator={','}
      decimalScale={2}
      fixedDecimalScale
    />
  );
};
CurrencyFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

const DecimalThreePrecision = props => {
  const { inputRef, onValueChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={onValueChange}
      thousandSeparator={'.'}
      decimalSeparator={','}
      decimalScale={3}
      fixedDecimalScale
    />
  );
};
DecimalThreePrecision.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

const PercentageDecimalThreePrecision = props => {
  const { inputRef, onValueChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={onValueChange}
      decimalSeparator={'.'}
      decimalScale={3}
      fixedDecimalScale
    />
  );
};
PercentageDecimalThreePrecision.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export { CurrencyFormatCustom, DecimalThreePrecision, PercentageDecimalThreePrecision };
