import { ACTIVATION, RESTORE_DATA, LOGIN, LOGOUT, CLEAR_DATA } from './actions';

export default function auth(
  state = {
    loading: true,
    activated: false,
    authenticated: false,
    empresaLicenciada: null
  },
  action
) {
  switch (action.type) {
    case ACTIVATION:
      return {
        ...state,
        activated: true
      };

    case RESTORE_DATA:
      return {
        ...state,
        loading: false,
        activated: action.activated,
        authenticated: action.authenticated,
        empresaLicenciada: action.empresaLicenciada
      };

    case CLEAR_DATA:
      return {
        ...state,
        loading: false,
        activated: false,
        authenticated: false
      };
    case LOGIN:
      return {
        ...state,
        authenticated: true
      };
    case LOGOUT:
      return {
        ...state,
        authenticated: false
      };

    default:
      return state;
  }
}
