import React, { useState, useEffect, createRef } from 'react';
import { format } from 'date-fns'
import { withRouter } from 'react-router-dom';
import { useStyles } from './styles';
import { Storage } from '@capacitor/core';
import { Card, CardContent, CardHeader, Container } from '@material-ui/core';
import { IconButton, Tooltip, CircularProgress } from '@material-ui/core';
import SortIcon from '@material-ui/icons/Sort';
import {
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Chip,
  TableFooter,
  TablePagination,
  CardActions,
  Button,
} from '@material-ui/core';
import {
  EditSharp as IconEditSharp,
  AddOutlined as AddOutlinedIcon
} from '@material-ui/icons';
import { ContentedButtons } from '../../../styles/shared';
import { InputSearch } from '../../../components';

import CupomDescontoService from '../../../services/modules/cupom-desconto.service';

const CupomDescontoList = ({ history }) => {
  const classes = useStyles();

  const refInputSearch = createRef();
  const [rowsCount, setRowsCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [lastQuery, setLastQuery] = useState('');
  const [growingSenseFilter, setGrowingSenseFilter] = useState(true);  
  const [empresaLicenciada, setEmpresaLicenciada] = useState(null);

  const [dataSource, setDataSource] = useState({
    data: []
  });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, dataSource.data.length - page * rowsPerPage);

  const handleSortResult = (itemToSort) => {
    let sense = !growingSenseFilter
    setGrowingSenseFilter(!growingSenseFilter)

    setDataSource(data => {
      const results = data.data.sort((a, b) => { return sortFunction(a, b, itemToSort, sense) })

      return {
        ...data,
        data: [...results]
      };
    });
  }

  const getSituacaoColor = (situacao) => {
    let color = ''
    switch (situacao) {
      case 1:
        color = 'primary';
        break;
      case 2:
        color = 'secondary';
        break;
      case 3:
        color = 'default';
        break;
      case null:
        color = 'secondary';
        break;
      default:
        color = 'default';
        break;
    }

    return color
  };

  const sortFunction = (a, b, itemToSort, sense) => {
    let sortResult = 0;
    switch (itemToSort) {
      case 'codigo':
        if (a.codigo?.toLowerCase() > b.codigo?.toLowerCase())
          sortResult = sense ? 1 : -1
        else if (a.codigo?.toLowerCase() < b.codigo?.toLowerCase())
          sortResult = sense ? -1 : 1
        else
          sortResult = 0
        break;
      case 'descricao':
        if (a.descricao?.toLowerCase() > b.descricao?.toLowerCase())
          sortResult = sense ? 1 : -1
        else if (a.descricao?.toLowerCase() < b.descricao?.toLowerCase())
          sortResult = sense ? -1 : 1
        else
          sortResult = 0
        break;
      case 'empresa':
        if (a.empresa?.nomeFantasia.toLowerCase() > b.empresa?.nomeFantasia.toLowerCase())
          sortResult = sense ? 1 : -1
        else if (a.empresa?.nomeFantasia.toLowerCase() < b.empresa?.nomeFantasia.toLowerCase())
          sortResult = sense ? -1 : 1
        else
          sortResult = 0
        break;
      case 'tipo':
        if (a.descricaoTipoDesconto?.toLowerCase() > b.descricaoTipoDesconto?.toLowerCase())
          sortResult = sense ? 1 : -1
        else if (a.descricaoTipoDesconto?.toLowerCase() < b.descricaoTipoDesconto?.toLowerCase())
          sortResult = sense ? -1 : 1
        else
          sortResult = 0
        break;
      case 'valor':
        if (a.valor > b.valor)
          sortResult = sense ? 1 : -1
        else if (a.valor < b.valor)
          sortResult = sense ? -1 : 1
        else
          sortResult = 0
        break;
      case 'data':
        if (new Date(a.validoAte) > new Date(b.validoAte))
          sortResult = sense ? 1 : -1
        else if (new Date(a.validoAte) < new Date(b.validoAte))
          sortResult = sense ? -1 : 1
        else
          sortResult = 0
        break;
      case 'situacao':
        if (a.situacao > b.situacao)
          sortResult = sense ? 1 : -1
        else if (a.situacao < b.situacao)
          sortResult = sense ? -1 : 1
        else
          sortResult = 0
        break;
      default:
        sortResult = 0;
        break;
    }

    return sortResult;
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const filterList = event => {
    setPage(0);
    const queryString = getQueryString();
    event.persist();
    const query = event.target.value;
    if (query.trim() !== '' && query.length >= 3) {
      CupomDescontoService.getCuponsDesconto(queryString, {termoParaBusca: query}).then(({ data: cuponsResult }) => {
        const { rows, rowsCount } = cuponsResult;
        setRowsCount(rowsCount);
        setDataSource({
          data: rows.map(row => ({
            ...row,
          }))
        });
      });
    } else if(query.trim() === ''){
      CupomDescontoService.getCuponsDesconto(queryString, {termoParaBusca: query}).then(({ data: cuponsResult }) => {
        const { rows, rowsCount } = cuponsResult;
        setRowsCount(rowsCount);
        setDataSource({
          data: rows.map(row => ({
            ...row,
          }))
        });
      });
    }
  };

  const handleRowsPerPageChange = event => {
    if (event.target.value === 'Todos')
      setRowsPerPage(parseInt(1000000, 25));
    else
      setRowsPerPage(parseInt(event.target.value, 25));
    setPage(0);
  };

  const getQueryString = () => {
    let queryString = `?page=${page + 1}&limit=${rowsPerPage}`;

    return queryString;
  };

  const loadData = () => {
    const queryString = getQueryString();
    if (queryString !== lastQuery && !loading) {
      setLoading(true);
      Storage.get({
        key: 'empresaLicenciada'
      }).then(x =>{
        setEmpresaLicenciada(JSON.parse(x.value));
      });

      CupomDescontoService
        .getCuponsDesconto(queryString)
        .then(({ data }) => {
          const { rows, rowsCount } = data;
          setRowsCount(rowsCount);
          setDataSource({
            data: rows.map(row => ({
              ...row,
            }))
          });

          setLoading(false);
        })
        .catch(() => setLoading(false));

      setLastQuery(queryString);
    }
  };

  useEffect(() => loadData());

  return (
    <Container className={classes.root} fixed>
      <Card>
        <CardHeader title="Cupons de Desconto" />
        <CardActions style={{ justifyContent: 'space-between' }}>
          <InputSearch inputRef={refInputSearch} onChange={filterList} />
          <ContentedButtons>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => history.push('/cupom-desconto/0')}
            >
              <AddOutlinedIcon /> Adicionar
            </Button>
          </ContentedButtons>
        </CardActions>
        <CardContent>
          <TableContainer style={{ marginTop: '15px' }}>
            <Table stickyHeader size="small" aria-label="">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.headerCell} onClick={(event) => { handleSortResult('codigo') }}>Cupom <SortIcon className={classes.sortIcon} /></TableCell>
                  <TableCell className={classes.headerCell} onClick={(event) => { handleSortResult('descricao') }}>Descrição <SortIcon className={classes.sortIcon} /></TableCell>
                  {empresaLicenciada?.administrador === true && (<TableCell className={classes.headerCell} onClick={(event) => { handleSortResult('empresa') }}>Empresa <SortIcon className={classes.sortIcon} /></TableCell>)}
                  <TableCell className={classes.headerCell} onClick={(event) => { handleSortResult('tipo') }}>Tipo <SortIcon className={classes.sortIcon} /></TableCell>
                  <TableCell className={classes.headerCell} onClick={(event) => { handleSortResult('valor') }}>Valor do desconto <SortIcon className={classes.sortIcon} /></TableCell>
                  <TableCell align="center" className={classes.headerCell} onClick={(event) => { handleSortResult('data') }}>Expira em <SortIcon className={classes.sortIcon} /></TableCell>
                  <TableCell align="center" className={classes.headerCell} onClick={(event) => { handleSortResult('situacao') }}>Situação <SortIcon className={classes.sortIcon} /></TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={5} rowSpan="all" align="center">
                      <CircularProgress />
                      <p>Carregando...</p>
                    </TableCell>
                  </TableRow>
                ) : (
                    <>
                      {dataSource.data.map(row => (
                        <TableRow key={row.id}>
                          <TableCell >
                            <b>{row.codigo}</b>
                          </TableCell>
                          <TableCell >
                            {row.descricao}
                          </TableCell>
                          {empresaLicenciada?.administrador === true && (<TableCell >
                            {row?.empresa?.nomeFantasia}
                          </TableCell>)}
                          <TableCell>{row?.descricaoTipoDesconto}</TableCell>
                          <TableCell> {row?.tipoDesconto === 2 ? `${row?.valor} %` : `R$ ${(+row?.valor)?.toFixed(2)}`}</TableCell>
                          <TableCell align="center">{format(new Date(row?.validoAte), 'dd/MM/yyyy HH:mm')}</TableCell>
                          <TableCell align="center">
                            <Chip
                              size="small"
                              color={getSituacaoColor(row?.situacao)}
                              label={row?.descricaoSituacao}
                            /></TableCell>
                          <TableCell align="right">
                            <Tooltip title="Editar">
                              <IconButton aria-label="Editar" onClick={() => history.push(`/cupom-desconto/${row.id}`)}>
                                <IconEditSharp />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  )}

                {dataSource.data.length === 0 && !loading && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={5}>Nenhuma informação encontrada</TableCell>
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={5}>
                    <TablePagination
                      labelRowsPerPage="Registro por página:"
                      labelDisplayedRows={({ from, to, count }) => `Exibindo ${from}-${to} de ${count} registros`}
                      rowsPerPageOptions={[25, 50, 100, 'Todos']}
                      component="div"
                      count={rowsCount}
                      page={page}
                      onChangePage={handlePageChange}
                      rowsPerPage={rowsPerPage}
                      onChangeRowsPerPage={handleRowsPerPageChange}
                    />
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </CardContent>
      </Card> </Container>
  );
};

export default withRouter(CupomDescontoList);
