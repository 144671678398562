import styled, { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  *{
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }
  html{
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  body {
    font-family: 'Montserrat', sans-serif;
    background-color: #f4f6f8;
  }
  body, input, button {
    font: 14px 'Montserrat', sans-serif;
  }
  html, body, #root {
    height: 100%;
  }
  button {
    cursor: pointer;
  }
  .swal2-container {
    z-index: 9999;
  }

  .makeStyles-content-2{
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }
  .MuiCardContent-root:last-child {
    padding-bottom: 24px !important;
  }
  .MuiContainer-root{
    /* padding-bottom: 24px !important; */
  }
  .MuiCardHeader-root {
    padding:  16px 16px 0 16px !important;
    .MuiCardHeader-title {
      font-size: 14px;
      color: #eb5757;
      font-weight: bold;
      text-transform: uppercase;
    }
    .MuiCardHeader-action {
      padding-top: 5px;
    }
    .MuiSvgIcon-root {
      color: #333;
    }
  }

  .MuiCardContent-root {
    /* padding: 0 !important; */
    h5 {
      color: #333;
      font-size: 14px;
    }
  }

  .MuiInputLabel-outlined{
    color: #999;
  }

  .MuiTableCell-stickyHeader {
    font-weight: bold;
    background-color: #FFF;
  }

  .MuiCardActions-root {
    display: flex;
    padding: 16px !important;
  }
`;

export const Title = styled.h1`
  align-self: center;
  font-family: 'Montserrat', sans-serif;
  span {
    font-weight: 300;
  }
`;
