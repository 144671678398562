import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

export const useStyles = makeStyles({
  root: {},
  address: {
    marginTop: '8px'
  },
  divider: {
    margin: '10px 0'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  modalHeader: {
    borderBottom: '1px solid #E4E7EB'
  },
  modalActions: {
    borderTop: '1px solid #E4E7EB',
    padding: '15px',
    display: 'flex',
    buttonRight: {
      marginLeft: 'auto'
    },
    buttonLeft: {
      marginRight: 'auto'
    }
  },

  paper: {
    backgroundColor: '#fff',
    border: '2px solid #000',
    boxShadow: '1px 1px 2px #333',
    padding: '10px'
  }
});

export const CardLink = styled(Link)`
  text-decoration: none;
  >:hover {
    text-decoration: none;
    background-color: aliceblue;
  }
`;
