import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Box,
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Checkbox,
  MenuItem,
  Select,
  FormControl,
  TextField,
  InputLabel,
  ListItemText,
  Button,
  Tabs,
  Tab
} from '@material-ui/core';

import {
  Search as IconSearch,
  Add as IconAdd,
  DeleteSweep as IconDeleteSweep,
  DoneAll as IconDoneAll,
  Update as UpdateIcon
} from '@material-ui/icons';

import { apiSiaf } from '../../services/api';
import notification from '../../services/notification';
import produtoService from '../../services/modules/produto.service';
import * as ProductActions from '../../store/modules/products/actions';

import TableSelecaoProdutos from './components/TableSelecaoProdutos';
import TableCarinhoExportacao from './components/TableCarinhoExportacao';
import { useStyles } from './styles';
import Loading from '../../components/Loading';

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{ padding: '20px 0' }}>
          {children}
        </Box>
      )}
    </Typography>
  );
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const Exportacao = ({ loadingData, products, addProducts, clearProducts, rowsPerPage }) => {
  const classes = useStyles();

  const [value, setValue] = useState(0);

  const [loading, setLoading] = useState(false);
  const [grupos, setGrupos] = useState([]);
  const [produto, setProduto] = useState('');
  const [subgrupos, setSubgrupos] = useState([]);
  const [familias, setFamilias] = useState([]);
  const [subFamilias] = useState([]);
  const [grupoSelecionados, setGruposSelecionados] = useState([]);
  const [familiaSelecionados, setFamiliasSelecionados] = useState([]);
  const [subgrupoSelecionados, setSubgruposSelecionados] = useState([]);
  const [subFamiliaSelecionados, setSubFamiliaSelecionados] = useState([]);

  useEffect(() => {
    const bootstrapAsync = async () => {
      const { data: dataGrupo } = await apiSiaf.get('/api/estoque/grupo');
      setGrupos(dataGrupo);

      const { data: dataSubgrupo } = await apiSiaf.get('/api/estoque/subgrupo');
      setSubgrupos(dataSubgrupo);

      const { data: dataFamilia } = await apiSiaf.get('/api/estoque/familia');
      setFamilias(dataFamilia);

      const { data: dataSubFamilia } = await apiSiaf.get('/api/estoque/subfamilia');
      setFamilias(dataSubFamilia);
    };

    loadingData({});

    bootstrapAsync();
  }, [loadingData]);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  const handleUpdatePrecoProdutosSIAF = async () => {
    var notify = notification.loadingLongOperation("Operação em lote sendo realizada...");
    try {
      await produtoService.updatePrecoProdutosSIAF();
      notify.then(x => { x.dismiss() });
      notification.success("Alteração em lote concluída.");
    } catch (e) {
      console.error(e);
      notify.then(x => { x.dismiss() });
      notification.erroValidacao("Não conseguimos sicronizar os preços com o SIAF. Caso o problema persista contate nosso suporte.");
    }
  }

  const handleChangeGrupo = event => {
    const selected = event.target.value || [];
    setGruposSelecionados(selected);
  };

  const handleChangeSubgrupo = event => {
    const selected = event.target.value || [];
    setSubgruposSelecionados(selected);
  };

  const handleChangeFamilia = event => {
    const selected = event.target.value || [];
    setFamiliasSelecionados(selected);
  };

  const handleChangeSubFamilia = event => {
    const selected = event.target.value || [];
    setSubFamiliaSelecionados(selected);
  };

  const handleFiltrar = event => {
    loadingData({
      filters: {
        produto: produto,
        grupos: grupoSelecionados,
        subgrupos: subgrupoSelecionados,
        familia: familiaSelecionados,
        subfamilia: subFamiliaSelecionados
      }
    })
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);

    try {
      let departmentNotFilled = false;

      products.forEach(item => {
        if (!(item.grupo.grupoProdutoGenericoId > 0))
          departmentNotFilled = true;
      })

      if (departmentNotFilled) {
        notification.erroValidacao('Você deve preencher o departamento de todos os produtos antes de prosseguir...')
        setLoading(false);
        return;
      }

      setLoading(true);
      await produtoService.exportProdutos(
        products.map(({ descricao, ...produto }) => ({
          ...produto,
          nome: descricao,
          passoFracionado: typeof produto.passoFracionado === 'string'
            ? (Number(produto.passoFracionado.split(',').join('.').replace(/[^0-9.-]+/g, '')))
            : produto.passoFracionado,
        }))
      );
      clearProducts();
      setLoading(false);

      notification.success('Produtos exportados com sucesso!');
    } catch (error) {
      setLoading(false);
      notification.error(error);
    }
  };

  const keyPress = (e) => {
    if(e.keyCode == 13){
      handleFiltrar()
    }
 }

  return (
    <Container className={classes.root} fixed>
      <Card>
        {loading ? (
          <Loading />
        ) : (
            <CardContent>
              <Tabs
                className={classes.tab}
                value={value}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleChange}
                aria-label="disabled tabs example"
              >
                <Tab label="Seleção de produtos" />
                <Tab label="Carrinho de exportação" />
              </Tabs>

              <TabPanel value={value} index={0}>
                <Grid container direction="row" spacing={2}>
                  <Grid item size={4}>
                    <TextField
                      className={classes.formControl}
                      id="produto"
                      label="Produto"
                      variant="outlined"
                      fullWidth
                      value={produto}
                      onKeyDown={(event) => { keyPress(event) }}
                      onChange={e => setProduto(e.target.value)}
                    />
                  </Grid>
                  <Grid item size={8}>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <InputLabel id="grupo">Grupo</InputLabel>
                      <Select
                        labelId="grupo"
                        id="select-grupo"
                        label="grupo"
                        multiple
                        value={grupoSelecionados}
                        onChange={handleChangeGrupo}
                        renderValue={selected => selected.map(s => s.descricao).join(', ')}
                      >
                        {grupos.map(grupo => (
                          <MenuItem key={grupo.id} value={grupo}>
                            <Checkbox
                              color="primary"
                              checked={grupoSelecionados.indexOf(grupoSelecionados.find(g => g.id === grupo.id)) > -1}
                            />
                            <ListItemText>{grupo.descricao}</ListItemText>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item size={4}>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <InputLabel id="subgrupo">Subgrupo</InputLabel>
                      <Select
                        labelId="subgrupo"
                        id="select-Subgrupo"
                        label="Subgrupo"
                        multiple
                        value={subgrupoSelecionados}
                        onChange={handleChangeSubgrupo}
                        renderValue={selected =>
                          selected.map(s => s.descricao).join(', ')
                        }
                        MenuProps={MenuProps}
                      >
                        {subgrupos.map(subgrupo => (
                          <MenuItem key={subgrupo.id} value={subgrupo}>
                            <Checkbox
                              color="primary"
                              checked={
                                subgrupoSelecionados.indexOf(
                                  subgrupoSelecionados.find(
                                    g => g.id === subgrupo.id
                                  )
                                ) > -1
                              }
                            />
                            <ListItemText>{subgrupo.descricao}</ListItemText>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item size={4}>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <InputLabel id="familia">Família</InputLabel>
                      <Select
                        labelId="familia"
                        id="select-Familia"
                        label="Família"
                        multiple
                        value={familiaSelecionados}
                        onChange={handleChangeFamilia}
                        renderValue={selected =>
                          selected.map(s => s.descricao).join(', ')
                        }
                        MenuProps={MenuProps}
                      >
                        {familias.map(familia => (
                          <MenuItem key={familia.id} value={familia}>
                            <Checkbox
                              color="primary"
                              checked={
                                familiaSelecionados.indexOf(
                                  familiaSelecionados.find(
                                    g => g.id === familia.id
                                  )
                                ) > -1
                              }
                            />
                            <ListItemText>{familia.descricao}</ListItemText>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item size={4}>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <InputLabel id="subFamilia">Subfamilia</InputLabel>
                      <Select
                        labelId="subFamilia"
                        id="select-subFamilia"
                        label="Subfamilia"
                        multiple
                        value={subFamiliaSelecionados}
                        onChange={handleChangeSubFamilia}
                        renderValue={selected =>
                          selected.map(s => s.descricao).join(', ')
                        }
                        MenuProps={MenuProps}
                      >
                        {subFamilias.map(subfamilia => (
                          <MenuItem key={subfamilia.id} value={subfamilia}>
                            <Checkbox
                              color="primary"
                              checked={
                                subFamiliaSelecionados.indexOf(
                                  subFamiliaSelecionados.find(
                                    g => g.id === subfamilia.id
                                  )
                                ) > -1
                              }
                            />
                            <ListItemText>{subfamilia.descricao}</ListItemText>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item size={12}>
                    <Button variant="contained" color="primary" size="small" onClick={() => handleFiltrar()}>
                      <IconSearch />
                    Filtrar
                  </Button>

                    <Button
                      style={{ marginLeft: '10px' }}
                      variant="contained"
                      size="small"
                      onClick={() => {
                        loadingData({
                          filters: {},
                          page: 0,
                          rowsPerPage
                        });
                        setProduto('');
                        setGruposSelecionados([]);
                        setSubgruposSelecionados([]);
                        setFamiliasSelecionados([]);
                        setSubFamiliaSelecionados([]);
                      }
                      }
                    >
                      <IconDeleteSweep />
                    Limpar filtros
                  </Button>

                    <Button
                      style={{ marginLeft: '10px' }}
                      variant="contained"
                      color="default"
                      size="small"
                      onClick={() => addProducts()}
                    >
                      <IconAdd />
                    Adicionar itens selecionados
                  </Button>
                    <Button
                      style={{ marginLeft: '10px' }}
                      variant="contained"
                      color="secondary"
                      size="small"
                      onClick={() => handleUpdatePrecoProdutosSIAF()}
                    >
                      <UpdateIcon />
                    Atualizar preços de produtos já importados do SIAF
                  </Button>
                  </Grid>
                </Grid>

                <Grid item md={12}>
                  <TableSelecaoProdutos />
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Grid container spacing={2}>
                  <Grid item size={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      disabled={products.length === 0}
                      onClick={handleSubmit}
                    >
                      <IconDoneAll />
                    Salvar produtos
                  </Button>
                  </Grid>
                  <Grid item md={12}>
                    <TableCarinhoExportacao />
                  </Grid>
                </Grid>
              </TabPanel>
            </CardContent>
          )}
      </Card>
    </Container>
  );
};

const mapStateToProps = state => ({
  ...state.products
});

const mapDispatchToProps = dispatch => bindActionCreators(ProductActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Exportacao);
