import React from 'react';
import PropTypes from 'prop-types';
import { Divider, IconButton } from '@material-ui/core';
import { Delete as IconDelete, PhotoCamera as IconPhotoCamera } from '@material-ui/icons';

import { useStyles } from './styles';
import imageNotFound from '../../assets/images/not-found.png';

const ImageView = ({ image, handleChangeImage, handleRemoveImage, showActions, imageSize, rounded }) => {
  const classes = useStyles();

  const fileUpload = React.createRef();

  const getImageSize = () => {
    let imageStyle;
    switch (imageSize) {
      case 'small':
        imageStyle = {
          width: 80,
          height: 80,
          borderRadius: rounded ? 80 : 0
        };
        break;
      case 'normal':
        imageStyle = {
          width: 160,
          height: 160,
          borderRadius: rounded ? 160 : 0
        };
        break;
      case 'large':
        imageStyle = {
          width: 400,
          height: 400,
          borderRadius: rounded ? 400 : 0
        };
        break;
        case 'extraLarge':
        imageStyle = {
          width: 500,
          height: 500,
          borderRadius: rounded ? 500 : 0
        };
        break;
      case 'auto':
        imageStyle = {
          width: '100%',
          height: '100%',
          borderRadius: rounded ? '100%' : 0
        };
        break;
        case 'tiny':
          imageStyle = {
            width: 40,
            height: 40
          };
          break;
      default:
        break;
    }

    return imageStyle;
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div className={classes.logoContainer}>
        {image ? (
          <img src={image} ref={img => image = img} className={classes.logoImage} style={getImageSize()} alt="Imagem" />
        ) : (
          <img src={imageNotFound} className={classes.logoImage} style={getImageSize()} alt="Sem imagem" />
        )}

        <input
          accept="image/*"
          className={classes.logoInputFile}
          ref={fileUpload}
          type="file"
          onChange={event => handleChangeImage(event)}
        />
      </div>
      {showActions && (
        <>
          <Divider />
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <IconButton aria-label="Remover imagem" onClick={() => fileUpload.current.click()}>
              <IconPhotoCamera />
            </IconButton>

            <IconButton aria-label="Remover logo" onClick={() => handleRemoveImage()}>
              <IconDelete />
            </IconButton>
          </div>
        </>
      )}
    </div>
  );
};

ImageView.propTypes = {
  image: PropTypes.string,
  handleChangeImage: PropTypes.func,
  handleRemoveImage: PropTypes.func,
  showActions: PropTypes.bool,
  imageSize: PropTypes.oneOf(['auto', 'small', 'normal', 'large']),
  rounded: PropTypes.bool
};

ImageView.defaultProps = {
  imageSize: 'small',
  rounded: false
};

export default ImageView;
