import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import CardMenu from './components/CardMenu';
import PedidoService from '../../services/modules/pedido.service';
import { useStyles2, Container1 } from './styles';
import { Loading } from '../../components';
import ClienteService from '../../services/modules/cliente.service';
import notification from '../../services/notification';
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Divider
} from '@material-ui/core';

import { Storage } from '@capacitor/core';
import usuarioService from '../../services/modules/usuario.service';
const cardImages = {
  configuracao: require('../../assets/images/configuracao.png'),
  parametro: require('../../assets/images/parametro.png'),
  exportar: require('../../assets/images/exportar.png'),
  produto: require('../../assets/images/produto.png'),
  pedido: require('../../assets/images/pedido.png')
};

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const Sorteio = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const classes = useStyles2();
  const [clientes, setClientes] = useState(0);
  const [usuarioSorteadoExibido, setUsuarioSorteadoExibido] = useState(null);
  const [sorteando, setSorteando] = useState(true);
  const [empresa, setEmpresa] = useState(null);
  const loadData = async () => {
    const clientesResult = await ClienteService.getAll();
    if (clientesResult?.data?.rows)
      setClientes(clientesResult.data.rows)

    const { value: empresaLicenciada } = await Storage.get({
      key: 'empresaLicenciada'
    });
    setEmpresa(JSON.parse(empresaLicenciada));
  };

  const enviarNotificacao = async () => {
    // void
  }

  const sortCliente = async () => {
    setSorteando(true);
    let array = [...clientes]
    var currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    for (let item of array.slice(1, 50)) {
      setUsuarioSorteadoExibido(item);
      await sleep(80);
    }

    for (let item of array.slice(1, 15)) {
      setUsuarioSorteadoExibido(item);
      await sleep(200);
    }

    for (let item of array.slice(1, 4)) {
      setUsuarioSorteadoExibido(item);
      await sleep(500);
    }

    setUsuarioSorteadoExibido(array[0])
    setSorteando(false);
  }

  useEffect(() => {
    loadData();
  }, [])
  return (
    <Container fixed>
      <CssBaseline />

      <Grid container spacing={2} direction="row">
        <Grid item md={12} xs={12}>
          <Grid spacing={2} container direction="row">
            <Grid item md={12} xs={12}>
              <Container1>
                {loading ? (
                  <Loading />
                ) :
                  (
                    <Card className={classes.root}>
                      <CardHeader title="Sorteio" />
                      <CardContent>
                        <Grid container alignItems="center" justify="center">
                          <Grid item md={12} xs={12}>
                            <Typography variant="h1" component="h1" style={{ height: 100, marginTop: 100 }}>
                              {usuarioSorteadoExibido ? usuarioSorteadoExibido.nome?.toUpperCase() : 'Clique para sortear'}
                            </Typography>
                            {sorteando !== true && (
                              <>
                                <Typography variant="h3" style={{ color: 'gray' }}>
                                  (xx) x xxxx-
                                                        {usuarioSorteadoExibido?.telefone ? ` ${usuarioSorteadoExibido.telefone.substr(usuarioSorteadoExibido.telefone.length - 4)}` : ''}
                                </Typography>
                                <Typography variant="h3" style={{ color: 'gray' }}>
                                  {usuarioSorteadoExibido?.enderecos[0] ? usuarioSorteadoExibido.enderecos[0].bairro + ' - ' + usuarioSorteadoExibido.enderecos[0].cidade + '/' + usuarioSorteadoExibido.enderecos[0].uf : ''}
                                  {usuarioSorteadoExibido ? ` (${usuarioSorteadoExibido.id})` : ''}
                                </Typography>
                              </>
                            )}
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  )
                }
              </Container1>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12} xs={12}>
          <Grid spacing={2} container direction="row">
            {/* <Grid item md={12} xs={12}>
              <CardEmpresa />
            </Grid> */}
            <Grid item md={12} xs={12} onClick={() => { sortCliente() }} >
              <CardMenu label="SORTEAR" axius="vertical" icon={cardImages['produto']} />
            </Grid>
            {/* <Grid item md={3} xs={3} onClick={() => { enviarNotificacao() }}>
              <CardMenu label="NOTIFICAR" axius="vertical" icon={cardImages['exportar']} />
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default withRouter(Sorteio);
