import React from 'react';
import Cropper from 'cropperjs';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';

import 'cropperjs/dist/cropper.min.css';
import { ImgContainer } from './styles';
import { ModalActions, ModalContainer, ModalCustom } from '../../styles/shared';

class ImageCropperPreview extends React.Component {
  constructor() {
    super();
    this.state = {
      imageDestination: ''
    };
    this.imageElement = React.createRef();
  }

  handleSave() {
    const { imageDestination } = this.state;
    this.props.onSave(imageDestination);
  }

  handleHide() {
    this.props.onHide();
  }

  componentDidMount() {
    const cropper = new Cropper(this.imageElement.current, {
      zoomable: true,
      scalable: false,
      aspectRatio: +this.props.aspectRatio,
      crop: () => {
        const canvas = cropper.getCroppedCanvas();
        this.setState({ imageDestination: canvas.toDataURL('image/png') });
      }
    });
  }

  render() {
    return (
      <>
        <ImgContainer ref={this.imageElement} src={this.props.image} alt="Source" />
        <ModalActions>
          <Button color="primary" onClick={() => this.handleHide()}>
            Cancelar
          </Button>
          <Button color="primary" variant="contained" onClick={() => this.handleSave()}>
            Cortar imagem
          </Button>
        </ModalActions>
      </>
    );
  }
}

ImageCropperPreview.propTypes = {
  image: PropTypes.string.isRequired
};

const ImagemCropper = ({ image, width, height, onSave, onHide, aspectRatio }) => {
  return (
    <ModalCustom open title="Cortar imagem">
      <ModalContainer style={{ width: 600 }}>
        <ImageCropperPreview image={image} width={width} height={height} aspectRatio={aspectRatio} onSave={onSave} onHide={onHide} />
      </ModalContainer>
    </ModalCustom>
  );
};

ImagemCropper.propTypes = {
  image: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default ImagemCropper;
