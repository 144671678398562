import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

export const useStyles = makeStyles({
  root: {}
});

export const CardLink = styled(Link)`
  display: flex;
  justify-content: center;
  text-decoration: none;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
  padding: 12px;

  :hover {
    text-decoration: none;
    background-color: aliceblue;
  }

  img {
    display: block;

    width: 64px;
    margin-bottom: 16px;
  }
  span {
    display: block;
    font-size: 14px;
    font-weight: bold;
    color: #eb5757;
  }
`;
