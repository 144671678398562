import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  inputInput: {
    border: 'none',
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '20ch',
      '&:focus': {
        width: '30ch'
      }
    }
  }
}));

export const InputSearchWrapper = styled.div`
  position: relative;
  border-radius: 10px;
  background-color: #f3f3f3;

  :hover {
    background-color: fade('#F3F3F3', 0.8);
  }

  margin-right: 10px;
`;

export const InputSearchIcon = styled.div`
  color: #333;
  padding: 0 8px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;
