import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { HashRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Plugins } from '@capacitor/core';
import empresaService from './services/modules/empresa.service';
import GlobalStyles from './styles/global';
import theme from './styles/theme';
import 'react-perfect-scrollbar/dist/css/styles.css';

import { inicializarFirebase, pedirPermissaoParaReceberNotificacoes } from './services/modules/push-notification.service';
import Routes from './Routes';
import * as AuthActions from './store/modules/auth/actions';
import { Login } from './views';
import { Minimal as MinimalLayout } from './layouts';
import { Loading } from './components';

const { Storage } = Plugins;

const App = ({ auth, restoreData }) => {
  useEffect(() => {
    const bootstrapAsync = async () => {
      const { value: empresaLicenciada } = await Storage.get({
        key: 'empresaLicenciada'
      });

      const { value: servidorSiaf } = await Storage.get({
        key: 'servidorSiaf'
      });
      const { value: usuarioToken } = await Storage.get({
        key: 'usuarioToken'
      });
      const { value: chavePrivada } = await Storage.get({
        key: 'chavePrivada'
      });

      if (empresaLicenciada) {
        const { data: empresaLicenciadaAtualizada } = await empresaService.getEmpresa(0);

        if (empresaLicenciadaAtualizada?.id === JSON.parse(empresaLicenciada)?.id) {
          await Storage.set({ key: 'empresaLicenciada', value: JSON.stringify(empresaLicenciadaAtualizada) });
        }
      }

      const activated = servidorSiaf !== null && chavePrivada !== null;
      const authenticated = usuarioToken !== null;
      restoreData({
        empresaLicenciada: JSON.parse(empresaLicenciada),
        activated,
        authenticated
      });
    };

    bootstrapAsync();
  }, [restoreData]);

  useEffect( async () => {
    try {
      const { value: usuarioToken } = await Storage.get({
        key: 'usuarioToken'
      });
      const authenticated = usuarioToken !== null;
      await inicializarFirebase();
      await pedirPermissaoParaReceberNotificacoes(authenticated);
    } catch (error) {
      console.error(error);
    }
  }, [])

  if (auth.loading) {
    return <Loading />;
  }

  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        {!auth.authenticated ? (
          <MinimalLayout>
            <Login />
          </MinimalLayout>
        ) : (
          <Router>
            <Routes />
          </Router>
        )}
        <GlobalStyles />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

const mapStateToProps = state => ({
  auth: state.auth
});

const mapDispatchToProps = dispatch => bindActionCreators(AuthActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(App);
