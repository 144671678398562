import styled from 'styled-components';
import { Modal, Paper } from '@material-ui/core';

export const ModalCustom = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;

`;

export const ModalContainer = styled(Paper)`
  width: 420px;
  background: #fff;
  border-radius: 3px;
`;

export const ModalWrapper = styled.div`
  padding: 10px;
`;

export const ModalActions = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  padding: 20px;
`;
export const ContentedButtons = styled.div``;
