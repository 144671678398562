import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {},
  divider: {
    margin: '20px 0'
  },
  modalActions: {
    borderTop: '1px solid #E4E7EB',
    padding: '15px',
    display: 'flex',
    buttonRight: {
      marginLeft: 'auto'
    },
    buttonLeft: {
      marginRight: 'auto'
    }
  },
  logoContainer: {
    position: 'relative',
    padding: '0'
  },
  logoImage: {
    display: 'block',
    height: '100%',
    width: '100%',
    margin: 'auto',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  },
  logoOverlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    height: '100%',
    opacity: 0,
    transition: '.3s ease',
    background: '#333',
    '&:hover': {
      opacity: 0.8
    }
  },
  logoInputFile: {
    display: 'none'
    
  }
}));
