import React, { useState, useEffect, createRef } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from './styles';
import notification from '../../../services/notification';
import { Card, CardContent, CardHeader, Container, Divider } from '@material-ui/core';
import { IconButton, Tooltip, CircularProgress } from '@material-ui/core';
import SortIcon from '@material-ui/icons/Sort';
import { Storage } from '@capacitor/core';
import grupoService from '../../../services/modules/produto-grupo.service';
import {
  TableContainer,
  Input,
  InputLabel,
  Checkbox,
  ListItemText,
  FormControl,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  DialogContentText,
  Select,
  Switch,
  FormControlLabel,
  Chip,
  TableFooter,
  TablePagination,
  CardActions,
  Menu,
  MenuItem,
  Grid,
  TextField,
  Button,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import {
  EditSharp as IconEditSharp,
  MoreVert as IconMore,
  DoneAll as IconDoneAll,
  Star as IconStar,
  StarBorder as IconStarBorder,
} from '@material-ui/icons';
import { ContentedButtons } from '../../../styles/shared';
import { ImageView, InputSearch } from '../../../components';

import produtoService from '../../../services/modules/produto.service';
import freteService from '../../../services/modules/frete.service';
import { formatPrice } from '../../../utils/format';
import * as productActions from '../../../store/modules/products/actions';

const ProdutoList = ({ history }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const refInputSearch = createRef();
  const [rowsCount, setRowsCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [lastQuery, setLastQuery] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openDialogConfirmLot, setOpenDialogConfirmLot] = React.useState(false);
  const [empresaLicenciada, setEmpresaLicenciada] = useState(null);

  //Alteração em lote
  const [lotePorGrupo, setLotePorGrupo] = React.useState(false);
  const [optionsGrupos, setOptionsGrupos] = React.useState([]);
  const [gruposSelecionados, setGruposSelecionados] = React.useState([]);
  const [alterarPreco, setAlterarPreco] = useState(false);
  const [alterarStatus, setAlterarStatus] = useState(false);
  const [statusAlteracao, setStatusAlteracao] = useState(false);
  const [precoAlteracao, setPrecoAlteracao] = useState(false);
  const [portes, setPortes] = useState([]);
  const [growingSenseFilter, setGrowingSenseFilter] = useState(true);
  // FIM Alteração em lote

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [dataSource, setDataSource] = useState({
    data: []
  });

  const handleChangeDestaque = (produto) => {
    setDataSource(source => {

      let listInputs = source.data.map(input => {
        if (input.id === produto?.id) {
          var produtoAlterado = { ...input, destaque: !produto.destaque };

          produtoService.updateProduto(produtoAlterado.id, produtoAlterado);

          return produtoAlterado;
        } else {
          return { ...input }
        }
      });

      listInputs = listInputs.map(input => {
        return { ...input, precoVendaFormatado: formatPrice(input.precoVenda) }
      })

      return {
        ...source,
        data: [...listInputs]
      };
    });
  }

  const handleLotePorGrupoChange = (event) => {
    setLotePorGrupo(!event.target.checked);
  }

  const handleAlterarPreco = (event) => {
    setAlterarPreco(event.target.checked);
  }

  const handleAlterarStatus = (event) => {
    setAlterarStatus(event.target.checked);
  }

  const handleStatusAlteracao = (event) => {
    setStatusAlteracao(event.target.value);
  }

  const handlePrecoAlteracao = (event) => {
    setPrecoAlteracao(event.target.value);
  }

  const currentPage = useSelector(state => state.products.page);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, dataSource.data.length - page * rowsPerPage);

  const handleSortResult = (itemToSort) => {
    let sense = !growingSenseFilter
    setGrowingSenseFilter(!growingSenseFilter)

    setDataSource(data => {
      const results = data.data.sort((a, b) => { return sortFunction(a, b, itemToSort, sense) })

      return {
        ...data,
        data: [...results]
      };
    });
  }

  const sortFunction = (a, b, itemToSort, sense) => {
    let sortResult = 0;
    switch (itemToSort) {
      case 'produto':
        if (a.nome?.toLowerCase() > b.nome?.toLowerCase())
          sortResult = sense ? 1 : -1
        else if (a.nome?.toLowerCase() < b.nome?.toLowerCase())
          sortResult = sense ? -1 : 1
        else
          sortResult = 0
        break;
      case 'departamento':
        if (a.produtoGrupo?.descricao?.toLowerCase() > b.produtoGrupo?.descricao?.toLowerCase())
          sortResult = sense ? 1 : -1
        else if (a.produtoGrupo?.descricao?.toLowerCase() < b.produtoGrupo?.descricao?.toLowerCase())
          sortResult = sense ? -1 : 1
        else
          sortResult = 0
        break;
      case 'preco':
        if (a.precoVenda > b.precoVenda)
          sortResult = sense ? 1 : -1
        else if (a.precoVenda < b.precoVenda)
          sortResult = sense ? -1 : 1
        else
          sortResult = 0
        break;
      case 'estoqueReal':
        if (a.estoqueReal > b.estoqueReal)
          sortResult = sense ? 1 : -1
        else if (a.estoqueReal < b.estoqueReal)
          sortResult = sense ? -1 : 1
        else
          sortResult = 0
        break;
      case 'estoqueReservado':
        if (a.estoqueReservado > b.estoqueReservado)
          sortResult = sense ? 1 : -1
        else if (a.estoqueReservado < b.estoqueReservado)
          sortResult = sense ? -1 : 1
        else
          sortResult = 0
        break;
      case 'status':
        if (a.desativado > b.desativado)
          sortResult = sense ? 1 : -1
        else if (a.desativado < b.desativado)
          sortResult = sense ? -1 : 1
        else
          sortResult = 0;
        break;
      default:
        sortResult = 0;
        break;
    }

    return sortResult;
  };

  const handlePageChange = (event, newPage) => {
    dispatch(productActions.setPageCount(newPage));
  };

  const filterList = event => {
    setPage(0);
    const queryString = getQueryString();
    event.persist();
    const query = event.target.value;
    if (query.trim() !== '' && query.length >= 3) {
      produtoService.getProdutos(queryString, { termoParaBusca: query }).then(({ data: cuponsResult }) => {
        const { rows, rowsCount } = cuponsResult;
        setRowsCount(rowsCount);
        setDataSource({
          data: rows.map(row => ({
            ...row
            , precoVendaFormatado: formatPrice(row.precoVenda)
          }))
        });
      });
    } else if (query.trim() === '') {
      produtoService.getProdutos(queryString, { termoParaBusca: query }).then(({ data: cuponsResult }) => {
        const { rows, rowsCount } = cuponsResult;
        setRowsCount(rowsCount);
        setDataSource({
          data: rows.map(row => ({
            ...row
            , precoVendaFormatado: formatPrice(row.precoVenda)
          }))
        });
      });
    }
  };

  const handleClose = () => {
    setLastQuery((new Date()).getTime())
    loadData();
    setOpenDialog(false);
  };

  const handleCloseConfirmLot = () => {
    setOpenDialogConfirmLot(false)
  }

  const handleOpen = () => {
    setOpenDialog(true);
  };

  const handleConfirmSaveLotUpdate = async (saveNow) => {
    if (!saveNow) {
      setOpenDialogConfirmLot(true);
      return;
    } else {
      setOpenDialogConfirmLot(false)
      var notify = notification.loadingLongOperation("Operação em lote sendo realizada...");
      const { data } = await produtoService.updatePrecoStatusLote({
        alterarGrupo: lotePorGrupo,
        gurposParaAlterar: gruposSelecionados,
        alterarStatus: alterarStatus,
        alterarPreco: alterarPreco,
        statusAlteracao: alterarStatus ? statusAlteracao : null,
        precoAlteracao: alterarPreco ? precoAlteracao : null,
      })

      if (data) {
        notify.then(x => { x.dismiss() });
        notification.success("Alteração em lote concluída.");
        setTimeout(() => {
          setLastQuery((new Date()).getTime())
          loadData();
        }, 2000);
      } else {
        notify.then(x => { x.dismiss() });
        notification.error('Houve um erro ao realizar a alteração em lote. Tente novamente mais tarde.')
      }
    }
  }

  const handleAlterarPrecoEmLote = () => {
    notification.showInputAlert('Insira um valor (percentual) para ajustar os preços (pode ser + ou -)', 'Ajustar').then(result => {
      if (result.value && Number(result.value)) {

        setDataSource(dataSource => {
          const results = dataSource.data.map(item => {
            item.precoVenda = (item.precoVenda * ((+result.value + 100) / 100)).toFixed(2)

            return { ...item, editado: true }
          })

          return {
            ...dataSource,
            data: [...results]
          };
        })
      } else if (result?.value !== undefined) {
        notification.erroValidacao("O valor inserido não é valido, tente novamente.")
      }
    })
  }

  const handleAlterarPorteEmLote = () => {
    let portesInput = { 'Portes': {} }
    portes.forEach(porte => {
      portesInput.Portes = { ...portesInput.Portes, [porte.id]: porte.nome }
    })
    notification.showSelectAlert('Selecione uma nova opção de porte para os produtos', 'Ajustar', portesInput).then(result => {
      if (result.value && Number(result.value)) {

        setDataSource(dataSource => {
          const results = dataSource.data.map(item => {
            return { ...item, tipoPorteFreteId: +result.value, editado: true }
          })

          return {
            ...dataSource,
            data: [...results]
          };
        })
      } else if (result?.value !== undefined) {
        notification.erroValidacao("A opção inserida não é valida, tente novamente.")
      }
    })
  }

  const handleChangeEvent = async (event, itemLista) => {
    event.persist();
    setDataSource(source => {

      let listInputs = source.data.map(input => {
        if (input.id === itemLista?.id || !itemLista) {
          if (event.target.value && (event.target.value + '').length > 0)
            return { ...input, [event.target.name]: event.target.value, editado: true };
          else
            return { ...input, [event.target.name]: (event.target.name === "desativado" ? !event.target.checked : event.target.checked), editado: true };
        } else {
          return { ...input }
        }
      });

      listInputs = listInputs.map(input => {
        return { ...input, precoVendaFormatado: formatPrice(input.precoVenda) }
      })

      return {
        ...source,
        data: [...listInputs]
      };
    });
  }

  const handleSelectGruposChange = (event) => {
    setGruposSelecionados(event.target.value);
  };

  const handleSubmit = async event => {
    event.preventDefault();

    try {
      setLoading(true);
      setEditMode(false);
      var produtosParaAlterar = dataSource.data.filter(x => x.editado === true)
      await produtoService.updateProdutoLote([...produtosParaAlterar]);
      notification.success('Produtos salvos com sucesso!');
      setLoading(false);
      loadData();
    } catch (error) {
      setLoading(false);
      notification.error(error);
    }
  };

  const handleRowsPerPageChange = event => {
    if (event.target.value === 'Todos')
      setRowsPerPage(parseInt(1000000, 25));
    else
      setRowsPerPage(parseInt(event.target.value, 25));
    setPage(0);
  };

  const getQueryString = () => {
    let queryString = `?page=${page + 1}&limit=${rowsPerPage}`;

    return queryString;
  };

  const loadData = async () => {
    const queryString = getQueryString();
    if (queryString !== lastQuery && !loading) {
      setLoading(true);

      grupoService.getGrupos().then(data => {
        setOptionsGrupos(data.data);
      });

      freteService.getPortes().then(data => {
        setPortes(data.data)
      });

      const { value: empresaLicenciadaStorage } = await Storage.get({
        key: 'empresaLicenciada'
      });
      setEmpresaLicenciada(JSON.parse(empresaLicenciadaStorage));

      produtoService
        .getProdutos(queryString)
        .then(({ data }) => {
          const { rows, rowsCount } = data;
          setRowsCount(rowsCount);
          setDataSource({
            data: rows.map(row => ({
              ...row,
              precoVendaFormatado: formatPrice(row.precoVenda)
            }))
          });

          setLoading(false);
        })
        .catch(() => setLoading(false));

      setLastQuery(queryString);
    }
  };

  useEffect(() => {
    setPage(currentPage);
    loadData();
  });

  return (
    <Container className={classes.root} fixed>
      <Card>
        <CardHeader title="Produto" />
        <CardActions style={{ justifyContent: 'space-between' }}>
          <InputSearch inputRef={refInputSearch} onChange={filterList} />
          <ContentedButtons>
            {/* <IconButton aria-label="display more actions" edge="end" color="inherit" style={{ color: '#666' }}>
                  <IconFilterList />
                </IconButton> */}
            {empresaLicenciada?.possuiIntegracaoSIAF !== true && (
              <Button style={{ marginLeft: 'auto' }} variant="contained" color="primary" onClick={() => history.push(`/produto/0`)}>
                Adicionar item
              </Button>
            )}
            <IconButton
              aria-label="display more actions"
              color="inherit"
              style={{ color: '#666' }}
              onClick={event => setAnchorEl(event.currentTarget)}
            >
              <IconMore />
            </IconButton>

            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              <MenuItem
                onClick={() => {
                  refInputSearch.current.value = '';
                  setDataSource(data => ({
                    ...data,
                    data: [...data.data]
                  }));
                  setLastQuery((new Date()).getTime())
                  loadData();
                  setAnchorEl(null);
                  setEditMode(true);
                }}
              >
                Editar preços e status dos produtos
                </MenuItem>
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  handleOpen();
                }}
              >
                Alterar preço e status em lote
                </MenuItem>
            </Menu>
          </ContentedButtons>
        </CardActions>
        {editMode && (
          <>
            <CardActions style={{ justifyContent: 'flex-end' }}>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ marginLeft: 10, marginRight: 10 }}
                  onClick={handleSubmit}
                >
                  <IconDoneAll /> Salvar produtos
                  </Button>
                <Button variant="contained" size="small" onClick={() => setEditMode(false)}>
                  Cancelar
                </Button>
              </Grid>
            </CardActions>
          </>
        )}
        <CardContent>
          <TableContainer style={{ marginTop: '15px' }}>
            <Table stickyHeader size="small" aria-label="">
              <TableHead>
                <TableRow>
                  <TableCell>Imagem</TableCell>
                  <TableCell className={classes.headerCell} onClick={(event) => { handleSortResult('produto') }}>Produto {!editMode && (<SortIcon className={classes.sortIcon} />)}</TableCell>
                  <TableCell className={classes.headerCell} onClick={(event) => { handleSortResult('departamento') }}>Departamento {!editMode && (<SortIcon className={classes.sortIcon} />)}</TableCell>
                  <TableCell className={classes.headerCell} onClick={(event) => { handleSortResult('estoqueReal') }}>Estoque Real {!editMode && (<SortIcon className={classes.sortIcon} />)}</TableCell>
                  <TableCell className={classes.headerCell} onClick={(event) => { handleSortResult('estoqueReservado') }}>Estoque Reservado {!editMode && (<SortIcon className={classes.sortIcon} />)}</TableCell>
                  {!editMode && (
                    <>
                      <TableCell align="left" className={classes.headerCell} onClick={(event) => { handleSortResult('preco') }}>
                        Preço <SortIcon className={classes.sortIcon} />
                      </TableCell>
                      <TableCell align="center" className={classes.headerCell} onClick={(event) => { handleSortResult('status') }}>Status <SortIcon className={classes.sortIcon} /></TableCell>
                      <TableCell align="center"></TableCell>
                    </>
                  )}
                  {editMode && (
                    <>
                      <TableCell align="center">
                        Porte &nbsp;
                        <Tooltip title="Alterar porte dos produtos">
                          <Chip
                            size="small"
                            onClick={() => { handleAlterarPorteEmLote() }}
                            style={{ cursor: "pointer" }}
                            color="primary"
                            label="P"
                          />
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">
                        Preço &nbsp;
                        <Tooltip title="Alterar preços">
                          <Chip
                            size="small"
                            onClick={() => { handleAlterarPrecoEmLote() }}
                            style={{ cursor: "pointer" }}
                            color="primary"
                            label="%"
                          />
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center"><Switch
                        onChange={(event) => { handleChangeEvent(event, null) }}
                        checked={dataSource?.data?.find(x => !x.desativado)}
                        name="desativado"
                        color='primary'
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      /></TableCell>
                    </>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={5} rowSpan="all" align="center">
                      <CircularProgress />
                      <p>Carregando...</p>
                    </TableCell>
                  </TableRow>
                ) : (
                    <>
                      {dataSource.data.map(row => (
                        <TableRow key={row.id}>
                          <TableCell >
                            <ImageView image={row?.imagemUrl} />
                          </TableCell>
                          <TableCell >
                            <b>{(row.nome.length > 50) ? row.nome.substr(0, 50 - 1).trim() + '...' : row.nome}</b>
                            <br /> <small>EAN: {row.codigoBarra}</small>
                            <br />
                            <Tooltip title="Os produtos favoritados irão aparecer na tela inicial da sua loja no APP">
                              <Chip
                                size="small"
                                style={{ cursor: 'pointer' }}
                                color={row?.destaque ? 'primary' : 'default'}
                                variant={row?.destaque ? 'default' : 'outlined'}
                                avatar={row?.destaque ? <IconStar color='primary' /> : <IconStarBorder color='default' />}
                                label="Destaque"
                                onClick={(event) => handleChangeDestaque(row)}
                              />
                            </Tooltip>
                          </TableCell>
                          <TableCell>{row?.produtoGrupo?.descricao}</TableCell>
                          <TableCell align="center">{row?.inventariado === true ? 'N/D' : row?.estoqueReal}</TableCell>
                          <TableCell align="center">{row?.inventariado === true ? 'N/D' : (row.estoqueReservado === 0 ? 0 : Math.abs(row.estoqueReservado))}</TableCell>
                          {!editMode && (
                            <TableCell align="right">{row.precoVendaFormatado}</TableCell>
                          )}
                          {editMode && (
                            <>
                              <TableCell >
                                <FormControl style={{ width: 120 }}>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="tipoPorteFreteId"
                                    name="tipoPorteFreteId"
                                    value={row.tipoPorteFreteId ?? null}
                                    onChange={(event) => { handleChangeEvent(event, row) }}
                                  >
                                    <MenuItem value={null}>Nenhum</MenuItem>
                                    {portes.map(porte => (
                                      <MenuItem value={porte.id}>{porte.nome}</MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </TableCell>
                              <TableCell align="right">
                                <TextField style={{ width: 90 }}
                                  onChange={(event) => { handleChangeEvent(event, row) }}
                                  type="number"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  InputProps={{
                                    startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                                  }}
                                  name="precoVenda" value={row.precoVenda}
                                />
                              </TableCell>
                            </>
                          )}

                          <TableCell align="right">
                            {!editMode && (
                              <Chip
                                size="small"
                                color={row?.desativado ? "secondary" : "primary"}
                                label={row?.desativado ? "Desativado" : "Ativado"}
                              />
                            )}
                            {editMode && (
                              <Switch
                                onChange={(event) => { handleChangeEvent(event, row) }}
                                checked={!row?.desativado}
                                name="desativado"
                                color='primary'
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                              />
                            )}
                          </TableCell>
                          {!editMode && (
                            <TableCell align="right">
                              <Tooltip title="Editar">
                                <IconButton aria-label="Editar" onClick={() => history.push(`/produto/${row.id}`)}>
                                  <IconEditSharp />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                    </>
                  )}

                {dataSource.data.length === 0 && !loading && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={5}>Nenhuma informação encontrada</TableCell>
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={5}>
                    <TablePagination
                      labelRowsPerPage="Registro por página:"
                      labelDisplayedRows={({ from, to, count }) => `Exibindo ${from}-${to} de ${count} registros`}
                      rowsPerPageOptions={[25, 50, 100, 'Todos']}
                      component="div"
                      count={rowsCount}
                      page={page}
                      onChangePage={handlePageChange}
                      rowsPerPage={rowsPerPage}
                      onChangeRowsPerPage={handleRowsPerPageChange}
                    />
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </CardContent>
        {editMode && (
          <>
            <CardActions style={{ justifyContent: 'flex-end' }}>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ marginLeft: 10, marginRight: 10 }}
                  onClick={handleSubmit}
                >
                  <IconDoneAll /> Salvar produtos
                  </Button>
                <Button variant="contained" size="small" onClick={() => setEditMode(false)}>
                  Cancelar
                </Button>
              </Grid>
            </CardActions>
          </>
        )}
      </Card>


      <Dialog
        open={openDialog}
        onClose={handleClose}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Alteração de dados em lote</DialogTitle>
        <DialogContent dividers={true} className={classes.loteDialog}>
          <Grid container>
            <Grid item md={5} className={classes.gridLote}>
              <div style={{ marginTop: '10px' }}>
                <FormControlLabel
                  control={<Switch checked={!lotePorGrupo} color='primary' onChange={(event) => handleLotePorGrupoChange(event)} name="gilad" />}
                  label={lotePorGrupo === true ? "Alterar departamentos" : "Alterar tudo"}
                />
              </div>
            </Grid>
            <Grid item md={6} className={classes.gridLote}>
              {lotePorGrupo && (
                <FormControl className={classes.selectGruposLote}>
                  <InputLabel className={classes.selectGruposLote} id="demo-mutiple-checkbox-label">Departamentos</InputLabel>
                  <Select
                    labelId="demo-mutiple-checkbox-label"
                    id="demo-mutiple-checkbox"
                    multiple
                    value={gruposSelecionados}
                    onChange={handleSelectGruposChange}
                    input={<Input />}
                    renderValue={(selected) => selected.map(id => { return optionsGrupos.find(x => x.produtoGrupoId === id).produtoGrupo?.descricao }).join(', ')}
                    MenuProps={MenuProps}
                  >
                    {optionsGrupos?.map((grupo) => (
                      <MenuItem key={grupo.produtoGrupoId} value={grupo.produtoGrupoId}>
                        <Checkbox checked={gruposSelecionados.indexOf(grupo.produtoGrupoId) > -1} />
                        <ListItemText primary={grupo.produtoGrupo?.descricao} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Grid>
            <Grid item md={12} className={classes.divider}>
              <h3>Valores para alterar</h3>
              <Divider className={classes.divider} />
            </Grid>
            <Grid item md={12}>
              <Grid container md={12}>
                <Grid item md={6} className={classes.gridLote}>
                  <MenuItem>
                    <Checkbox checked={alterarStatus} onChange={handleAlterarStatus} />
                    <ListItemText primary="Status" />
                  </MenuItem>
                </Grid>
                <Grid item md={6} className={classes.gridLote}>
                  <MenuItem>
                    <Checkbox checked={alterarPreco} onChange={handleAlterarPreco} />
                    <ListItemText primary="Preço (percentual)" />
                  </MenuItem>
                </Grid>
                <Grid item md={6} className={classes.gridLote}>
                  {alterarStatus && (
                    <FormControl className={classes.selectGruposLote}>
                      <InputLabel id="demo-simple-select-label" >Status</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={statusAlteracao}
                        onChange={handleStatusAlteracao}
                      >
                        <MenuItem value={false}>Ativado</MenuItem>
                        <MenuItem value={true}>Desativado</MenuItem>
                      </Select>
                    </FormControl>

                  )}
                </Grid>
                <Grid item md={6} className={classes.gridLote}>
                  {alterarPreco && (
                    <TextField
                      style={{ marginLeft: 10 }}
                      onChange={handlePrecoAlteracao}
                      type="number"
                      label="Percentual"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                      }}
                      name="precoVenda" value={precoAlteracao}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>

          <Button onClick={() => {  
            handleConfirmSaveLotUpdate(false); 
            handleClose(); 
          }} color="primary">
            Alterar
          </Button>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDialogConfirmLot}
        onClose={handleCloseConfirmLot}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Alteração de dados em lote</DialogTitle>
        <DialogContent dividers={true} className={classes.loteDialog}>
          <DialogContentText>
            Tem certeza que deseja alterar as informações em lote?
          </DialogContentText>
        </DialogContent>
        <DialogActions>

          <Button onClick={() => { handleConfirmSaveLotUpdate(true) }} color="primary">
            Sim
          </Button>
          <Button onClick={handleCloseConfirmLot} color="primary">
            Não
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default withRouter(ProdutoList);
