import { call, all, put, takeLatest } from 'redux-saga/effects';
import { apiSiaf } from '../../../services/api';
import { LOADING_DATA, afterLoadingData, beforeLoadingData } from './actions';
import { formatPrice } from '../../../utils/format';

function* loadingData({ filters, page, rowsPerPage }) {
  yield put(beforeLoadingData({ filters, page, rowsPerPage }));

  let queryString = `?page=${page + 1}&limit=${rowsPerPage}`;

  if (filters.produto) {
    queryString += `&descricao=${filters.produto}`;
  }
  if (filters.grupos && filters.grupos.length > 0) {
    queryString += `&grupo=${filters.grupos.map(g => g.id).join(',')}`;
  }

  if (filters.subgrupos && filters.subgrupos.length > 0) {
    queryString += `&subgrupo=${filters.subgrupos.map(g => g.id).join(',')}`;
  }

  if (filters.subgrupos && filters.subgrupos.length > 0) {
    queryString += `&familia=${filters.familia.map(g => g.id).join(',')}`;
  }

  if (filters.subgrupos && filters.subgrupos.length > 0) {
    queryString += `&subfamilia=${filters.subfamilia.map(g => g.id).join(',')}`;
  }


  const { data } = yield call(apiSiaf.post, `/api/estoque/produto/${queryString}`);

  yield put(
    afterLoadingData({
      ...data,
      rows: data.rows.map(({ id, ...product }) => ({
        id,
        ...product,
        passoFracionado: (product.permiteFracionar === true ? 1 : null),
        precoVendaFormatado: formatPrice(product.precoVenda)
      }))
    })
  );
}

export default all([takeLatest(LOADING_DATA, loadingData)]);
