import React from 'react';
import { Card } from '@material-ui/core';

import { CardLink, useStyles } from './styles';

const CardMenu = ({ href, label, icon, handleClick }) => {
  const classes = useStyles();
  return (
    <>
      <Card className={classes.root}>
        <CardLink to={href || '#'} onClick={handleClick}>
          <img src={icon} alt={label} />
          <span>{label}</span>
        </CardLink>
      </Card>
    </>
  );
};

export default CardMenu;
