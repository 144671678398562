import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Checkbox,
  CircularProgress
} from '@material-ui/core';
import TableItem from '../TableItem';
import * as ProductActions from '../../../../store/modules/products/actions';

const TableSelecaoProdutos = ({
  loading,
  filters,
  checked,
  rows,
  rowsCount,
  page,
  rowsPerPage,
  rowsPerPageOptions,
  selection,
  loadingData,
  changeSelectionAll
}) => {
  const handleSelectAll = event => {
    changeSelectionAll();
  };

  const handlePageChange = (event, value) => {
    loadingData({
      filters,
      page: value,
      rowsPerPage
    });
  };

  const handleRowsPerPageChange = event => {
    loadingData({
      filters,
      page: 0,
      rowsPerPage: event.target.value
    });
  };

  return (
    <>
      <TableContainer style={{ marginTop: '15px' }}>
        <Table stickyHeader size="small" aria-label="Today's orders">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={checked}
                  color="primary"
                  indeterminate={
                    selection.length > 0 && selection.length < rows.length
                  }
                  onChange={handleSelectAll}
                />
              </TableCell>
              <TableCell variant="head">Cod.</TableCell>
              <TableCell variant="head">Produto</TableCell>
              <TableCell variant="head">Estoque</TableCell>
              <TableCell variant="head" align="right">
                Preço de venda
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={5} rowSpan="all" align="center">
                  <CircularProgress />
                  <p>Carregando...</p>
                </TableCell>
              </TableRow>
            ) : (
              <>
                {rows.map(row => (
                  <TableItem
                    key={row.id}
                    row={{
                      ...row,
                      checked: selection.findIndex(s => s.id === row.id) !== -1
                    }}
                  />
                ))}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        labelRowsPerPage="Registro por página:"
        labelDisplayedRows={({ from, to, count }) =>
          `Exibindo ${from}-${to} de ${count} registros`
        }
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={rowsCount}
        page={page}
        onChangePage={handlePageChange}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={handleRowsPerPageChange}
      />
    </>
  );
};

const mapStateToProps = state => ({
  ...state.products
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(ProductActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TableSelecaoProdutos);
