import React, { useEffect, useState, useRef } from 'react';
import { withRouter, useParams } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import DateFnsUtils from "@date-io/date-fns";
import deLocale from "date-fns/locale/pt-BR";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from '@material-ui/pickers';
import {
  Card,
  CardHeader,
  CardContent,
  CardMedia,
  TextField,
  Container,
  InputAdornment,
  Button,
  Typography,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Chip,
  FormControlLabel,
  Switch,
  CardActions,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress
} from '@material-ui/core';
import { List, IconButton, Tooltip } from '@material-ui/core';

import { Star as IconStar, StarBorder as IconStarBorder, Delete as IconDelete } from '@material-ui/icons';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Storage } from '@capacitor/core';
import Swal from 'sweetalert2';
import grupoService from '../../../services/modules/produto-grupo.service';
import notification from '../../../services/notification';
import produtoService from '../../../services/modules/produto.service';
import { CurrencyFormatCustom, FilesDropzone, ImageView, DecimalThreePrecision } from '../../../components';
import { useStyles } from './styles';
import Loading from '../../../components/Loading';
import freteService from '../../../services/modules/frete.service';

const ProdutoEdit = ({ history }) => {
  const classes = useStyles();
  let { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [model, setModel] = useState(null);
  const [gradesModel, setGradesModel] = useState([]);
  const [imagens, setImagens] = useState([]);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [gradeToUploadImage, setGradeToUploadImage] = useState(null);
  const [portes, setPortes] = useState([]);
  const [gruposProdutos, setGruposProdutos] = useState([]);
  const [empresaLicenciada, setEmpresaLicenciada] = useState(null);
  const labelRef = useRef()
  const labelWidth = labelRef.current ? labelRef.current.clientWidth : 0

  const labelRefPorte = useRef()
  const labelWidthPorte = labelRefPorte.current ? labelRefPorte.current.clientWidth : 0

  const handleChange = event => {
    event.persist();

    setModel(data => ({ ...data, [event.target.name]: event.target.value }));
  };

  const handleAddGrade = event => {
    setGradesModel(arr => {
      const newArr = arr;
      newArr.push({
        produtoId: model?.id,
      })

      return [...newArr];
    })
  }

  const handleMatrixChange = async (event, itemLista) => {
    event.persist();
    setGradesModel(data => {

      const listInputs = data.map(input => {
        if (input === itemLista) {
          if (event.target.name !== 'desativado')
            return { ...input, [event.target.name]: event.target.value };
          else
            return { ...input, [event.target.name]: (event.target.name === "desativado" ? !event.target.checked : event.target.checked) };
        } else {
          return { ...input }
        }
      });

      return [...listInputs];
    });
  }

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleOpen = () => {
    setOpenDialog(true);
  };

  const handleDeleteGrade = async (event, row) => {
    const confirmacaoDeExclusao = await notification.confirm("Excluir grade", "Tem certeza que deseja excluir esta grade?")
    if (!confirmacaoDeExclusao?.value) {
      return;
    }

    let deleteGrade = true;

    if (row.id > 0) {
      deleteGrade = (await produtoService.deleteGrade(row.id))?.data
    }

    if (deleteGrade === true)
      setGradesModel(arr => {
        const newArr = arr;

        const indexToDelete = newArr.indexOf(row);

        newArr.splice(indexToDelete, 1)

        return [...newArr]
      })
  }

  const handleDelete = async e => {
    e.preventDefault();

    const confirmacaoDeExclusao = await notification.confirm("Excluir produto", "Tem certeza que deseja excluir este produto?")
    if (!confirmacaoDeExclusao?.value) {
      return;
    }

    setLoading(true);

    try {

      await produtoService.deleteProduto(id);

      setLoading(false);

      notification.success('Produto excluído com sucesso!');
      history.push('/produto');
    } catch (error) {
      setLoading(false);
      notification.error(error);
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    let savedModel;
    setLoading(true);

    try {
      if (model.codigoBarra) {
        if (isNaN(+model.codigoBarra) || model.codigoBarra.length > 20) {
          notification.erroValidacao("Verifique o código de barras ele deve ser numérico de no máximo 20 dígitos");
          setLoading(false);
          return;
        }
      }

      if (!(Number((model.precoVenda + '').split('.').join('').replace(/[^0-9.-]+/g, '') / 100) > 0)) {
        notification.erroValidacao("Você deve preencher um valor de venda para o produto.");
        setLoading(false);
        return;
      }

      if (!(model.produtoGrupoId > 0)) {
        notification.erroValidacao("Você deve preencher um departamento");
        setLoading(false);
        return;
      }

      if (model.id > 0) {
        savedModel = await produtoService.updateProduto(id, {
          ...model,
          grades: gradesModel,
          grade: gradesModel?.length > 0,
          passoFracionado: typeof model.passoFracionado === 'string'
            ? Number(model.passoFracionado.split('.').join('').split(',').join('.').replace(/[^0-9.-]+/g, ''))
            : model.passoFracionado,
          precoVenda:
            typeof model.precoVenda === 'string'
              ? Number(model.precoVenda?.split('.').join('').replace(/[^0-9.-]+/g, '') / 100)
              : model.precoVenda
        });
      } else {
        savedModel = await produtoService.saveProduto({
          ...model,
          grades: gradesModel,
          grade: gradesModel?.length > 0,
          passoFracionado: typeof model.passoFracionado === 'string'
            ? Number(model.passoFracionado.split('.').join('').split(',').join('.').replace(/[^0-9.-]+/g, ''))
            : model.passoFracionado,
          precoVenda:
            typeof model.precoVenda === 'string'
              ? Number(model.precoVenda?.split('.').join('').replace(/[^0-9.-]+/g, '') / 100)
              : model.precoVenda
        });
      }


      setLoading(false);

      notification.success('Produto salvo com sucesso!');
      setModel({ ...savedModel?.data });
      bootstrapAsync();
      history.push('/produto/' + savedModel?.data.id);
    } catch (error) {
      setLoading(false);
      notification.error(error);
    }
  };

  const handleUploadImagens = async files => {
    try {
      Swal.fire({
        toast: true,
        title: 'Enviando imagens',
        backdrop: true,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });
      const promises = files.map(file => {
        let formData = new FormData();
        formData.append('image', file);

        return produtoService.uploadImagensProduto(id, formData);
      });

      const imagensSalvas = (await Promise.all([...promises])).map(({ data }) => data);

      if (imagensSalvas.length > 0 && (model.imagemUrl === null || model.imagemUrl === undefined || model.imagemUrl === ''
        || model.imagemUrl.includes('not-found'))) {
        handleChangeImagemPadrao(imagensSalvas[0]);
      }

      setImagens(imagens => [...imagens].concat(imagensSalvas));
      Swal.close();

      notification.success('Imagens enviadas!');
    } catch (error) {
      notification.error(error);
    }
  };

  const handleChangeImagemPadrao = (item) => {
    setModel(data => ({ ...data, imagem: item.nome, imagemUrl: item.imagemUrl }))
    notification.success("Imagem padrão alterada. Não se esqueça de salvar as alterações para efetivá-las")
  }

  const handleAddImagemGrade = (grade, event) => {
    if (grade.id > 0) {
      setGradeToUploadImage(grade);
      openDropzone();
    }
  }
  const handleRemoveImagem = async imagem => {
    const { id: imagemId, produtoId, imagemUrl } = imagem;

    Swal.fire({
      title: 'Deseja apagar esta imagem?',
      text: 'Ao apagar esta imagem não irá constar mais na lista de imagens do produto',
      imageUrl: imagemUrl,
      imageWidth: 400,
      customClass: {
        confirmButton: 'MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedSecondary',
        cancelButton: 'MuiButtonBase-root MuiButton-root MuiButton-contained'
      },
      showCancelButton: true,
      confirmButtonText: 'Apagar imagem',
      cancelButtonText: 'Cancelar',
      reverseButtons: false
    }).then(async result => {
      if (result.value) {
        const toast = Swal.fire({
          toast: true,
          title: 'Apagando imagem',
          backdrop: true,
          onBeforeOpen: () => {
            Swal.showLoading();
          }
        });

        produtoService.removeImagemProduto(produtoId, imagemId).then(() => {
          toast.close();

          setImagens(array => {
            var index = array.indexOf(imagem);
            if (index !== -1) {
              array.splice(index, 1);
            }

            return [...array];
          });

          if (imagens.length === 0 || imagemUrl === model.imagemUrl) {
            setModel(item => { return { ...item, imagem: null, imagemUrl: null, imagemUri: null } })
          }
          notification.success('Imagem removida!');
        });
      }
    });
  };

  const handleDropImagemGrade = async acceptedFiles => {
    try {
      Swal.fire({
        toast: true,
        title: 'Enviando imagens',
        backdrop: true,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });
      const promises = acceptedFiles.map(file => {
        let formData = new FormData();
        formData.append('image', file);

        return produtoService.uploadImagemGrade(gradeToUploadImage.produtoId, gradeToUploadImage.id, formData);
      });

      const imagensSalvas = (await Promise.all([...promises])).map(({ data }) => data);

      setGradesModel(data => {
        const novasGrades = data.map(x => {
          if (gradeToUploadImage.id === x.id)
            return { ...x, imagemUrl: imagensSalvas[0].imagemUrl, imagem: imagensSalvas[0]?.imagem }
          else
            return { ...x }
        })

        return [...novasGrades];
      })

      setGradeToUploadImage(null);
      Swal.close();

      notification.success('Imagens enviadas!');
    } catch (error) {
      notification.error(error);
    }
  };

  const { getInputProps, open: openDropzone } = useDropzone({
    accept: 'image/*',
    onDrop: handleDropImagemGrade,
  });

  const bootstrapAsync = async () => {
    setLoading(true);
    try {

      const { data } = await produtoService.getProduto(id);
      const gruposRes = await grupoService.getGrupos();
      freteService.getPortes().then(data => {
        setPortes(data.data)
      });

      const { value: empresaLicenciadaStorage } = await Storage.get({
        key: 'empresaLicenciada'
      });
      setEmpresaLicenciada(JSON.parse(empresaLicenciadaStorage));

      if (data) {
        setModel({ ...data, imagemUrl: data.imagemUrl ?? data.imagemUri });
        setImagens(data.imagens.map(item => ({ ...item, imagemUrl: item.imagemUrl ?? item.imagemUri })) || []);
        setGradesModel(data.grades)
      } else {
        const itemProduto = {
          empresaId: JSON.parse(empresaLicenciadaStorage).id,
          unidade: 'UN',
          passoFracionado: 1,
          multiploEmbalagem: false,
          permiteFracionar: false,
          grade: false,
          desativado: false,
          ultimaAtualizacao: new Date()
        }
        setGradesModel([]);
        setModel(itemProduto);
      }

      setGruposProdutos(gruposRes.data)

      setLoading(false);

    } catch (error) {
      setLoading(false);
      notification.error(error);
    }
  };

  useEffect(() => {
    bootstrapAsync();
  }, [id]);
  return (
    <Container className={classes.root} fixed>
      {loading ?
        (
          <Loading></Loading>
        )
        : (<Grid container orientation="row" spacing={2}>
          <Grid item md={8} orientation="column">
            <Grid container orientation="row" spacing={2}>
              <Grid item md={12}>
                <Card>
                  <CardHeader title="Editar produto" />
                  <CardContent>
                    <Grid container orientation="row" spacing={2}>
                      <Grid item md={12}>
                        <TextField
                          id="text-nome"
                          name="nome"
                          label="Produto"
                          value={model?.nome ?? ''}
                          onChange={handleChange}
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={12}>
                        <TextField
                          id="text-descricao"
                          name="descricao"
                          label="Descrição Complementar"
                          value={model?.descricao ?? ''}
                          onChange={handleChange}
                          variant="outlined"
                          fullWidth
                          rows={3}
                        />
                      </Grid>
                      <Grid item md={12}>
                        <CKEditor
                          editor={ClassicEditor}
                          config={{
                            height: 700
                          }}
                          data={model?.descricaoCompleta || ''}
                          onChange={(_, editor) => {
                            setModel(data => ({
                              ...data,
                              descricaoCompleta: editor.getData()
                            }));
                          }}
                        />
                      </Grid>
                      <Grid item md={8} xs={12}>
                        <FormControl style={{ width: '100%' }} variant="outlined" >
                          <InputLabel ref={labelRef} id="demo-simple-select-outlined-label">Departamento</InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            labelWidth={labelWidth}
                            id="produtoGrupoId"
                            name="produtoGrupoId"
                            value={model?.produtoGrupoId}
                            onChange={(event) => { handleChange(event) }}
                          >
                            {gruposProdutos.map(grupos => (
                              <MenuItem value={grupos?.produtoGrupo?.id}>{grupos?.produtoGrupo?.descricao}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <TextField
                          id="text-preco-venda"
                          name="precoVenda"
                          label="Preço de venda"
                          value={model?.precoVenda ? model?.precoVenda : ''}
                          onChange={handleChange}
                          InputProps={{
                            inputComponent: CurrencyFormatCustom,
                            startAdornment: <InputAdornment position="start">R$</InputAdornment>
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>

                      <Grid item md={3} xs={12}>
                        <TextField
                          id="text-codigo-barra"
                          name="codigoBarra"
                          label="Código de Barra/EAN/GTIN"
                          variant="outlined"
                          fullWidth
                          value={model?.codigoBarra ?? ''}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <TextField
                          id="text-codigo-referencia"
                          name="codigoReferencia"
                          label="Código de Referência"
                          variant="outlined"
                          fullWidth
                          value={model?.codigoReferencia ?? ''}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <FormControl style={{ width: '100%' }} variant="outlined">
                          <InputLabel ref={labelRefPorte} id="demo-simple-select-label-2">Porte</InputLabel>
                          <Select
                            labelId="demo-simple-select-label-2"
                            id="tipoPorteFreteId"
                            labelWidth={labelWidthPorte}
                            name="tipoPorteFreteId"
                            value={model?.tipoPorteFreteId}
                            onChange={(event) => { handleChange(event) }}
                          >
                            <MenuItem value={null}>Nenhum</MenuItem>
                            {portes.map(porte => (
                              <MenuItem value={porte.id}>{porte.nome}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item md={12}>
                        <Typography variant="h4" gutterBottom>
                          Fracionado
                      </Typography>
                      </Grid>
                      <Grid item md={2}>
                        <FormControlLabel
                          control={
                            <Switch
                              color="primary"
                              checked={model?.permiteFracionar ?? false}
                              onChange={() =>
                                setModel(data => ({
                                  ...data,
                                  permiteFracionar: !model.permiteFracionar
                                }))
                              }
                            />
                          }
                          label="Permite fracionar"
                        />
                      </Grid>
                      {model?.permiteFracionar === true && (
                        <Grid item md={3} xs={12}>
                          <Tooltip title="Quantidade mínima de compra deste produto fracionado.">
                            <TextField
                              id="text-passo-fracionado"
                              name="passoFracionado"
                              label="Quantidade mínima"
                              variant="outlined"
                              fullWidth
                              InputProps={{
                                inputComponent: DecimalThreePrecision,
                                endAdornment: <InputAdornment position="end">{model?.unidade}</InputAdornment>
                              }}
                              value={model?.passoFracionado ?? ''}
                              onChange={handleChange}
                            />
                          </Tooltip>
                        </Grid>
                      )}

                      <Grid item md={12}>
                        <Typography variant="h4" gutterBottom>
                          Limitar quantidade de compra por usuário
                      </Typography>
                      </Grid>
                      <Grid item md={3}>
                        <FormControlLabel
                          control={
                            <Switch
                              color="primary"
                              checked={model?.permiteQuantidadeMaximaUsuario ?? false}
                              onChange={() =>
                                setModel(data => ({
                                  ...data,
                                  permiteQuantidadeMaximaUsuario: !model.permiteQuantidadeMaximaUsuario
                                }))
                              }
                            />
                          }
                          label="Limitar quantidade por usuário"
                        />
                      </Grid>
                      {model?.permiteQuantidadeMaximaUsuario === true && (
                        <>
                          <Grid item md={3}>
                            <Tooltip title="Intervalo de dias de validade da limitação de compra">
                              <TextField
                                id="text-passo-fracionado"
                                name="diasQuantidadeMaximaUsuario"
                                label="Intervalo de dias"
                                variant="outlined"
                                type="number"
                                fullWidth
                                InputProps={{
                                  endAdornment: <InputAdornment position="end">dias</InputAdornment>
                                }}
                                value={model?.diasQuantidadeMaximaUsuario ?? 0}
                                onChange={handleChange}
                              />
                            </Tooltip>
                          </Grid>
                          <Grid item md={3} xs={12}>
                            <Tooltip title="Quantidade máxima de itens que será possível cada usuário adquirir até a data limite">
                              <TextField
                                id="text-passo-fracionado"
                                name="quantidadeMaximaUsuario"
                                label="Quantidade máxima"
                                variant="outlined"
                                type="number"
                                fullWidth
                                InputProps={{
                                  endAdornment: <InputAdornment position="end">{model?.unidade}</InputAdornment>
                                }}
                                value={model?.quantidadeMaximaUsuario ?? 0}
                                onChange={handleChange}
                              />
                            </Tooltip>
                          </Grid>
                        </>
                      )}

                      <Grid item md={12}>
                        <Typography variant="h4" gutterBottom>
                          Observação obrigatória do cliente
                      </Typography>
                      </Grid>
                      <Grid item md={3}>
                        <FormControlLabel
                          control={
                            <Switch
                              inputProps={{ 'aria-label': 'secondary checkbox' }}
                              checked={model?.obrigatorioObservacao ?? false}
                              onChange={() =>
                                setModel(data => ({
                                  ...data,
                                  obrigatorioObservacao: !model.obrigatorioObservacao
                                }))
                              }
                            />
                          }
                          label="Observação obrigatória na colocação do pedido"
                        />
                      </Grid>
                      {model?.obrigatorioObservacao === true && (
                        <>
                          <Grid item md={3}>
                            <Tooltip title="Texto que aparecerá como informação para o usuário preencher">
                              <TextField
                                id="text-passo-fracionado"
                                name="textoObrigatorioObservacao"
                                label="Texto observação"
                                variant="outlined"
                                fullWidth
                                value={model?.textoObrigatorioObservacao}
                                onChange={handleChange}
                              />
                            </Tooltip>
                          </Grid>
                        </>
                      )}
                    </Grid>
                    <Grid container>
                      <Grid item>
                        &nbsp;
                    </Grid>
                    </Grid>
                    {empresaLicenciada?.possuiIntegracaoSIAF !== true && (
                      <Grid container spacing={2}>
                        <Grid item md={12}>
                          <Typography variant="h4" gutterBottom>
                            Detalhes do produto
                          </Typography>
                        </Grid>
                        <Grid item md={3}>
                          <FormControlLabel
                            control={
                              <Switch
                                color="primary"
                                checked={model?.multiploEmbalagem ?? false}
                                onChange={() =>
                                  setModel(data => ({
                                    ...data,
                                    multiploEmbalagem: !model.multiploEmbalagem
                                  }))
                                }
                              />
                            }
                            label="Multiplo de embalagem"
                          />
                        </Grid>
                        <Grid item md={3} xs={12}>
                          {model?.multiploEmbalagem === true && (
                            <Tooltip title="Quantidade da embalagem">
                              <TextField
                                id="text-passo-fracionado"
                                name="embalagem"
                                label="Qt. da embalagem"
                                variant="outlined"

                                value={model?.embalagem ?? ''}
                                onChange={handleChange}
                              />
                            </Tooltip>

                          )}
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <Tooltip title="Descritivo de unidade">
                            <TextField
                              id="text-passo-fracionado"
                              name="unidade"
                              label="Unidade (Ex.: UN, SC, Kg, etc.)"
                              variant="outlined"

                              value={model?.unidade ?? ''}
                              onChange={handleChange}
                            />
                          </Tooltip>
                        </Grid>
                        {model?.inventariado !== true && (
                          <>
                            <Grid item md={6} xs={12}>
                              <Tooltip title="Estoque Real">
                                <TextField
                                  id="text-passo-fracionado"
                                  name="estoqueReal"
                                  label="Estoque Real"
                                  variant="outlined"
                                  disabled={gradesModel?.length > 0}
                                  value={model?.estoqueReal ?? ''}
                                  onChange={handleChange}
                                />
                              </Tooltip>
                            </Grid>
                            <Grid item md={3}>
                              <Typography variant="h6" gutterBottom>
                                Estoque Reservado
                                </Typography>
                            </Grid>
                            <Grid item md={3}>
                              <Typography variant="subtitle2" gutterBottom>
                                {(model?.estoqueReservado ? (model.estoqueReservado === 0 ? 0 : Math.abs(model.estoqueReservado)) : "N/D") ?? 'N/D'}
                              </Typography>
                            </Grid>
                          </>
                        )}
                      </Grid>
                    )}

                    {(model?.id && model?.id > 0 && empresaLicenciada?.possuiIntegracaoSIAF === true) && (
                      <>
                        <Grid container>
                          <Grid item md={12}>
                            <Typography variant="h4" gutterBottom>
                              Detalhes do produto
                      </Typography>
                          </Grid>
                          <Grid item md={3}>
                            <Typography variant="h6" gutterBottom>
                              Quantidade embalagem
                      </Typography>
                          </Grid>
                          <Grid item md={3}>
                            <Typography variant="subtitle2" gutterBottom>
                              {(model?.embalagem ? model.embalagem : "1")}
                            </Typography>
                          </Grid>
                          <Grid item md={3}>
                            <Typography variant="h6" gutterBottom>
                              Múltiplos de embalagem
                      </Typography>
                          </Grid>
                          <Grid item md={3}>
                            <Typography variant="subtitle2" gutterBottom>
                              {(model?.multiploEmbalagem ? "Sim" : "Não") ?? 'Não'}
                            </Typography>
                          </Grid>
                          <Grid item md={3}>
                            <Typography variant="h6" gutterBottom>
                              Descritivo de unidade
                      </Typography>
                          </Grid>
                          <Grid item md={3}>
                            <Typography variant="subtitle2" gutterBottom>
                              {(model?.unidade ? model.unidade : "N/D") ?? 'N/D'}
                            </Typography>
                          </Grid>
                          <Grid item md={3}>
                            <Typography variant="h6" gutterBottom>
                              &nbsp;
                            </Typography>
                          </Grid>
                          <Grid item md={3}>
                            <Typography variant="subtitle2" gutterBottom>
                              &nbsp;
                            </Typography>
                          </Grid>
                          {!model?.inventariado === true && (
                            <>
                              <Grid item md={3}>
                                <Typography variant="h6" gutterBottom>
                                  Estoque Real
                                </Typography>
                              </Grid>
                              <Grid item md={3}>
                                <Typography variant="subtitle2" gutterBottom>
                                  {(model?.estoqueReal ? model.estoqueReal : "N/D") ?? 'N/D'}
                                </Typography>
                              </Grid>
                              <Grid item md={3}>
                                <Typography variant="h6" gutterBottom>
                                  Estoque Reservado
                                </Typography>
                              </Grid>
                              <Grid item md={3}>
                                <Typography variant="subtitle2" gutterBottom>
                                  {(model?.estoqueReservado ? (model.estoqueReservado === 0 ? 0 : Math.abs(model.estoqueReservado)) : "N/D") ?? 'N/D'}
                                </Typography>
                              </Grid>
                            </>
                          )}

                          <Grid item md={3}>
                            <Typography variant="h6" gutterBottom>
                              ID SIAF
                      </Typography>
                          </Grid>
                          <Grid item md={3}>
                            <Typography variant="subtitle2" gutterBottom>
                              {(model?.siafId ? model.siafId : "N/D") ?? 'N/D'}
                            </Typography>
                          </Grid>

                        </Grid>
                      </>
                    )}
                    <Grid container>
                      <Grid item md={12}>
                        <Typography variant="h4" gutterBottom>
                          Preços
                        </Typography>
                      </Grid>
                      {empresaLicenciada?.possuiIntegracaoSIAF === true && (
                        <>
                          <Grid item md={3}>
                            <Typography variant="h6" gutterBottom>
                              Preço no SIAF
                                              </Typography>
                          </Grid>
                          <Grid item md={3}>
                            <Typography variant="subtitle2" gutterBottom>
                              {(model?.precoVendaSiaf ? `R$ ${model.precoVendaSiaf.toFixed(2)}` : "N/D") ?? 'N/D'}
                            </Typography>
                          </Grid>
                          <Grid item md={3}>
                            <p>&nbsp;</p>
                          </Grid>
                          <Grid item md={3}>
                            <p>&nbsp;</p>
                          </Grid>
                        </>
                      )}
                      <Grid item md={3}>
                        <Typography variant="h6" gutterBottom>
                          Último preço de venda
                          </Typography>
                      </Grid>
                      <Grid item md={3}>
                        <Typography variant="subtitle2" gutterBottom>
                          {(model?.precoVendaAnterior ? `R$ ${model.precoVendaAnterior.toFixed(2)}` : "N/D") ?? 'N/D'}
                        </Typography>
                      </Grid>
                      {(model?.grade || empresaLicenciada?.possuiIntegracaoSIAF !== true) && (<Grid container>
                        <Grid item>
                          <Button style={{ marginLeft: 'auto' }} variant="contained" color="primary" onClick={handleOpen}>
                            Exibir Grade do produto
                            </Button>
                        </Grid>
                      </Grid>)}
                    </Grid>
                    <FormControlLabel
                      control={
                        <Switch
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                          checked={model?.desativado ?? false}
                          onChange={() =>
                            setModel(data => ({
                              ...data,
                              desativado: !model.desativado
                            }))
                          }
                        />
                      }
                      label="Desativado"
                    />
                    {empresaLicenciada?.permiteInventariado === true && (
                      <FormControlLabel
                        control={
                          <Switch
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                            checked={model?.inventariado ?? false}
                            onChange={() =>
                              setModel(data => ({
                                ...data,
                                inventariado: !model.inventariado
                              }))
                            }
                          />
                        }
                        label="Inventariado"
                      />
                    )}

                  </CardContent>
                  <CardActions className={classes.modalActions}>
                    <Tooltip title="Só podem ser excluídos produtos que ainda não foram vendidos">
                      <span>
                        <Button style={{ marginRight: 'auto' }} disabled={model?.countVendidos > 0} variant="contained" color="secondary" onClick={handleDelete}>
                          Excluir Produto
                      </Button>
                      </span>
                    </Tooltip>

                    <Button style={{ marginLeft: 'auto' }} variant="contained" color="primary" onClick={handleSubmit}>
                      Salvar
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={4} xs={12} orientation="column">
            {imagens.length > 0 && (
              <Card className={classes.root}>
                <CardHeader title="Imagens do produto" />
                <CardContent>
                  <Grid container orientation="row" spacing={2}>
                    <Grid item md={12}>
                      {model?.imagemUrl && (
                        <>
                          <Card style={{ width: '100%' }}>
                            <CardMedia component="img" src={model?.imagemUrl} />
                          </Card>
                          <Divider style={{ margin: 15 }} />
                        </>
                      )}

                      <List style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 10 }}>
                        {imagens.map(item => (
                          <Card style={{ height: '100%' }} key={item.id}>
                            <CardMedia component="img" height="140" src={item.imagemUrl} />
                            <Divider />

                            <CardActions style={{ display: 'flex', justifyContent: 'center' }}>
                              <Tooltip title="Definir como imagem principal do produto">
                                <IconButton
                                  onClick={() => { handleChangeImagemPadrao(item) }}
                                >
                                  {item.nome === model?.imagem ? <IconStar /> : <IconStarBorder />}
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Remover">
                                <IconButton onClick={() => handleRemoveImagem(item)}>
                                  <IconDelete />
                                </IconButton>
                              </Tooltip>
                            </CardActions>
                          </Card>
                        ))}
                      </List>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            )}

            <Card className={classes.root}>
              <CardHeader title="Upload de imagens" />
              <CardContent>
                <Grid container orientation="row" spacing={2}>
                  <Grid item md={12}>
                    {model?.id > 0 ? (
                      <FilesDropzone uploadImagens={handleUploadImagens} />
                    ) : (
                        <Typography variant="h6">Você está adicionando um novo produto. Para fazer o upload das
                         imagens, preencha os campos ao lado e em seguida clique em salvar.</Typography>
                      )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>)
      }
      <Dialog
        open={openDialog}
        onClose={handleClose}
        fullWidth={false}
        maxWidth="md"
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <input {...getInputProps()} />
        <DialogTitle id="scroll-dialog-title">Configuração da grade</DialogTitle>
        <DialogContent dividers={true}>
          <Grid container>
            <Grid item md={3} xs={3}>
              <Tooltip title="Descrição para Coluna da Matriz (Primária)">
                <TextField
                  id="text-passo-fracionado"
                  name="gradeDescricaoEixoY"
                  label="Desc. Linha (Primária)"
                  variant="outlined"

                  value={model?.gradeDescricaoEixoY ?? ''}
                  onChange={handleChange}
                />
              </Tooltip>
            </Grid>
            <Grid item md={3} xs={3}>
              <Tooltip title="Descrição para Linha da Matriz (Secundária)">
                <TextField
                  id="text-passo-fracionado"
                  name="gradeDescricaoEixoX"
                  label="Desc. Coluna (Secundária)"
                  variant="outlined"

                  value={model?.gradeDescricaoEixoX ?? ''}
                  onChange={handleChange}
                />
              </Tooltip>
            </Grid>
          </Grid>
          <TableContainer style={{ marginTop: '15px' }}>
            <Table stickyHeader size="small" aria-label="">
              <TableHead>
                <TableRow>
                  {empresaLicenciada?.possuiIntegracaoSIAF !== true ? (
                    <>
                      <TableCell>Imagem</TableCell>
                      <TableCell>Linha/Coluna</TableCell>
                      <TableCell>Código de barras</TableCell>
                      <TableCell>Estoque</TableCell>
                      <TableCell>Código de referência</TableCell>
                      <TableCell>Desativado</TableCell>
                      <TableCell align="center"></TableCell>
                    </>
                  ) : (
                      <>
                        <TableCell>Imagem</TableCell>
                        <TableCell>Descrição</TableCell>
                        <TableCell>Código de referência</TableCell>
                        <TableCell align="center"></TableCell>
                      </>
                    )}
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={5} rowSpan="all" align="center">
                      <CircularProgress />
                      <p>Carregando...</p>
                    </TableCell>
                  </TableRow>
                ) : (
                    <>
                      {gradesModel?.map(row => (
                        <>
                          {empresaLicenciada?.possuiIntegracaoSIAF !== true ? (<TableRow key={row.id}>
                            <TableCell style={{ width: 30, cursor: 'pointer' }} onClick={(event) => { handleAddImagemGrade(row, event) }}>
                              <Tooltip title={row.id > 0 ? "Clique para alterar a imagem" : "Você só pode alterar a imagem depois de salvar a nova grade adicionada."}>
                                <div>
                                  {row?.imagem &&
                                    (<ImageView image={(row?.imagemUrl ?? row?.imagemUri)} />)
                                  }
                                  {!row?.imagem &&
                                    (<ImageView image="/images/blankImage.png" />)
                                  }
                                </div>
                              </Tooltip>
                            </TableCell>
                            <TableCell>
                              <TextField
                                id="gradeEixoColuna"
                                name="gradeEixoColuna"
                                label={model?.gradeDescricaoEixoY ?? 'Coluna'}
                                value={row?.gradeEixoColuna}
                                onChange={(event) => { handleMatrixChange(event, row) }}
                                variant="outlined"
                                fullWidth
                              />
                              <TextField
                                spacing={2}
                                id="gradeEixoLinha"
                                name="gradeEixoLinha"
                                label={model?.gradeDescricaoEixoX ?? 'Linha'}
                                value={row?.gradeEixoLinha}
                                onChange={(event) => { handleMatrixChange(event, row) }}
                                variant="outlined"
                                fullWidth
                              />
                            </TableCell>
                            <TableCell size="medium">
                              <TextField
                                id="codigoBarra"
                                name="codigoBarra"
                                label="Cód. de barras"
                                value={row?.codigoBarra}
                                onChange={(event) => { handleMatrixChange(event, row) }}
                                variant="outlined"
                                fullWidth
                              />
                            </TableCell>
                            <TableCell size="medium">
                              <TextField
                                id="estoqueReal"
                                name="estoqueReal"
                                label="Estoque Real"
                                value={row?.estoqueReal}
                                onChange={(event) => { handleMatrixChange(event, row) }}
                                variant="outlined"
                                fullWidth
                              />
                            </TableCell>
                            <TableCell>
                              <TextField
                                id="codigoReferencia"
                                name="codigoReferencia"
                                label="Ref."
                                value={row?.codigoReferencia}
                                onChange={(event) => { handleMatrixChange(event, row) }}
                                variant="outlined"
                                fullWidth
                              />
                            </TableCell>
                            <TableCell align="right">
                              <Tooltip title="Desativado">
                                <Switch
                                  checked={!row.desativado}
                                  onChange={(event) => { handleMatrixChange(event, row) }}
                                  name="desativado"
                                  id="desativado"
                                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                                />
                              </Tooltip>
                            </TableCell>
                            <TableCell align="right">
                              <Tooltip title="Excluir">
                                <Chip
                                  size="small"
                                  label="x"
                                  clickable
                                  color="secondary"
                                  disabled={row?.countVendidos > 0}
                                  onClick={(event) => { handleDeleteGrade(event, row) }}
                                />
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                          ) : (<TableRow key={row.id}>
                            <TableCell style={{ width: 30, cursor: 'pointer' }} onClick={(event) => { handleAddImagemGrade(row, event) }}>
                              <Tooltip title="Clique para alterar a imagem">
                                <div>
                                  {row?.imagem &&
                                    (<ImageView image={(row?.imagemUrl ?? row?.imagemUri)} />)
                                  }
                                  {!row?.imagem &&
                                    (<ImageView image="/images/blankImage.png" />)
                                  }
                                </div>
                              </Tooltip>
                            </TableCell>
                            <TableCell size="medium">
                              <b>{row.gradeEixoLinha + '/' + row.gradeEixoColuna}</b>
                              <br /> <small>EAN: {row.codigoBarra}</small>
                            </TableCell>
                            <TableCell>
                              <TextField
                                id="codigoReferencia"
                                name="codigoReferencia"
                                label="Cód. de referência"
                                value={row?.codigoReferencia}
                                onChange={(event) => { handleMatrixChange(event, row) }}
                                variant="outlined"
                                fullWidth
                              />
                            </TableCell>
                            <TableCell align="right">
                              <Tooltip title="Ativar/Desativar">
                                <Switch
                                  checked={!row.desativado}
                                  onChange={(event) => { handleMatrixChange(event, row) }}
                                  name="desativado"
                                  id="desativado"
                                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                                />
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                            )}
                        </>
                      ))}
                    </>
                  )}

                {model?.grades?.length === 0 && !loading && (
                  <TableRow style={{ height: 53 }}>
                    <TableCell colSpan={5}>Nenhuma informação encontrada</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {(model?.grade || empresaLicenciada?.possuiIntegracaoSIAF !== true) && (<Grid container>
            <Grid container spacing={2}>
              <Grid item>
                <Button style={{ marginLeft: 'auto' }} variant="contained" color="primary" onClick={handleAddGrade}>
                  Adicionar grade
                            </Button>
              </Grid>
            </Grid>
          </Grid>)}

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

    </Container >
  );
};

export default withRouter(ProdutoEdit);
