import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Plugins } from '@capacitor/core';
import {
  makeStyles,
  Container,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Grid,
  Checkbox,
  FormControlLabel,
  TextField
} from '@material-ui/core';

import notification from '../../services/notification';
import parametroService from '../../services/modules/parametro.service';
import { Loading } from '../../components';

const { Storage } = Plugins;

const useStyles = makeStyles(() => ({
  root: {},
  divider: {
    margin: '20px 0'
  },
  modalActions: {
    borderTop: '1px solid #E4E7EB',
    padding: '15px',
    display: 'flex',
    buttonRight: {
      marginLeft: 'auto'
    },
    buttonLeft: {
      marginRight: 'auto'
    }
  }
}));

const Parametros = ({ history }) => {
  const classes = useStyles();
  // const [tipoPagamentos, setTipoPagamentos] = useState([]);
  // const [tipoVendas, setTipoVendas] = useState([]);

  const [loading, setLoading] = useState(false);
  const [enderecoApiSiaf, setEnderecoApiSiaf] = useState(null);
  const [enderecoApiBase, setEnderecoApiBase] = useState(null);
  const [model, setModel] = useState({
    meiosPagamento: [],
    permiteAgendarEntrega: false,
    pedidoSomenteExpediente: false,
    cobrarTaxaEntrega: false
  });

  const handleChangeEnderecoIP = (event) => {
    setEnderecoApiSiaf(event.target.value);
  }

  const handleCheckboxChange = event => {
    event.persist();
    setModel(data => {
      return {
        ...data,
        [event.target.name]: event.target.checked
      }
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);

    await Storage.set({
      key: 'servidorSiaf',
      value: enderecoApiSiaf
    })

    if (enderecoApiBase === '') {
      await Storage.set({
        key: 'baseApiUrl',
        value: null
      })
    } else
      await Storage.set({
        key: 'baseApiUrl',
        value: enderecoApiBase
      })

    try {
      setLoading(true);
      await parametroService.saveParametro(model);

      setLoading(false);
      history.push('/');
      notification.success('Parâmetros salvos com sucesso!');
    } catch (error) {
      setLoading(false);
      notification.error(error);
    }
  };

  useEffect(() => {
    const loadData = async () => {
      const respParametro = await parametroService.getParametro();
      setModel(respParametro.data);
    };
    
    loadData();
    Storage.get({
      key: 'servidorSiaf'
    }).then(x => {
      console.log(x)
      setEnderecoApiSiaf(x.value);
    });
    Storage.get({
      key: 'baseApiUrl'
    }).then(x => {
      console.log(x)
      setEnderecoApiBase(x.value);
    });

  }, []);

  return (
    <Container className={classes.root} fixed>
      {loading ? (
        <Loading />
      ) : (
          <>
            <Grid container orientation="row" spacing={2}>
              <Grid item md={12} orientation="column">
                <Grid container alignItems="center"
                  justify="center" spacing={2}>
                  <Grid item alignItems="center" md={6}>
                    <Card>
                      <CardHeader title="Permissões" />
                      <CardContent>
                        {/* <Grid item md={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="permiteAgendarEntrega"
                                checked={model?.permiteAgendarEntrega}
                                onChange={(event) => { handleCheckboxChange(event) }}
                                color="primary"
                              />
                            }
                            label="Agendamento do horário de entrega"
                          />
                        </Grid> */}
                        {/* <Grid item md={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="pedidoSomenteExpediente"
                                checked={model?.pedidoSomenteExpediente}
                                onChange={(event) => { handleCheckboxChange(event) }}
                                color="primary"
                              />
                            }
                            label="Aceitar pedido apenas dentro do horário de funciomaneto"
                          />
                        </Grid> */}
                        <Grid item md={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="cobrarTaxaEntrega"
                                checked={model?.cobrarTaxaEntrega}
                                onChange={(event) => { handleCheckboxChange(event) }}
                                color="primary"
                              />
                            }
                            label="Cobrar taxa de entrega"
                          />
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container alignItems="center"
              justify="center" spacing={2}>
              <Grid md={6} orientation="row" style={{ marginTop: 20 }}>
                <Card>
                  <CardHeader title="Endereço de integração com SIAF" />
                  <CardContent>
                    <TextField label="Endereço IP"
                      variant="outlined"
                      value={enderecoApiSiaf}
                      onChange={(event) => { handleChangeEnderecoIP(event) }}
                    />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid container alignItems="center"
              justify="center" spacing={2}>
              <Grid item md={6} orientation="row" style={{ marginTop: 20 }}>
                <Card>
                  <CardActions className={classes.modalActions}>
                    <Button style={{ marginLeft: 'auto' }} variant="contained" color="primary" onClick={handleSubmit}>
                      Salvar
                </Button>
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
          </>
        )
      }
    </Container >
  );
};

export default withRouter(Parametros);
