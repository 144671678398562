import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    
  }
});

export const Container = styled.div`
.MuiCardHeader-root {
  padding: 10px 16px;
  .MuiCardHeader-title {
    font-size: 0.9em;
    color: #666;
  }
  .MuiCardHeader-action {
    padding-top: 5px;
  }
}

.MuiCardContent-root {
  padding: 0 16px 20px;
  text-align: center;
  h1 {
    font-size: 4.3em;
  }
}
`;
  