import { apiStore } from '../api';

class ProdutoGrupoService {
  getGruposGenericos() {
    return apiStore.get(`/api/backoffice/produto-grupo/`);
  }

  getGrupos() {
    return apiStore.get(`/api/backoffice/produto-grupo-empresa/`);
  }

  getGruposDeEmpresa(empresaId) {
    return apiStore.get(`/api/backoffice/empresa/${empresaId}/produto-grupo-empresa/`);
  }

  updateGrupos(data) {
    return apiStore.put(`/api/backoffice/produto-grupo-empresa/`, data);
  }

  addImagem(data) {
    return apiStore.post('/api/backoffice/produto-grupo/upload/imagem', data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
  }

  updateGrupo(id, data) {
    return apiStore.put(`/api/backoffice/produto-grupo-empresa/${id}`, data);
  }

  updateGrupoGenerico(id, data) {
    return apiStore.put(`/api/backoffice/produto-grupo/${id}`, data);
  }

  saveGrupoGenerico(data) {
    return apiStore.post(`/api/backoffice/produto-grupo`, data);
  }

  saveGruposEmpresa(data) {
    return apiStore.post(`/api/backoffice/produto-grupo-empresa`, data);
  }
}

export default new ProdutoGrupoService();
