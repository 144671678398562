import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { useStyles } from './styles';
import { format } from 'date-fns'
import Container from '@material-ui/core/Container';
import { useReactToPrint } from 'react-to-print';
import { Card, CardContent, CardHeader, Typography } from '@material-ui/core';
import { Storage } from '@capacitor/core';
import { ContentedButtons } from '../../../styles/shared';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import PedidoRomaneio from "../Reports/pedido-romaneio"
import {
  Table,
  IconButton,
  TableBody,
  TableCell,
  Checkbox,
  CardActions,
  TableContainer,
  CircularProgress,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  Grid,
  ButtonGroup,
  Button,
  MenuList,
  Popper,
  Grow,
  Menu,
  Paper,
  MenuItem,
  ClickAwayListener
} from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import PedidoService from '../../../services/modules/pedido.service';
import { PROPS } from "../../../utils/constantes";
import VisibilityIcon from '@material-ui/icons/Visibility';
import {
  MoreVert as IconMore,
  DoneAll as IconDoneAll,
} from '@material-ui/icons';
import pedidoService from '../../../services/modules/pedido.service';

const Pedidos = ({ history }) => {
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [editMode, setEditMode] = useState(false);
  const [actionEditMode, setActionEditMode] = useState(-1);
  const [rowsCount, setRowsCount] = useState(0);
  const componentRef = useRef();
  const [page, setPage] = useState(0);
  const [lastQuery, setLastQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const anchorRef = React.useRef(null);
  const options = ['Todos', 'Aguardando Aprovação', 'Aprovado', 'Reprovado', 'Faturado', 'Preparação', 'Aguardando Envio', 'Trânsito', 'Concluído', 'Cancelado'];
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [open, setOpen] = React.useState(false);
  const [apenasRetirarLoja, setApenasRetirarLoja] = React.useState(false);
  const [empresa, setEmpresa] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [dataSource, setDataSource] = useState({
    data: []
  });

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, dataSource.data.length - page * rowsPerPage);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleImprimir = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleAprovarPedidos = async () => {
    setLoading(true);

    try {
      const idsParaAprovar = dataSource.data?.filter(x => x.checked === true).map(x => (x.id));

      await pedidoService.nextSituacaoLote(idsParaAprovar);
      await handleMenuItemClick(null, selectedIndex);

      setEditMode(false);
      setActionEditMode(0);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handlePageChange = async (event, newPage) => {
    await setPage(newPage);
  };

  const changeSelectionOne = (item) => {
    setDataSource(data => {
      const newData = data.data.map(pedido => {
        if (pedido.id === item.id) {
          if (item.checked === undefined || item.checked === null)
            pedido.checked = true;
          else
            pedido.checked = !pedido.checked;
        }

        return { ...pedido }
      })

      return {
        data: [...newData]
      }
    })

  }

  const handleRowsPerPageChange = event => {
    if (event.target.value === 'Todos')
      setRowsPerPage(parseInt(1000000));
    else
      setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleMenuItemClick = (event, index) => {
    const queryString = getQueryString();
    setSelectedIndex(index);
    setOpen(false);

    setLoading(true);
    PedidoService.getAll(index, queryString, apenasRetirarLoja).then(({ data: pedidosResult }) => {
      const { rows, rowsCount } = pedidosResult;
      setRowsCount(rowsCount);
      setDataSource({
        data: rows.map(row => ({
          ...row,
        }))
      });
    });
    setLoading(false);
  };

  const filterList = event => {
    setPage(0);
    const queryString = getQueryString();
    event.persist();
    const query = event.target.value;
    if (query.trim() !== '' && query.length >= 3) {
      PedidoService.getAll(selectedIndex, queryString, apenasRetirarLoja, query).then(({ data: pedidosResult }) => {
        const { rows, rowsCount } = pedidosResult;
        setRowsCount(rowsCount);
        setDataSource({
          data: rows.map(row => ({
            ...row,
          }))
        });
      });
    } else if (query.trim() === '') {
      PedidoService.getAll(selectedIndex, queryString, apenasRetirarLoja, '').then(({ data: pedidosResult }) => {
        const { rows, rowsCount } = pedidosResult;
        setRowsCount(rowsCount);
        setDataSource({
          data: rows.map(row => ({
            ...row,
          }))
        });
      });
    }
  };

  const filterListApenasRetirada = apenasRetirada => {
    const queryString = getQueryString();

    if (!loading) {
      setLoading(true);
      PedidoService.getAll(selectedIndex, queryString, apenasRetirada, '').then(({ data: pedidosResult }) => {
        const { rows, rowsCount } = pedidosResult;
        setRowsCount(rowsCount);
        setDataSource({
          data: rows.map(row => ({
            ...row,
          }))
        });
      });

      setLoading(false);
      setLastQuery(queryString);
    }
  };

  const getQueryString = () => {
    let queryString = `?page=${page + 1}&limit=${rowsPerPage}`;

    return queryString;
  };

  const loadData = () => {
    const queryString = getQueryString();

    if (queryString !== lastQuery && !loading) {
      setLoading(true);
      PedidoService.getAll(selectedIndex, queryString, apenasRetirarLoja).then(({ data: pedidosResult }) => {
        if (pedidosResult) {
          const { rows, rowsCount } = pedidosResult;
          setRowsCount(rowsCount);
          setDataSource({
            data: rows.map(row => ({
              ...row,
            }))
          });
        }
      });

      Storage.get({
        key: 'empresaLicenciada'
      }).then(({ value: empresaLicenciada }) => {
        setEmpresa(JSON.parse(empresaLicenciada));
      });

      setLoading(false);
      setLastQuery(queryString);
    }
  };

  const handleSelectAll = () => {
    const isToSelectAll = dataSource.data.filter(x => x.checked === true).length !== dataSource.data.length;

    setDataSource(data => {
      const newData = data.data.map(pedido => {
        pedido.checked = isToSelectAll;
        return { ...pedido }
      })

      return {
        data: [...newData]
      }
    })
  }

  const handleClickFilterSituacao = () => {
    console.info(`You clicked ${options[selectedIndex]}`);
  };

  const hadleClickPedido = (event, pedidoId) => {
    history.push(`/pedido/${pedidoId}`)
  };

  const getClassColor = (situacaoId) => {
    let styleClass = classes.colorBlack;
    switch (situacaoId) {
      case PROPS.PEDIDO.SITUACAO.AGUARDANDO_APROVACAO:
        styleClass = classes.colorRed
        break;

      case PROPS.PEDIDO.SITUACAO.AGUARDANDO_ENVIO:
        styleClass = classes.colorRed
        break;

      case PROPS.PEDIDO.SITUACAO.APROVADO:
        styleClass = classes.colorGreen
        break;

      case PROPS.PEDIDO.SITUACAO.CONCLUIDO:
        styleClass = classes.colorGreen
        break;

      case PROPS.PEDIDO.SITUACAO.FATURADO:
        styleClass = classes.colorGray
        break;

      case PROPS.PEDIDO.SITUACAO.PREPARACAO:
        styleClass = classes.colorBlack
        break;

      case PROPS.PEDIDO.SITUACAO.REPROVADO:
        styleClass = classes.colorRed
        break;

      case PROPS.PEDIDO.SITUACAO.TRANSITO:
        styleClass = classes.colorGray
        break;

      case PROPS.PEDIDO.SITUACAO.CANCELADO:
        styleClass = classes.colorGray
        break;

      default:
        break;
    }

    return styleClass;
  }


  useEffect(() => loadData());

  return (
    <Container className={classes.root} fixed>
      <Card>
        <CardHeader title="Pedido" />
        <CardContent>
          <AppBar className={classes.appbar} position="static">
            <Toolbar className={classes.toolbar}>
              <Typography className={classes.title} variant="h4" noWrap></Typography>
              <Grid container direction="column" alignItems="right">
                <Grid item xs={12}>
                  <ButtonGroup variant="contained" color="primary" ref={anchorRef} aria-label="split button">
                    <Button onClick={handleClickFilterSituacao} disabled={editMode} className={classes.filterButton} > {options[selectedIndex]}</Button>
                    <Button
                      disabled={editMode}
                      color="primary"
                      size="small"
                      aria-controls={open ? 'split-button-menu' : undefined}
                      aria-expanded={open ? 'true' : undefined}
                      aria-label="select merge strategy"
                      aria-haspopup="menu"
                      onClick={handleToggle}
                    >
                      <ArrowDropDownIcon />
                    </Button>
                  </ButtonGroup>
                  <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList id="split-button-menu">
                              {options.map((option, index) => (
                                <MenuItem
                                  key={option}
                                  selected={index === selectedIndex}
                                  onClick={(event) => handleMenuItemClick(event, index)}
                                >
                                  {option}
                                </MenuItem>
                              ))}
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </Grid>
                <Grid item md={12} className={classes.gridInterno}>
                  <Checkbox
                    color="primary"
                    disabled={editMode}
                    name="apenasRetirada" checked={apenasRetirarLoja}
                    onChange={(event) => { setApenasRetirarLoja(event.target.checked); filterListApenasRetirada(event.target.checked); }}
                  /> <Typography variant="caption">Apenas para retirar na loja</Typography>
                </Grid>
              </Grid>
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="Pesquisa…"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput
                  }}
                  onChange={filterList}
                  inputProps={{ 'aria-label': 'Pesquisa' }}
                />
              </div>
              <ContentedButtons>
                {/* <IconButton aria-label="display more actions" edge="end" color="inherit" style={{ color: '#666' }}>
                  <IconFilterList />
                </IconButton> */}
                <IconButton
                  aria-label="display more actions"
                  color="inherit"
                  style={{ color: '#666' }}
                  onClick={event => setAnchorEl(event.currentTarget)}
                >
                  <IconMore />
                </IconButton>

                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={() => setAnchorEl(null)}
                >
                  <MenuItem
                    onClick={() => {
                      setAnchorEl(null);
                      setActionEditMode(0);
                      setEditMode(true);
                    }}
                  >
                    Imprimir pedidos...
                </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setAnchorEl(null);
                      setActionEditMode(1);
                      setEditMode(true);
                      handleMenuItemClick(null, 1);
                    }}
                  >
                    Aprovar pedidos...
                </MenuItem>
                </Menu>
              </ContentedButtons>
            </Toolbar>
          </AppBar>

          {editMode && (
            <>
              <CardActions style={{ justifyContent: 'flex-end' }}>
                <Grid item>
                  {actionEditMode === 0 && (
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      style={{ marginLeft: 10, marginRight: 10 }}
                      onClick={() => { setEditMode(false); setActionEditMode(-1); handleImprimir() }}
                    >
                      <IconDoneAll /> Imprimir pedidos
                    </Button>
                  )}
                  {actionEditMode === 1 && (
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      style={{ marginLeft: 10, marginRight: 10 }}
                      onClick={() => { handleAprovarPedidos() }}
                    >
                      <IconDoneAll /> Aprovar pedidos
                    </Button>
                  )}
                  <Button variant="contained" size="small" onClick={() => { setEditMode(false); setActionEditMode(-1) }}>
                    Cancelar
                </Button>
                </Grid>
              </CardActions>
            </>
          )}

          <TableContainer>
            <Table size="small" aria-label="Today's orders">
              <TableHead className={classes.thead}>
                <TableRow>
                  {editMode && (
                    <TableCell className={classes.headCell} variant="head">
                      <Checkbox checked={dataSource.data.filter(x => x.checked === true).length === dataSource.data.length} color="primary" onChange={event => handleSelectAll()} />
                    </TableCell>
                  )}
                  <TableCell className={classes.headCell} variant="head">
                    Cod.
                  </TableCell>
                  <TableCell className={classes.headCell} variant="head">
                    Doc.
                  </TableCell>
                  {empresa?.administrador === true && (
                    <TableCell className={classes.headCell} variant="head">
                      Empresa
                    </TableCell>
                  )}
                  <TableCell className={classes.headCell} variant="head">
                    Data
                  </TableCell>
                  <TableCell className={classes.headCell} variant="head">
                    Cliente
                  </TableCell>
                  <TableCell className={classes.headCell} variant="head" align="right">
                    Total
                  </TableCell>
                  <TableCell className={classes.headCell} variant="head" align="center">
                    Situação
                  </TableCell>
                  {!editMode && (
                    <TableCell className={classes.headCell} variant="head">
                      &nbsp;
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={7} rowSpan="all" align="center">
                      <CircularProgress />
                      <p>Carregando...</p>
                    </TableCell>
                  </TableRow>
                ) : (
                    dataSource.data.map(row => (
                      <TableRow className={classes.pedidoRow} key={row.numero}>
                        {editMode && (
                          <TableCell>
                            <Checkbox checked={dataSource.data.filter(x => x.checked === true && x.id === row.id).length > 0} color="primary" onChange={event => changeSelectionOne(row)} />
                          </TableCell>
                        )}
                        <TableCell><b>{row.numero} &nbsp;</b>
                          {row.tipoPagamento === 2 && (<CreditCardIcon style={{ marginBottom: '-7px', color: 'green' }} />)}
                        </TableCell>
                        <TableCell>{row.siafPedidoId}</TableCell>
                        {empresa?.administrador === true && (
                          <TableCell>{row?.empresa.nomeFantasia}</TableCell>
                        )}
                        <TableCell>{format(new Date(row.dataEmissao), 'dd/MM/yyyy HH:mm')}</TableCell>
                        <TableCell><b>{row.clienteNome}</b></TableCell>
                        <TableCell align="right">{row.valorTotal ? `R$ ${(+row.valorTotal)?.toFixed(2)}` : `Sem valor`}</TableCell>
                        <TableCell align="center" className={getClassColor(row.situacao)}>
                          <b>{row.situacaoDescricao}</b>
                        </TableCell>
                        {!editMode && (
                          <TableCell>
                            <VisibilityIcon onClick={(event) => { hadleClickPedido(event, row.id) }} />
                          </TableCell>
                        )}
                      </TableRow>
                    ))
                  )}
                {dataSource.data.length === 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={5}>Nenhuma informação encontrada</TableCell>
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={8}>
                    <TablePagination
                      labelRowsPerPage="Registro por página:"
                      labelDisplayedRows={({ from, to, count }) => `Exibindo ${from}-${to} de ${count} registros`}
                      rowsPerPageOptions={[50, 100, 'Todos']}
                      component="div"
                      count={rowsCount}
                      page={page}
                      onChangePage={handlePageChange}
                      rowsPerPage={rowsPerPage}
                      onChangeRowsPerPage={handleRowsPerPageChange}
                    />
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
          {editMode && (
            <>
              <CardActions style={{ justifyContent: 'flex-end' }}>
                <Grid item>
                  {actionEditMode === 0 && (
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      style={{ marginLeft: 10, marginRight: 10 }}
                      onClick={() => { setEditMode(false); setActionEditMode(-1); handleImprimir() }}
                    >
                      <IconDoneAll /> Imprimir pedidos
                    </Button>
                  )}
                  {actionEditMode === 1 && (
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      style={{ marginLeft: 10, marginRight: 10 }}
                      onClick={() => { handleAprovarPedidos() }}
                    >
                      <IconDoneAll /> Aprovar pedidos
                    </Button>
                  )}
                  <Button variant="contained" size="small" onClick={() => { setEditMode(false); setActionEditMode(-1) }}>
                    Cancelar
                </Button>
                </Grid>
              </CardActions>
            </>
          )}
          {/* Ajuste par quando ouver poucos itens e não quebrar o dropdown de status */}
          {dataSource?.data.length === 4 && (
            <p style={{ paddingBottom: 20 }}></p>
          )}
          {dataSource?.data.length < 4 && (
            <p style={{ paddingBottom: 140 }}></p>
          )}
        </CardContent>
      </Card>
      <Grid item md={12}>
        <div>
          <div style={{ display: 'none' }}>
            <PedidoRomaneio pedidos={dataSource.data} ref={componentRef} />
          </div>
        </div>
      </Grid>
    </Container >
  );
};

export default withRouter(Pedidos);
