import { apiStore } from '../api';

class ProdutoService {
  exportProdutos(data) {
    return apiStore.post('/api/backoffice/produtos', data);
  }

  updatePrecoProdutosSIAF() {
    return apiStore.get('/api/backoffice/produto/0/update-preco-produto-siaf');
  }

  getProdutos(filter, data) {
    return apiStore.post(`/api/backoffice/produto/get-all${filter}`, data);
  }
  getProdutosByEmpresa(empresaId) {
    return apiStore.get(`/api/backoffice/empresa/${empresaId}/produto`);
  }

  getProduto(id) {
    return apiStore.get(`/api/backoffice/produto/${id}`);
  }

  updateProduto(id, data) {
    return apiStore.put(`/api/backoffice/produto/${id}`, data);
  }

  
  saveProduto(data) {
    return apiStore.post(`/api/backoffice/produto/`, data); 
  }

  deleteProduto(id) {
    return apiStore.delete(`/api/backoffice/produto/${id}`);
  }

  
  deleteGrade(id) {
    return apiStore.delete(`/api/backoffice/grade/${id}`);
  }

  updateProdutoLote(data) {
    return apiStore.post(`/api/backoffice/produto/alterar-lote`, data);
  }

  uploadImagensProduto(id, data) {
    return apiStore.post(`/api/backoffice/produto/${id}/imagem`, data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    });
  }

  uploadImagemGrade(idProduto, idGrade, data) {
    return apiStore.post(`/api/backoffice/produto/${idProduto}/grade/${idGrade}`, data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    });
  }

  removeImagemProduto(produtoId, imagemId) {
    return apiStore.delete(`/api/backoffice/produto/${produtoId}/imagem/${imagemId}`);
  }

  updatePrecoStatusLote(data){
    return apiStore.post(`/api/backoffice/produto/alterar-preco-status-lote`, data);
  }
}

export default new ProdutoService();
